import OrdersIcon from '@material-ui/icons/ListAlt'
import OrdersList from './OrdersList'
import OrdersEdit from './OrdersEdit'
import OrdersCreate from './OrdersCreate'

const index = {
    icon: OrdersIcon,
    list: OrdersList,
    edit: OrdersEdit,
    create: OrdersCreate
}

export default index