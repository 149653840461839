import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { svidaAPI } from '../../../../../api/svidaApi'
import { streetStyles } from './selectCityAndStreetStyles'
const filter = createFilterOptions()
const SelectStreetTo = props => {
	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const [cityIdTo, setCityIdTo] = useState(null)
	const [value, setValue] = useState(null)
	const loading = open && options.length === 0

	const [validateStreet, setValidateStreet] = useState(false)

	useEffect(() => {
		let streetIdTo = props.value
		if (streetIdTo) {
			;(async () => {
				await svidaAPI
					.getStreet(streetIdTo)

					.then(response => {
						setValue(response)
					})
			})()
		}
	}, [props.value])

	useEffect(() => {
		if (props.cityIdTo !== cityIdTo) {
			setValue(null)
		}

		setCityIdTo(props.cityIdTo)
	}, [props.cityIdTo, cityIdTo])

	useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		if (cityIdTo && active) {
			;(async () => {
				await svidaAPI.getStreetsByCityId(cityIdTo).then(response => {
					setOptions(
						Object.keys(response).map(key => {
							return response[key]
						}),
					)
				})
			})()
		}

		return () => {
			active = false
		}
	}, [loading, cityIdTo])

	useEffect(() => {
		if (!open) {
			setOptions([])
		}
	}, [open])
	const onSelectStreetIdTo = (streetIdTo) => {
		props.onSelectStreetIdTo(streetIdTo);
	};
	const classes = streetStyles()

	return (
		<Autocomplete
			open={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			getOptionSelected={(option, value) => option.name === value.name}
			getOptionLabel={option => {
				if (typeof option === 'string') {
					return option
				}
				if (option.inputValue) {
					return 'ул. ' + option.inputValue
				}
				return 'ул. ' + option.name
			}}
			filterOptions={(options, params) => {
				const filtered = filter(options, params)

				const { inputValue } = params
				const isExisting = options.some(option => inputValue === option.name)
				if (inputValue !== '' && !isExisting) {
					filtered.push({
						inputValue,
						name: `Добавить ул "${inputValue}"`,
					})
				}

				return filtered
			}}
			loadingText={cityIdTo ? 'Загрузка...' : 'Выберите город'}
			noOptionsText='Нет совпадений'
			clearText='Очистить'
			openText='Открыть'
			options={options}
			loading={loading}
			value={value}
			className={`${classes.inputNewWidthStreet} ${classes.inputNewMRStreet}`}
			onInputChange={(event, value) => {
				;(async () => {
					await svidaAPI.getStreetsByName(value, cityIdTo).then(response => {
						setOptions(
							Object.keys(response).map(key => {
								return response[key]
							}),
						)
					})
				})()
			}}
			onChange={(event, value) => {
				if (typeof value === 'string') {
					// setValue({
					// 	name: value,
					// })
					setValue('')
					props.setFieldValue(props.name, value)
				} else if (value && value.inputValue) {
					value.id && onSelectStreetIdTo(value.id);
					!value.id && value.inputValue && onSelectStreetIdTo(value.inputValue);
					setValue(value.inputValue)
					props.setFieldValue(props.name, value.inputValue)
				} else {
					onSelectStreetIdTo(value.id);
					setValue(value)
					// props.setFieldValue(props.name, value.id)
				}
			}}
			onKeyDown={e => {
				if (e.code === 'Enter') {
					if (value === null || value === '') {
						setValidateStreet(true)
						localStorage.setItem('validateStreet', false)
						setTimeout(() => {
							setValidateStreet(false)
							localStorage.setItem('validateStreet', true)
						}, 3000)
					}
				}
			}}
			renderInput={(params, input) => {
				return (
					<TextField
						{...params}
						{...input}
						name={props.name}
						label='Куда: Улица'
						variant='filled'
						error={validateStreet}
						helperText={validateStreet && <div>Необходимо выбрать из списка</div>}
						required={validateStreet}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color='inherit' size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)
			}}
		/>
	)
}

export default SelectStreetTo
