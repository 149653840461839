import React from 'react'
import { useFormik } from 'formik'
import * as yup from 'yup'
import TextField from '@material-ui/core/TextField'
import StelsSaveButton from '../../../../components/common/ButtonStyles/StelsSaveButton'
import { userAPI } from '../../../../api/api'
import { useNotify } from 'react-admin'

const validationSchema = yup.object({
    password: yup
        .string('Введите пароль')
        .min(6, 'Минимальная длина пароля 6 символов')
        .required('Обязательно для заполнения'),
})

const FormikInModal = (props) => {
    const notify = useNotify()

    const formik = useFormik({
        initialValues: {
            userId: props.userId,
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            let userId = values.userId
            let password = { password: values.password }

            await userAPI
                .changePassword(userId, password)
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error(response.statusText)
                    }
                    props.openModal()
                    notify('Пароль успешно изменен', 'info')
                })
                .catch((error) => {
                    notify(
                        'Пароль не должен иметь кириллицу. Должен иметь: буквы и цифры',
                        'warning'
                    )
                })
        },
    })

    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    style={{ margin: '10px 0 20px' }}
                    id="password"
                    name="password"
                    label="Пароль"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                    }
                    helperText={
                        formik.touched.password && formik.errors.password
                    }
                />
                <StelsSaveButton
                    variant="contained"
                    color="default"
                    fullWidth
                    type="submit"
                >
                    Отправить
                </StelsSaveButton>
            </form>
        </div>
    )
}

export default FormikInModal
