import React, { useEffect, useState } from 'react'
import { Grid, MenuItem, Select, TextField, Typography } from '@material-ui/core'
import ClientImg from '../../../images/avatar-client.jpg'
import StelsSaveButton from '../../../components/common/ButtonStyles/StelsSaveButton'
import StelsCloseButton from '../../../components/common/ButtonStyles/StelsCloseButton'
import { useFormik } from 'formik'
import {beelineAPI, putInfoCall, referencesAPI, userAPI} from '../../../api/api'
import OrderCreateModal from './order/OrderCreateModal'
import { newClientModalStyles } from '../TelephonyFormStyles'

const NewClientModal = props => {
	const client = !!props.client && !!props.client.id ? props.client : null
	const [formValue, setFormValue] = useState({
		dateCreated: new Date(),
		client: {
			id: client.id,
			name: client.name,
			phone: client.phone,
		},
		company: client.company,
		comment: '',
		callsClosingId: null,
	})

	const [isClickSave, setIsClickSave] = useState(true)
	const [createOrderOpenModal, setCreateOrderOpenModal] = useState(false)
	const [isCallClosingField, setIsCallClosingField] = useState(false)
	const [callsClosing, setCallClosing] = useState(null)
	const [levelModal, setLevelModal] = useState(0)

	useEffect(() => {
		referencesAPI.getCallsClosing().then(res => setCallClosing(res.data))
	}, [])

	const formik = useFormik({
		initialValues: { ...formValue },
		onSubmit: values => {
			setFormValue({ ...values })

			if (isClickSave) {
				userAPI
					.updateClientInTelephony({ ...values })
					.then(res => {
						setCreateOrderOpenModal(true)
					})
					.then(() => {
						beelineAPI.updateCalls(props.callId, {
							comment: values.comment,
						})
					})
			} else {
				if (isCallClosingField) {
					userAPI.updateClientInTelephony({ ...values }).then(() => {
						beelineAPI
							.updateCalls(props.callId, {
								comment: values.comment,
								callsClosingId: values.callsClosingId,
							})
							.then(() => {
								setIsClickSave(true)
								props.closeForm()
							})
					})
				}

				setIsCallClosingField(true)
			}
		},
	})

	const handleCloseClick = () => {
		setIsClickSave(false)
		const userId = JSON.parse(localStorage.getItem('userId'))
		const callId = JSON.parse(localStorage.getItem('callId'))
		levelModal == 1 && setLevelModal(0)
		levelModal == 0 && setLevelModal(1)
		levelModal == 1 && putInfoCall({ userId:userId, callId:callId })
		formik.handleSubmit()
	}

	const classes = newClientModalStyles()

	return createOrderOpenModal ? (
		<OrderCreateModal
			client={formValue.client}
			closeForm={() => props.closeForm()}
			createOrderOpenModal={createOrderOpenModal}
		/>
	) : (
		props.client && (
			<form className={classes.form} onSubmit={formik.handleSubmit}>
				<Grid container>
					<Grid item xs={6} className={classes.formInputWrapper}>
						<img src={ClientImg} alt='' className={classes.formImg} />
					</Grid>
					<Grid item xs={6} className={classes.formRightColumn}>
						<Grid item xs={12} className={classes.formInputWrapper}>
							<Typography variant={'h5'} className={classes.formTitle}>
								Входящий звонок
							</Typography>
							<Typography variant={'subtitle1'} className={classes.formPhone}>
								{props.client.phone ?? 'Телефон: -'}
							</Typography>

							{isCallClosingField ? (
								<>
									<Select
										fullWidth
										id='callsClosingId'
										className={classes.formInput}
										name='callsClosingId'
										value={formik.values.callsClosingId}
										onChange={formik.handleChange}
									>
										{callsClosing.map(call => (
											<MenuItem key={call.id} value={call.id}>
												{call.name}
											</MenuItem>
										))}
									</Select>
									<TextField
										fullWidth
										id='comment'
										name='comment'
										label='Комментарий'
										multiline={true}
										rows={6}
										variant='filled'
										className={`${classes.formInputMultiple} ${classes.formInput}`}
										value={formik.values.comment}
										onChange={formik.handleChange}
									/>
								</>
							) : (
								<>
									<TextField
										fullWidth
										id='name'
										name='client.name'
										label='Имя'
										variant='filled'
										className={classes.formInput}
										value={formik.values.client.name}
										onChange={formik.handleChange}
									/>
									<TextField
										fullWidth
										id='company'
										name='company.name'
										label='Компания'
										variant='filled'
										className={classes.formInput}
										value={formik.values?.company?.name}
										onChange={formik.handleChange}
									/>

									<TextField
										fullWidth
										id='comment'
										name='comment'
										label='Комментарий'
										multiline={true}
										rows={6}
										variant='filled'
										className={`${classes.formInputMultiple} ${classes.formInput}`}
										value={formik.values.comment}
										onChange={formik.handleChange}
									/>
								</>
							)}
						</Grid>
						<Grid item xs={12} className={classes.formButtons}>
							{!isCallClosingField && (
								<StelsSaveButton type='submit'>Создать заказ</StelsSaveButton>
							)}

							<StelsCloseButton type='button' onClick={handleCloseClick}>
								Закрыть
							</StelsCloseButton>
						</Grid>
					</Grid>
				</Grid>
			</form>
		)
	)
}

export default NewClientModal
