import ReferenceUsersIcon from '@material-ui/icons/People'
import ReferenceUsersList from './ReferenceUsersList'
import ReferenceUsersEdit from './ReferenceUsersEdit'
import ReferenceUsersCreate from './ReferenceUsersCreate'

const index = {
    icon: ReferenceUsersIcon,
    list: ReferenceUsersList,
    edit: ReferenceUsersEdit,
    create: ReferenceUsersCreate
}

export default index