const signalR = require('@microsoft/signalr')

export let connection = new signalR.HubConnectionBuilder()
	.withUrl(process.env.REACT_APP_SIGNALR_API_URL, {
		accessTokenFactory: () => localStorage.getItem('token'),
	})
	.withAutomaticReconnect([1000, 5000,7000])
	.build()

if (!localStorage.getItem('isConnection')) {
	connection
		.start()
		.then(response => {
			console.log('My connection id is ', connection.connectionId)
		})
		.catch(e => {
			console.log(e)
		})

}

window.onbeforeunload = function (e) {
	localStorage.removeItem('isConnection')
}

export default connection
