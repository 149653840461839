import SettingsIcon from '@material-ui/icons/Settings'
import SettingsEdit from "../settings/SettingsEdit";
import SettingsList from "../settings/SettingsList";
import SettingsCreate from "../settings/SettingsCreate";

const index = {
    icon: SettingsIcon,
    list: SettingsList,
    edit: SettingsEdit,
    create:SettingsCreate
}

export default index