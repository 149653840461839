import React, { useEffect, useState, useMemo } from 'react'
import {
	ArrayInput,
	DateTimeInput,
	Edit,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	FormDataConsumer,
	TextField,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsBottomToolbar from '../../components/common/ButtonsBottomToolbar'
import ButtonsBottomToolbarBid from '../../components/common/ButtonsBottomToolbarBid'
import AsyncSelectCityFromEdit from '../../components/common/SelectInput/AsyncSelectPlaceFromEdit/AsyncSelectCityFromEdit'
import AsyncSelectStreetFromEdit from '../../components/common/SelectInput/AsyncSelectPlaceFromEdit/AsyncSelectStreetFromEdit'
import AsyncSelectRegionFromEdit from '../../components/common/SelectInput/AsyncSelectPlaceFromEdit/AsyncSelectRegionFromEdit'
import { useGetIdentity, usePermissions } from 'react-admin'
import { referencesAPI } from '../../api/api'
import { ordersAPI } from '../../api/api'
import { Grid } from '@material-ui/core'
import StelsPathButton from '../../components/common/ButtonStyles/StelsPathButton'
import CarSelectInputEdit from '../../components/common/SelectInput/CarDriverSelectInput/CarSelectInputEdit'
import DriverSelectInputEdit from '../../components/common/SelectInput/CarDriverSelectInput/DriverSelectInputEdit'
import { useGlobal } from '../../../src/GlobalState'
import { disableEnter } from '../../Utils/utils'
import { useRef } from 'react'

const useStyles = makeStyles({
	inputWidth: {
		display: 'inline-flex',
		marginRight: '1%',
		width: '32.6665%',
		'&:nth-child(3n)': {
			marginRight: '0',
		},
	},
	inputNumberWidth: {
		display: 'inline-flex',
		width: '32.6665%',
	},
	inputWidthCity: {
		display: 'inline-block',
		width: '49.5%',
	},
	inputMLCity: {
		marginLeft: '0.5%',
	},
	inputMRCity: {
		marginRight: '0.5%',
	},
	inputFullWidth: {
		display: 'inline-flex',
		width: '100%',
	},
	inputCarWidth: {
		display: 'inline-block',
		marginRight: '1%',
		width: '49.5%',
	},
	inputDriverWidth: {
		display: 'inline-block',
		marginRight: '1%',
		width: '49.5%',
	},
})

const OrdersEdit = React.memo(props => {
	const [regionNameFrom, setRegionNameFrom] = useState(null)
	const [cityIdFrom, setCityIdFrom] = useState(null)
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()
	const [order, setOrder] = useState(null)
	const [manager, setManager] = useState(null)
	const [size, setSize] = useState(null)
	const [date, setDate] = useState(null)
	const [stageorder, setStageOrder] = useState(null)
	const { identity, loading: identityLoading } = useGetIdentity()
	const { loading, permissions } = usePermissions()
	const [carId, setCarId] = useState(null)
	const [DriverCar, setDriverCar] = useState(false)

	const SizeEdit = dimmm => {
		referencesAPI.getDimension(dimmm).then(res => setSize(res))
	}

	const [globalState, globalActions] = useGlobal()
	const onSuccess = () => {
		const validateStreetLocal = JSON.parse(localStorage.getItem('validateStreet'))
		if (validateStreetLocal === false) return

		notify('Изменения сохранены')
		redirect('/orders')
		refresh()
	}
	const onFailure = error => {
		notify(`${error.message}`, 'error')
	}
	const classes = useStyles()
	const Title = () => {
		if (globalState.selectOrder?.cityFrom?.city && globalState.selectOrder?.cityTo?.city) {
			return (
				<span>
					Заказ {globalState.selectOrder?.cityFrom?.city?.name} -{' '}
					{globalState.selectOrder?.cityTo?.city?.name}
				</span>
			)
		} else {
			return <span>Заказ {globalState.selectOrder ? `${globalState.selectOrder.id}` : ''}</span>
		}
	}
	const handleCreatePath = () => {
		setPathOrder(<RouteOrder />)
	}
	const handleHidePath = () => {
		setPathOrder(null)
	}
	const [pathOrder, setPathOrder] = useState()
	const RouteOrder = props => {
		return (
			<>
				<ArrayInput source='pointOrders' label='Маршрут заказа'>
					<SimpleFormIterator>
						<FormDataConsumer>
							{({ getSource, scopedFormData }) => {
								return (
									<>
										<AsyncSelectRegionFromEdit
											label='Регион'
											source={getSource('regionId')}
											record={scopedFormData}
											onSelectRegionNameFrom={regionNameFrom => {
												setRegionNameFrom(regionNameFrom)
											}}
											formClassName={`${classes.inputFullWidth}`}
										/>
										<AsyncSelectCityFromEdit
											label='Город'
											source={getSource('cityId')}
											record={scopedFormData}
											onSelectCityIdFrom={cityIdFrom => {
												setCityIdFrom(cityIdFrom)
											}}
											regionNameFrom={regionNameFrom}
											formClassName={`${classes.inputFullWidth}`}
										/>
										<AsyncSelectStreetFromEdit
											label='Улица'
											source={getSource('street')}
											record={scopedFormData}
											formClassName={`${classes.inputFullWidth}`}
										/>

										<TextInput
											onKeyDown={disableEnter}
											source={getSource('house')}
											record={scopedFormData}
											label='Дом'
											margin='none'
											helperText=''
											formClassName={`${classes.inputFullWidth}`}
											fullWidth
										/>
										<TextInput
											onKeyDown={disableEnter}
											source={getSource('apartment')}
											record={scopedFormData}
											label='Квартира'
											margin='none'
											helperText=''
											formClassName={`${classes.inputFullWidth} `}
											fullWidth
										/>
									</>
								)
							}}
						</FormDataConsumer>
					</SimpleFormIterator>
				</ArrayInput>
				<Grid item xs={12} className={classes.formButtons}>
					{!pathOrder && (
						<StelsPathButton type='button' onClick={() => handleHidePath()}>
							Скрыть маршрут
						</StelsPathButton>
					)}
				</Grid>
			</>
		)
	}

	const CarSelectOrder = () => {
		return (
			<CarSelectInputEdit
				onSelectCar={carId => {
					setCarId(carId)
					setDriverCar(true)
				}}
				DimensionLength={size != null ? size?.length : 0}
				DimensionWidth={size != null ? size?.width : 0}
				DimensionHeight={size != null ? size?.height : 0}
				Stage={stageorder ?? 1}
				DateStart={date && date !== undefined ? date : new Date()}
				formClassName={classes.inputCarWidth}
				fullWidth
				resetable
			/>
		)
	}

	const DriverSelectOrder = React.memo(({ record }) => {
		return (
			<DriverSelectInputEdit
				CarId={carId}
				DriverCar={DriverCar}
				formClassName={classes.inputDriverWidth}
				fullWidth
				onSelectDriver={() => {
					setDriverCar(false)
				}}
				record={record}
			/>
		)
	})

	const getDateStartOrder = async () => {
		if (!date) {
			setDate(globalState.selectOrder.dateStartOrder)
		}
	}

	useEffect(() => {
		globalActions.initialOrder(props.id, 'selectOrder')
	}, [])

	useEffect(() => {
		globalState.selectOrder && setManager(globalState.selectOrder.managerId) && getDateStartOrder()
	}, [globalState.selectOrder])

	useMemo(() => {
		RouteOrder()
	}, [globalState.region])

	useMemo(() => {
		CarSelectOrder()
	}, [size, date, stageorder])

	return (
		<Edit
			title={<Title />}
			onSuccess={onSuccess}
			onFailure={onFailure}
			mutationMode='pessimistic'
			{...props}
		>
			<SimpleForm
				toolbar={
					globalState.selectOrder?.typeOrder === 1 ? (
						<ButtonsBottomToolbar />
					) : globalState.selectOrder?.typeOrder === 2 ? (
						<ButtonsBottomToolbarBid />
					) : null
				}
			>
				{permissions !== 'Driver' ? (
					<DateTimeInput
						onKeyDown={disableEnter}
						source='dateCreated'
						label='Дата и время создания заказа'
						formClassName={classes.inputWidth}
						fullWidth
					/>
				) : null}
				{permissions !== 'Driver' ? (
					<DateTimeInput
						onKeyDown={disableEnter}
						source='dateStartOrder'
						label='Дата и время начала заказа'
						formClassName={classes.inputWidth}
						fullWidth
						onChange={event => {
							setDate(event.target.value)
						}}
					/>
				) : null}
				{permissions !== 'Driver' ? (
					<DateTimeInput
						onKeyDown={disableEnter}
						source='dateEndOrder'
						label='Дата и время завершения заказа'
						formClassName={classes.inputWidth}
						fullWidth
					/>
				) : null}

				<ReferenceInput
					onKeyDown={disableEnter}
					reference='clients'
					source='clientId'
					label='Клиент'
					formClassName={classes.inputWidth}
					fullWidth
				>
					<SelectInput optionText={choice => `${choice.fio} ${choice.phone}`} />
				</ReferenceInput>
				<TextInput
					onKeyDown={disableEnter}
					reference='reference/company'
					source='client.company.name'
					label='Компания'
					formClassName={classes.inputWidth}
					fullWidth
				/>
				{permissions !== 'Driver' ? (
					<ReferenceInput
						onKeyDown={disableEnter}
						reference='reference/dimensions'
						source='dimensionId'
						label='Габариты'
						formClassName={classes.inputWidth}
						fullWidth
						resettable
						onChange={event => SizeEdit(event.target.value)}
					>
						<SelectInput optionText='sizeCar' />
					</ReferenceInput>
				) : null}
				{permissions !== 'Driver' && (
					<CarSelectOrder formClassName={classes.inputWidth} fullWidth resettable />
				)}
				{permissions !== 'Driver' && (
					<DriverSelectOrder formClassName={classes.inputWidth} fullWidth resettable />
				)}
				<NumberInput
					onKeyDown={disableEnter}
					source='countWorkers'
					label='Кол-во грузчиков'
					formClassName={classes.inputWidth}
					fullWidth
				/>
				{permissions === 'Driver' && order && (
					<ReferenceInput
						reference='reference/stages'
						source='stageId'
						sort={{ field: 'order', order: 'ASC' }}
						filter={{ ordersId: globalState.selectOrder.id }}
						label='Стадия заказа'
						formClassName={classes.inputWidth}
						fullWidth
						resettable
						onChange={event => {
							event &&
								referencesAPI.getStage(event.target.value).then(res => {
									setStageOrder(res.order)
								})
						}}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				)}
				{permissions !== 'Driver' && (
					<ReferenceInput
						reference='reference/stages'
						source='stageId'
						sort={{ field: 'order', order: 'ASC' }}
						label='Стадия заказа'
						formClassName={classes.inputWidth}
						fullWidth
						resettable
						onChange={event => {
							event &&
								referencesAPI.getStage(event.target.value).then(res => {
									setStageOrder(res.order)
								})
						}}
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				)}
				{permissions !== 'Driver' ? (
					<ReferenceInput
						reference='reference/payments'
						source='paymentId'
						label='Способ оплаты'
						resettable
						formClassName={classes.inputWidth}
						fullWidth
					>
						<SelectInput optionText='name' />
					</ReferenceInput>
				) : null}
				<TextInput
					label='Тариф'
					source='tariff'
					formClassName={classes.inputWidth}
					fullWidth
					onKeyDown={disableEnter}
				/>
				<NumberInput source='price' label='Цена' formClassName={classes.inputWidth} fullWidth />
				{permissions !== 'Driver' ? (
					<ReferenceInput
						reference='reference/users'
						source='managerId'
						label='Ответственный логист'
						resettable
						formClassName={classes.inputWidth}
						filter={{ roleuser: 'Logist' }}
						fullWidth
						allowEmpty
					>
						<SelectInput optionText='fio' />
					</ReferenceInput>
				) : null}
				<TextInput
					onKeyDown={disableEnter}
					label='Комментарий'
					source='comment'
					multiline
					formClassName={classes.inputWidth}
					fullWidth
				/>
				{((permissions === 'Logist' && manager === parseInt(identity.id)) ||
					permissions === 'Driver') && (
					<NumberInput
						onKeyDown={disableEnter}
						source='durationOrder'
						label='Длительность заказа'
						className={classes.inputNumberWidth}
						fullWidth
					/>
				)}
				{permissions === 'Driver' && (
					<TextField source='payment.name' label='Способ оплаты' onKeyDown={disableEnter} />
				)}
				{permissions === 'Driver' && (
					<TextField
						source='responsible.fio'
						label='Ответственный логист'
						onKeyDown={disableEnter}
					/>
				)}
				{permissions === 'Driver' && (
					<TextField source='responsible.phoneNumber' label='' onKeyDown={disableEnter} />
				)}
				{pathOrder}
				<Grid item xs={12} className={classes.formButtons}>
					{!pathOrder && (
						<StelsPathButton type='button' onClick={() => handleCreatePath()}>
							Показать маршрут
						</StelsPathButton>
					)}
				</Grid>
			</SimpleForm>
		</Edit>
	)
})

export default OrdersEdit
