import React from "react";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { Button, useNotify } from "react-admin";
import { Grid, TextField } from "@material-ui/core";
import IconCancel from "@material-ui/icons/Cancel";
import { ClientStyles } from "./ClientStyles";
import TextMaskPhone from "../../../../components/common/TextMaskPhone";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { beelineAPI, userAPI } from "../../../../api/api";

export const ClientCreateForm = ({ client, handleClose, processingCallId }) => {
  const notify = useNotify();
  const initialValues = {
    name: client.name ?? "",
    surname: client.surname ?? "",
    patronymic: client.patronymic ?? "",
    additionalPhone: client.additionalPhone ?? "",
    phone: client.phone ?? "",
    company: {
      name: client.companyName ?? "",
    },
    corporate: false,
  };

  const validationSchema = yup.object({
    name: yup.string("Введите имя").required("Обязательно для заполнения"),
  });

  const submit = async (values) => {
    await userAPI
      .updateClient(client.id, values)
      .then(async (res) => {
        await beelineAPI.updateCalls(processingCallId, {
          isWorkedOutCall: true,
        });

        return res.data;
      })
      .then((data) => {
        handleClose(data);
        notify("Клиент успешно обновлен");
      })
      .catch((error) => {
        notify(error.message, "error");
      });
  };

  const classes = ClientStyles();

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={submit}
        validationSchema={validationSchema}
      >
        {({ handleChange, values, setFieldValue, errors, touched }) => {
          return (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    name="name"
                    label="Имя"
                    variant="filled"
                    value={values.name}
                    onChange={handleChange}
                    className={classes.inputWidth}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />

                  <TextField
                    name="surname"
                    label="Фамилия"
                    variant="filled"
                    value={values.surname}
                    onChange={handleChange}
                    className={classes.inputWidth}
                  />

                  <TextField
                    name="patronymic"
                    label="Отчество"
                    variant="filled"
                    value={values.patronymic}
                    onChange={handleChange}
                    className={classes.inputWidth}
                  />

                  <FormControlLabel
                    className={`${classes.inputWidth} ${classes.checkbox}`}
                    control={
                      <Field
                        component={Switch}
                        type="checkbox"
                        color="primary"
                        name="corporate"
                        id="corporate"
                        onChange={handleChange}
                      />
                    }
                    label="Юридическое лицо"
                  />

                  <TextField
                    name="phone"
                    label="Телефон"
                    variant="filled"
                    value={values.phone}
                    onChange={handleChange}
                    className={classes.inputWidth}
                    disabled
                    InputProps={{
                      inputComponent: TextMaskPhone,
                    }}
                  />

                  <TextField
                    name="additionalPhone"
                    label="Дополнительный телефон"
                    variant="filled"
                    value={values.additionalPhone}
                    onChange={handleChange}
                    className={classes.inputWidth}
                    InputProps={{
                      inputComponent: TextMaskPhone,
                    }}
                  />

                  {values.corporate && (
                    <TextField
                      fullWidth
                      name="company.name"
                      label="Компания"
                      variant="filled"
                      className={classes.inputWidth}
                      value={values.company.name}
                      onChange={handleChange}
                    />
                  )}
                </Grid>

                <Grid item xs={12} className={classes.formButtons}>
                  <Button
                    label="Закрыть"
                    onClick={() => {
                      handleClose("closeClick");
                    }}
                    variant={"outlined"}
                    color={"default"}
                  >
                    <IconCancel style={{ color: "rgba(0, 0, 0, 0.23)" }} />
                  </Button>
                  <Button
                    label="Сохранить"
                    variant="contained"
                    color="primary"
                    type="submit"
                  />
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
