import * as React from 'react'
import { Show, SimpleShowLayout } from 'react-admin'
import OrderCardShowComponent from './OrderCardShowComponent'

const Title = ({ record }) => {
    if (record.cityFrom.city && record.cityTo.city) {
        return (
            <span>
                Заказ {record.cityFrom.city.name} - {record.cityTo.city.name}
            </span>
        )
    } else {
        return <span>Заказ {record ? `${record.id}` : ''}</span>
    }
}

const OrderCard = ({permissions, ...props}) => {
    return (
        <Show actions={permissions === 'Driver' && false} title={<Title />} component={OrderCardShowComponent} {...props}>
            <SimpleShowLayout></SimpleShowLayout>
        </Show>
    )
}

export default OrderCard