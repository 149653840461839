import React, { useCallback, useState } from 'react'
import CreateClientModalButton from './CreateClientModalButton'
import AutocompleteSelectWithDefaultValue from '../../../components/common/Autocomplete/AutocompleteSelectWithDefaultValue'
import { userAPI } from '../../../api/api'

const CustomFieldClient = (props) => {
    const [defaultValue, setDefaultValue] = useState()
    const handleChange = useCallback((data) => {
        setDefaultValue(data)
    }, [])

    return (
        <>
            <AutocompleteSelectWithDefaultValue
                targetList={{
                    get: userAPI.getClients,
                    getInputChange: userAPI.getClientsForFilter,
                    type: 'normal',
                }}
                name="clientId"
                optionLabel="fio phone"
                label="Клиент"
                defaultValue={defaultValue || null}
                {...props}
            />

            <CreateClientModalButton onChange={handleChange} />
        </>
    )
}

export default CustomFieldClient
