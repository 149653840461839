import { Filter } from 'react-admin'
import React, { useEffect, useState } from 'react'
import { referencesAPI, userAPI,carsAPI,ordersAPI } from '../../api/api'
import { svidaAPI } from '../../api/svidaApi'
import AutocompleteSelectFilter from '../../components/common/Autocomplete/AutocompleteSelectFilter'
import { makeStyles } from '@material-ui/core/styles'
import {Link, Typography} from "@material-ui/core";

const AutoparkFilter = props => {
	const [dimensions, setDimensions] = useState([])
	const [href, setHref] = useState("/#/orders")
	const [hrefHeader, sethrefHeader] = useState("Количество нераспределенных заказов на сегодня")
	const createHref = (data) => (
		ordersAPI.getOrdersByCarId(data[0].id).then(response => {
		console.log('response',response)
		sethrefHeader(`Количество нераспределенных заказов на сегодня ${response.data.length}`) }) &&
		 setHref(`/#/orders?filter={"carId":${data[0].id}}`)
	)
	useEffect(() => {
		referencesAPI.getDimensions().then(res => setDimensions(res.data))
		carsAPI.getDefaultCar().then(response =>
		     response.data.length > 0 && createHref(response.data)
			 )
	}, [])
	const useStyles = makeStyles(theme => ({
		inputStyle: {
			width: '220px',
			marginBottom: '6px',
		},
		inputAutocompleteStyle: {
			width: '456px',
			margin: '16px 0 2px',
		},
		waitOrdersStyle: {
			width: '456px',
			margin: '16px 0 0',
		},
	}))

	const classes = useStyles()
	return (
		<>
		<Filter {...props}>
			<AutocompleteSelectFilter
				targetList={{
					get: referencesAPI.getDimensions,
					getInputChange: userAPI.getDimensionsForFilter,
					type: 'normal',
				}}
				source='dimensionsId'
				optionLabel='sizeCar volume'
				label='Габариты'
				className={classes.inputAutocompleteStyle}
				alwaysOn
			/>
			<AutocompleteSelectFilter
				targetList={{
					get: svidaAPI.getCities,
					getInputChange: svidaAPI.getCityByName,
					type: 'cities',
				}}
				source='placeId'
				label='Город'
				className={classes.inputAutocompleteStyle}
				alwaysOn
			/>
		</Filter>
			<Typography className={classes.waitOrdersStyle} variant="body2" color="textSecondary" gutterBottom>
				<Link href={href}>
					{hrefHeader}
				</Link>
			</Typography>
		</>
	)
}

export default AutoparkFilter
