import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Button,
	Box,
} from '@material-ui/core'
import StelsSaveButton from '../../../../components/common/ButtonStyles/StelsSaveButton'
import StelsCloseButton from '../../../../components/common/ButtonStyles/StelsCloseButton'
import { putInfoCall, referencesAPI, userAPI } from '../../../../api/api'
import SelectRegionFrom from './SelectCityAndStreet/SelectRegionFrom'
import SelectRegionTo from './SelectCityAndStreet/SelectRegionTo'
import SelectCityFrom from './SelectCityAndStreet/SelectCityFrom'
import SelectStreetFrom from './SelectCityAndStreet/SelectStreetFrom'
import SelectCityTo from './SelectCityAndStreet/SelectCityTo'
import SelectStreetTo from './SelectCityAndStreet/SelectStreetTo'
import { appDataProvider } from '../../../../api/rest/rest'
import { deepCopyFunction, disableEnter, sortByField } from '../../../../Utils/utils'
import { orderCreateFormStyles } from '../../TelephonyFormStyles'

const OrderCreateForm = props => {
	const [regionIdFrom, setRegionIdFrom] = useState(27)
	const [regionIdTo, setRegionIdTo] = useState(27)
	const [cityIdFrom, setCityIdFrom] = useState(92732)
	const [cityIdTo, setCityIdTo] = useState(92732)
	const [streetIdFrom, setStreetIdFrom] = useState(null)
	const [streetIdTo, setStreetIdTo] = useState(null)
	const [additionalRegion, setAdditionalRegion] = useState({
		0: 27,
		1: 27,
		2: 27,
		3: 27,
		4: 27,
		5: 27,
		6: 27,
		7: 27,
	})
	const [additionalCity, setAdditionalCity] = useState({
		0: 92732,
		1: 92732,
		2: 92732,
		3: 92732,
		4: 92732,
		5: 92732,
		6: 92732,
		7: 92732,
	})
	const [lists, setLists] = useState({
		dimensions: [],
		stages: [],
		payments: [],
		managers: [],
	})
	const [loading, setLoading] = useState(false)
	const [initialValues, setInitialValues] = useState({
		dateCreated: null,
		dateStartOrder: null,
		dimensionId: null,
		countWorkers: null,
		stageId: null,
		paymentId: null,
		price: null,
		managerId: null,
		comment: null,
		durationOrder: 60,
		clientId: props.client.id,
		cityFrom: {
			region: { id: 27 },
			city: { id: 92732 },
			street: null,
			house: null,
			apartment: null,
		},
		cityTo: { region: { id: 27 }, city: { id: 92732 }, street: null, house: null, apartment: null },
		pointOrders: [
			{ regionId: 27, cityId: 92732, street: null, house: null, apartment: null },
			{ regionId: 27, cityId: 92732, street: null, house: null, apartment: null },
		],
		additionalPointOrders: [],
	})

	useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		if (active) {
			appDataProvider
				.getList(`reference/dimensions`, {
					pagination: { page: 1, perPage: 100 },
					sort: 'ASC',
				})
				.then(res => setLists(list => ({ ...list, dimensions: res.data })))
			// referencesAPI.getDimensions().then((res) => setLists((list) => ({ ...list, dimensions: res.data })))
			referencesAPI.getStages().then(res => {
				let stages = res.data.sort(sortByField('order'))
				return setLists(list => ({ ...list, stages }))
			})
			referencesAPI.getPayments().then(res => setLists(list => ({ ...list, payments: res.data })))
			userAPI.getLogisticiansRange([0,99]).then(res => setLists(list => ({ ...list, managers: res })))
		}

		return () => {
			active = false
		}
	}, [loading])

	useEffect(() => {
		if (props.orderInfoForModal) {
			let orderData = props.orderInfoForModal.orderData

			if (deepCopyFunction(orderData)) {
				setInitialValues(deepCopyFunction(orderData))
			}

			let cityFromId =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityFrom &&
				props.orderInfoForModal.orderData.cityFrom.city.id

			let cityToId =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityTo &&
				props.orderInfoForModal.orderData.cityTo.city.id
			let streetFromId =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityFrom &&
				props.orderInfoForModal.orderData.cityFrom.street.id
			let streetToId =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityTo &&
				props.orderInfoForModal.orderData.cityTo.street.id

			setCityIdFrom(cityFromId)
			setCityIdTo(cityToId)
			setStreetIdFrom(streetFromId)
			setStreetIdTo(streetToId)
			let points = [
				{ cityId: 92732, street: null, house: null, apartment: null },
				{ cityId: 92732, street: null, house: null, apartment: null },
			]
			points[0].cityId =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityFrom &&
				props.orderInfoForModal.orderData.cityFrom.city.id
			points[0].street =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityFrom.street &&
				props.orderInfoForModal.orderData.cityFrom.street.id
			points[0].house =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityFrom &&
				props.orderInfoForModal.orderData.cityFrom.house
			points[0].apartment =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityFrom &&
				props.orderInfoForModal.orderData.cityFrom.apartment

			points[1].cityId =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityTo &&
				props.orderInfoForModal.orderData.cityTo.city.id
			points[1].street =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityTo.street &&
				props.orderInfoForModal.orderData.cityTo.street.id
			points[1].house =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityTo &&
				props.orderInfoForModal.orderData.cityTo.house
			points[1].apartment =
				props.orderInfoForModal &&
				props.orderInfoForModal.orderData &&
				props.orderInfoForModal.orderData.cityTo &&
				props.orderInfoForModal.orderData.cityTo.apartment

			let tzoffset = new Date().getTimezoneOffset() * 60000
			let dateNow = new Date(Date.now() - tzoffset).toISOString().slice(0, -8)
			setInitialValues(initialValues => ({
				...initialValues,
				dateCreated: dateNow,
				dateStartOrder: dateNow,
				pointOrders: points,
			}))
		} else {
			let tzoffset = new Date().getTimezoneOffset() * 60000
			let dateNow = new Date(Date.now() - tzoffset).toISOString().slice(0, -8)
			setInitialValues(initialValues => ({
				...initialValues,
				dateCreated: dateNow,
				dateStartOrder: dateNow,
			}))
		}

		setLoading(true)
	}, [props.orderInfoForModal, props.client.id])
	const submit = values => {
		const validateStreetLocal = JSON.parse(localStorage.getItem('validateStreet'))
		if (validateStreetLocal === false) return

		values.cityFrom.city.id = cityIdFrom
		values.cityTo.city.id = cityIdTo
		values.pointOrders[0].cityId = cityIdFrom
		values.pointOrders[1].cityId = cityIdTo
		values.pointOrders[0].street = streetIdFrom
		values.pointOrders[1].street = streetIdTo
		for (let i = 0; i < values.additionalPointOrders.length; i++) {
			values.additionalPointOrders[i].cityId = additionalCity[i]
		}
		appDataProvider.create('orders', { data: { ...values } }).then(() => props.openModal())
	}
	const classes = orderCreateFormStyles()
	const handlePointOrders = (addOrDelete, values) => {
		const { additionalPointOrders } = initialValues

		if (additionalPointOrders.length >= 7 && addOrDelete === 'add') {
			return
		}
		if (addOrDelete === 'add') {
			setInitialValues({
				...initialValues,
				additionalPointOrders: [
					...additionalPointOrders,
					{ cityId: 92732, street: null, house: null, apartment: null },
				],
			})
			values.additionalPointOrders.push({
				cityId: 92732,
				street: null,
				house: null,
				apartment: null,
			})
		}
		if (addOrDelete === 'delete') {
			const lastIndex = additionalPointOrders.length - 1
			const deleteLastIndex = additionalPointOrders.slice(0, lastIndex)
			setInitialValues({
				...initialValues,
				additionalPointOrders: deleteLastIndex,
			})
			values.additionalPointOrders = deleteLastIndex
		}
	}
	return (
		loading && (
			<div>
				<Formik initialValues={initialValues} onSubmit={submit} >
					{({ handleChange, values, setFieldValue }) => (
						<Form className={classes.formikWidth}>
							<FormControl className={classes.inputNewWidth}>
								<TextField
									variant='filled'
									onKeyDown={disableEnter}
									id='dateCreated'
									// type="datetime-local"
									name='dateCreated'
									value={values.dateCreated}
									onChange={handleChange}
									type='hidden'
									InputLabelProps={{
										shrink: true,
									}}
								/>
								<TextField
									onKeyDown={disableEnter}
									variant='filled'
									id='dateStartOrder'
									label='Дата и время заказа'
									type='datetime-local'
									name='dateStartOrder'
									value={values.dateStartOrder}
									onChange={handleChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</FormControl>

							<TextField
								onKeyDown={disableEnter}
								name='clientId'
								label='Клиент'
								variant='filled'
								disabled
								value={props.client.name}
								className={classes.inputNewWidth}
							/>

							<FormControl variant='filled' className={classes.inputNewWidth}>
								<InputLabel id='dimension-select-filled-label'>Габариты авто</InputLabel>
								<Select
									onKeyDown={disableEnter}
									id='dimensionId'
									labelId='dimension-select-filled-label'
									name='dimensionId'
									value={values.dimensionId}
									onChange={handleChange}
									fullWidth
								>
									{lists.dimensions.map(dimension => (
										<MenuItem key={dimension.id} value={dimension.id}>
											{`${dimension.sizeCar}`}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<TextField
								onKeyDown={disableEnter}
								id='countWorkers'
								name='countWorkers'
								type='number'
								variant='filled'
								label='Кол-во грузчиков'
								value={values.countWorkers}
								onChange={handleChange}
								className={classes.inputNewWidth}
							/>

							<FormControl variant='filled' className={classes.inputNewWidth}>
								<InputLabel id='stage-select-filled-label'>Стадия заказа</InputLabel>
								<Select
									onKeyDown={disableEnter}
									id='stageId'
									labelId='stage-select-filled-label'
									name='stageId'
									value={values.stageId}
									onChange={handleChange}
									fullWidth
								>
									{lists.stages.map(stage => (
										<MenuItem key={stage.id} value={stage.id}>
											{stage.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<FormControl variant='filled' className={classes.inputNewWidth}>
								<InputLabel id='payment-select-filled-label'>Способ оплаты</InputLabel>
								<Select
									onKeyDown={disableEnter}
									id='paymentId'
									labelId='payment-select-filled-label'
									name='paymentId'
									value={values.paymentId}
									onChange={handleChange}
								>
									{lists.payments.map(payment => (
										<MenuItem key={payment.id} value={payment.id}>
											{payment.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
							<TextField
								onKeyDown={disableEnter}
								id='tariff'
								name='tariff'
								label='Тариф'
								variant='filled'
								value={values.tariff}
								onChange={handleChange}
								className={classes.inputNewWidth}
							/>
							<TextField
								onKeyDown={disableEnter}
								id='price'
								name='price'
								type='number'
								variant='filled'
								label='Цена'
								value={values.price}
								onChange={handleChange}
								className={classes.inputNewWidth}
							/>
							<FormControl variant='filled' className={classes.inputNewWidth}>
								<InputLabel id='manager-select-filled-label'>Ответственный логист</InputLabel>
								<Select
									onKeyDown={disableEnter}
									id='managerId'
									labelId='manager-select-filled-label'
									name='managerId'
									value={values.managerId}
									onChange={handleChange}
								>
									{lists.managers.map(manager => (
										<MenuItem key={manager.id} value={manager.id}>
											{manager.fio}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							<TextField
								onKeyDown={disableEnter}
								id='comment'
								name='comment'
								label='Комментарий'
								variant='filled'
								multiline
								rowsMax='3'
								value={values.comment}
								onChange={handleChange}
								className={classes.inputNewWidthComment}
								fullWidth
							/>
							<Box className={classes.addButton}>
								<Button onClick={() => handlePointOrders('add', values)}>Добавить точку</Button>
								<Button onClick={() => handlePointOrders('delete', values)}>Удалить точку</Button>
							</Box>
							<TextField
								onKeyDown={disableEnter}
								id='durationOrder'
								initialValue={60}
								formClassName={classes.inputHidden}
								type='hidden'
							/>
							<SelectRegionFrom
								name='pointOrders[0].regionId'
								initialValue={27}
								value={values.cityFrom?.region?.id}
								className={classes.inputNewWidthCity}
								margin='none'
								helperText=''
								onChange={handleChange}
								setFieldValue={setFieldValue}
								onSelectRegionIdFrom={regionIdFrom => {
									setRegionIdFrom(regionIdFrom)
								}}
							/>
							<SelectCityFrom
								name='pointOrders[0].cityid'
								initialValue={92732}
								value={values.cityFrom.city.id}
								RegionIdFrom={regionIdFrom}
								className={classes.inputNewWidthCity}
								onChange={handleChange}
								margin='none'
								helperText=''
								setFieldValue={setFieldValue}
								onSelectCityIdFrom={cityIdFrom => {
									setCityIdFrom(cityIdFrom)
								}}
							/>
							<SelectStreetFrom
								name='pointOrders[0].street'
								value={values.pointOrders[0].street}
								className={classes.inputNewWidthCity}
								onChange={handleChange}
								margin='none'
								helperText=''
								setFieldValue={setFieldValue}
								resettable
								cityIdFrom={cityIdFrom}
								onSelectStreetIdFrom={streetIdFrom => {
									setStreetIdFrom(streetIdFrom)
								}}
							/>
							<TextField
								onKeyDown={disableEnter}
								name='pointOrders[0].house'
								label='Откуда: Дом'
								variant='filled'
								className={classes.inputNewWidthHouse}
								value={values.cityFrom.house}
								onChange={handleChange}
								margin='none'
								helperText=''
								resettable
							/>
							<TextField
								onKeyDown={disableEnter}
								name='pointOrders[0].apartment'
								label='Откуда: Квартира'
								variant='filled'
								className={classes.inputNewWidthHouse}
								value={values.cityFrom.apartment}
								onChange={handleChange}
								resettable
							/>
							<SelectRegionTo
								name='pointOrders[1].regionId'
								initialValue={27}
								value={values.cityTo?.region?.id}
								className={classes.inputNewWidthCity}
								onChange={handleChange}
								margin='none'
								helperText=''
								setFieldValue={setFieldValue}
								onSelectRegionIdTo={regionIdTo => {
									setRegionIdTo(regionIdTo)
								}}
							/>
							<SelectCityTo
								name='pointOrders[1].cityid'
								initialValue={92732}
								value={values.cityTo.city.id}
								RegionIdTo={regionIdTo}
								className={classes.inputNewWidthCity}
								onChange={handleChange}
								margin='none'
								helperText=''
								setFieldValue={setFieldValue}
								onSelectCityIdTo={cityIdTo => {
									setCityIdTo(cityIdTo)
								}}
							/>
							<SelectStreetTo
								name='pointOrders[1].street'
								value={values.pointOrders[1].street}
								className={classes.inputNewWidthStreet}
								onChange={handleChange}
								margin='none'
								helperText=''
								setFieldValue={setFieldValue}
								cityIdTo={cityIdTo}
								resettable
								onSelectStreetIdTo={streetIdTo => {
									setStreetIdTo(streetIdTo)
								}}
							/>
							<TextField
								onKeyDown={disableEnter}
								name='pointOrders[1].house'
								label='Куда: Дом'
								variant='filled'
								className={classes.inputNewWidthHouse}
								value={values.cityTo.house}
								margin='none'
								helperText=''
								onChange={handleChange}
								resettable
							/>
							<TextField
								onKeyDown={disableEnter}
								name='pointOrders[1].apartment'
								label='Куда: Квартира'
								variant='filled'
								className={classes.inputNewWidthHouse}
								value={values.cityTo.apartment}
								onChange={handleChange}
								margin='none'
								helperText=''
								resettable
							/>
							{initialValues.additionalPointOrders &&
								initialValues.additionalPointOrders.length > 0 &&
								initialValues.additionalPointOrders.map((item, index) => {
									return (
										<Box key={index} className={classes.boxInputs}>
											<SelectRegionTo
												name={`additionalPointOrders[${index}].regionId`}
												initialValue={27}
												value={values.additionalPointOrders[index]?.region?.id}
												className={classes.inputNewWidthCity}
												onChange={handleChange}
												fullWidth
												margin='none'
												helperText=''
												setFieldValue={setFieldValue}
												onSelectRegionIdTo={regionIdTo => {
													setAdditionalRegion({ ...additionalRegion, [index]: regionIdTo })
												}}
											/>
											<SelectCityTo
												name={`additionalPointOrders[${index}].cityid`}
												initialValue={92732}
												value={values.additionalPointOrders[index]?.city?.id}
												RegionIdTo={additionalRegion[index]}
												className={classes.inputNewWidthCity}
												onChange={handleChange}
												fullWidth
												margin='none'
												helperText=''
												setFieldValue={setFieldValue}
												onSelectCityIdTo={cityIdTo => {
													setAdditionalCity({ ...additionalCity, [index]: cityIdTo })
												}}
											/>
											<SelectStreetTo
												name={`additionalPointOrders[${index}].street`}
												value={values.additionalPointOrders[index]?.street}
												className={classes.inputNewWidthStreet}
												onChange={handleChange}
												fullWidth
												margin='none'
												helperText=''
												setFieldValue={setFieldValue}
												cityIdTo={additionalCity[index]}
												resettable
												onSelectStreetIdTo={streetIdTo => {
													values.additionalPointOrders[index].street = streetIdTo
												}}
											/>
											<TextField
												onKeyDown={disableEnter}
												name={`additionalPointOrders[${index}].house`}
												label='Куда: Дом'
												variant='filled'
												className={classes.inputNewWidthHouse}
												value={values.additionalPointOrders[index]?.house}
												margin='none'
												helperText=''
												onChange={handleChange}
												resettable
											/>
											<TextField
												onKeyDown={disableEnter}
												name={`additionalPointOrders[${index}].apartment`}
												label='Куда: Квартира'
												variant='filled'
												className={classes.inputNewWidthHouse}
												value={values.additionalPointOrders[index]?.apartment}
												onChange={handleChange}
												margin='none'
												helperText=''
												resettable
											/>
										</Box>
									)
								})}
								<div className={classes.buttons}>
								<StelsSaveButton variant='contained' color='default' type='submit'
												 onClick={() => {
													 const userId = JSON.parse(localStorage.getItem('userId'))
													 const callId = JSON.parse(localStorage.getItem('callId'))
													 putInfoCall({ userId:userId,callId:callId })
												 }}
								>
									Создать
								</StelsSaveButton>
								<StelsCloseButton
									variant='contained'
									color='default'
									type='close'
									onClick={() => {
										const userId = JSON.parse(localStorage.getItem('userId'))
										const callId = JSON.parse(localStorage.getItem('callId'))
										putInfoCall({ userId:userId,callId:callId })
									}}
								>
									Отмена
								</StelsCloseButton>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		)
	)
}

export default OrderCreateForm
