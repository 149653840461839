import React, { useEffect, useState } from "react";
import {
  Edit,
  SelectInput,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { appDataProvider } from "../../../api/rest/rest";
import {
  validateName,
  validatePhoneNumberFunction,
  validateRequired,
} from "../../../validators/validate";
import TextMaskPhone from "../../../components/common/TextMaskPhone";
import ButtonsBottomToolbar from "../../../components/common/ButtonsBottomToolbar";

const Title = ({ record }) => {
  return <span>Пользователь {record ? `${record.name}` : ""}</span>;
};

const useStyles = makeStyles({
  inputWidth: {
    display: "inline-flex",
    marginRight: "1%",
    width: "32.6665%",
    "&:nth-child(3n)": {
      marginRight: "0",
    },
  },
  inputWrapper: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.13)",
    },
    "& label + div:hover:not(.Mui-disabled):before": {
      borderBottomWidth: "1px",
    },
    "& label + div.Mui-focused": {
      borderBottomWidth: "2px",
    },
  },
  customLabel: {
    margin: "8px 0 0 8px",
  },
  customInput: {
    "& > *": {
      margin: "0 8px 0",
    },
  },
});

const ReferenceUsersEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Изменения сохранены");
    redirect("/reference/users");
    refresh();
  };

  const [roles, setRoles] = useState([]);

  const getRoles = async () =>
    appDataProvider.getList("reference/roles", { pagination: 10, sort: "ASC" });

  useEffect(() => {
    getRoles().then((response) => {
      setRoles(response.data);
    });
  }, []);

  const classes = useStyles();
  return (
    <Edit
      title={<Title />}
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm toolbar={<ButtonsBottomToolbar />}>
        <TextInput
          source="userName"
          label="Логин"
          resettable
          validate={validateRequired}
          formClassName={classes.inputWidth}
          fullWidth
        />
        <SelectInput
          label="Роль"
          validate={validateRequired}
          source="role.id"
          choices={roles}
          resettable
          formClassName={classes.inputWidth}
          fullWidth
        />
        <TextInput
          source="name"
          label="Имя"
          resettable
          validate={validateName}
          formClassName={classes.inputWidth}
          fullWidth
        />
        <TextInput
          source="surname"
          label="Фамилия"
          resettable
          formClassName={classes.inputWidth}
          fullWidth
        />
        <TextInput
          source="patronymic"
          label="Отчество"
          resettable
          formClassName={classes.inputWidth}
          fullWidth
        />
        <TextInput
          source="phoneNumber"
          label="Телефон"
          validate={[validateRequired, validatePhoneNumberFunction]}
          resettable
          formClassName={classes.inputWidth}
          fullWidth
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
        />
        <TextInput
          source="workPhone"
          label="Рабочий телефон"
          validate={[validateRequired, validatePhoneNumberFunction]}
          resettable
          formClassName={classes.inputWidth}
          fullWidth
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ReferenceUsersEdit;
