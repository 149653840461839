import React from 'react'
import {
    BooleanInput,
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { validateRequired } from '../../../validators/validate'
import ButtonsBottomToolbar from '../../../components/common/ButtonsBottomToolbar'

const Title = ({ record }) => {
    return <span>Автомобиль {record ? `${record.gosNum}` : ''}</span>
}

const useStyles = makeStyles({
    inputWidth: {
        display: 'inline-flex',
        marginRight: '1%',
        width: '32.6665%',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
})

const ReferenceCarsEdit = props => {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()

    const onSuccess = () => {
        notify('Изменения сохранены')
        redirect('/reference/cars')
        refresh()
    }

    const classes = useStyles()

    return (
      <Edit title={<Title />} onSuccess={onSuccess} mutationMode="pessimistic"  {...props}>
          <SimpleForm toolbar={<ButtonsBottomToolbar />}>
              <TextInput label="Гос. номер"
                         source="gosNum"
                         validate={validateRequired}
                         formClassName={classes.inputWidth}
                         fullWidth
              />
              <TextInput label="Марка"
                         source="marka"
                         validate={validateRequired}
                         formClassName={classes.inputWidth}
                         fullWidth
              />
              <ReferenceInput
                label="Габариты"
                source="dimensionId"
                reference="reference/dimensions"
                formClassName={classes.inputWidth}
                fullWidth
              >
                  <SelectInput optionText="sizeCar" />
              </ReferenceInput>
              <ReferenceInput
                  label="Водитель"
                  source="driverId"
                  reference="reference/drivers"
                  formClassName={classes.inputWidth}
                  fullWidth
                  perPage={200}
              >
                  <SelectInput optionText="fio" />
              </ReferenceInput>
              <BooleanInput label="Внештатный"
                            source="outSide"
                            formClassName={classes.inputWidth}
                            fullWidth
              />
              <BooleanInput label="В ремонте"
                            source="isRepair"
                            formClassName={classes.inputWidth}
                            fullWidth
              />
          </SimpleForm>
      </Edit>
    )
}

export default ReferenceCarsEdit