import ReferenceDimensionsIcon from '@material-ui/icons/ZoomOutMap'
import ReferenceDimensionsList from './ReferenceDimensionsList'
import ReferenceDimensionsEdit from './ReferenceDimensionsEdit'
import ReferenceDimensionsCreate from './ReferenceDimensionsCreate'

const index = {
    icon: ReferenceDimensionsIcon,
    list: ReferenceDimensionsList,
    edit: ReferenceDimensionsEdit,
    create: ReferenceDimensionsCreate
}

export default index