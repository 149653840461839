import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
	ImageField,
	FormDataConsumer,
	ImageInput,
	Labeled,
	SimpleForm,
	Edit,
	TextField,
} from 'react-admin'
import { qrApi } from '../../../api/api'
import { useGlobal } from '../../../GlobalState'

const useStyles = makeStyles({
	widthActionsColumn: {
		width: '160px',
		textAlign: 'right',
	},
})

const QrCode = ({ permissions, ...props }) => {
	const [qrSrc, setQrSrc] = useState(null)
	const handleImageChange = e => {
		console.log('e', e)
	}

	const classes = useStyles()
	{
		return (
			<>
				<Edit {...props}>
					<SimpleForm>
						<ImageInput
							source='data'
							label=''
							accept='image/*'
							multiple={false}
							placeholder='Перетащите изображение QrCode сюда!'
						>
							<ImageField name='images' source='src' title='qrcode' />
						</ImageInput>

						{
							<FormDataConsumer>
								{({ formData, dispatch, ...rest }) => {
									if (!formData.contents) {
										return (
											<Labeled label='QrCode'>
												<ImageField
													name='images'
													source='src'
													onChange={rest => handleImageChange(rest)}
												/>
											</Labeled>
										)
									}
								}}
							</FormDataConsumer>
						}
					</SimpleForm>
				</Edit>
			</>
		)
	}
}

export default QrCode
