import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { IconButton, Tooltip, Zoom } from '@material-ui/core'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import FormikInModal from './FormikInModal'

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
        padding: theme.spacing(2, 4, 3),
        outline: 'none',
    },
}))

const PasswordRecoveryModal = (props) => {
    const classes = useStyles()
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <span>
            <Tooltip
                TransitionComponent={Zoom}
                placement="top"
                title="Восстановить пароль"
            >
                <IconButton
                    onClick={handleOpen}
                    color="primary"
                    aria-label="Восстановить пароль"
                >
                    <LockOpenIcon fontSize="small" />
                </IconButton>
            </Tooltip>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">
                            Восстановление пароля
                        </h2>
                        <p id="transition-modal-description">
                            <FormikInModal
                                openModal={() => {
                                    setOpen(false)
                                }}
                                userId={props.userId}
                            />
                        </p>
                    </div>
                </Fade>
            </Modal>
        </span>
    )
}

export default PasswordRecoveryModal
