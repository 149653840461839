import ReferenceCarsIcon from '@material-ui/icons/LocalShipping'
import ReferenceCarsList from './ReferenceCarsList'
import ReferenceCarsEdit from './ReferenceCarsEdit'
import ReferenceCarsCreate from './ReferenceCarsCreate'

const index = {
    icon: ReferenceCarsIcon,
    list: ReferenceCarsList,
    edit: ReferenceCarsEdit,
    create: ReferenceCarsCreate
}

export default index