import React from 'react'
import {
    BooleanField,
    Datagrid,
    List,
    ReferenceField, ReferenceInput,
    TextField, usePermissions,
} from 'react-admin'
import GroupButtonsActionTable from '../../../components/common/GroupButtonsActionTable'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const ReferenceDriversList = (props) => {
    const classes = useStyles()
    const { permissions } = usePermissions()

    return (
        <List
            title="Справочник водителей"
            {...props}
        >
            {permissions !== "Driver" ?(
                <Datagrid>
                    <TextField source="fio" label="ФИО" />
                    <TextField source="phoneNumber" label="Телефон" />
            </Datagrid>):null}
        </List>
    )
}

export default ReferenceDriversList
