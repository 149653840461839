import React from "react";
import { Datagrid, List, TextField } from "react-admin";
import GroupButtonsActionTable from "../../../components/common/GroupButtonsActionTable";
import { makeStyles } from "@material-ui/core/styles";
import ButtonsTopToolbar from "../../../components/common/ButtonsTopToolbar";
import BulkActionButtons from "../../../components/common/BulkActionButtons";

const useStyles = makeStyles({
  widthActionsColumn: {
    width: "160px",
    textAlign: "right",
  },
});

const ReferenceCompanyList = (props) => {
  const classes = useStyles();

  return (
    <List
      title="Справочник компаний"
      actions={<ButtonsTopToolbar />}
      bulkActionButtons={<BulkActionButtons />}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="Наименование" />
        <GroupButtonsActionTable cellClassName={classes.widthActionsColumn} />
      </Datagrid>
    </List>
  );
};

export default ReferenceCompanyList;
