import React from 'react'
import {
	BooleanInput,
	Create,
	FormDataConsumer,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsBottomToolbar from '../../components/common/ButtonsBottomToolbar'
import TextMaskPhone from '../../components/common/TextMaskPhone'
import {
	validateAdditionalPhoneNumberFunction,
	validatePhoneNumberFunction,
	validateRequired,
} from '../../validators/validate'
import { referencesAPI } from '../../api/api'
import AutocompleteSelectWithDefaultValue from '../../components/common/Autocomplete/AutocompleteSelectWithDefaultValue'

const useStyles = makeStyles({
	inputWidth: {
		display: 'inline-flex',
		marginRight: '1%',
		width: '32.6665%',
		'&:nth-child(3n)': {
			marginRight: '0',
		},
	},
})

const ClientsCreate = props => {
	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	const onSuccess = () => {
		notify('Клиент создан')
		redirect('/clients')
		refresh()
	}

	const classes = useStyles()

	return (
		<Create title='Создать клиента' onSuccess={onSuccess} {...props}>
			<SimpleForm toolbar={<ButtonsBottomToolbar />}>
				<TextInput
					source='name'
					label='Имя'
					formClassName={classes.inputWidth}
					fullWidth
					validate={validateRequired}
				/>
				<TextInput
					source='patronymic'
					label='Отчество'
          formClassName={classes.inputWidth}
          fullWidth
        />
        <TextInput
          source="surname"
          label="Фамилия"
          formClassName={classes.inputWidth}
          fullWidth
        />
        <BooleanInput
          source="corporate"
          label="Юридическое лицо"
          formClassName={classes.inputWidth}
          fullWidth
        />
        <TextInput
          source="phone"
          label="Телефон"
          formClassName={classes.inputWidth}
          fullWidth
          validate={[validatePhoneNumberFunction, validateRequired]}
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
        />
        <TextInput
          source="additionalPhone"
          label="Дополнительный телефон"
          formClassName={classes.inputWidth}
          fullWidth
          validate={[validateAdditionalPhoneNumberFunction]}
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
        />
        <FormDataConsumer
          subscription={{ values: true }}
          formClassName={classes.inputWidth}
          fullWidth
        >
          {({ formData, ...rest }) =>
            formData.corporate && (
              <AutocompleteSelectWithDefaultValue
                targetList={{
                  get: referencesAPI.getCompany,
                  getInputChange: referencesAPI.getCompanyForFilter,
                  type: "normal",
                }}
                name="company.id"
                optionLabel="name"
                label="Компания"
                defaultValue={null}
                {...props}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Create>
  );
};

export default ClientsCreate;
