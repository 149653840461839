import SettingsIcon from '@material-ui/icons/Settings'
import SettingsTelephonyEdit from "../telephony/SettingsTelephonyEdit";
import SettingsTelephonyCreate from "../telephony/SettingsTelephonyCreate";
import SettingsTelephonyList from "./SettingsTelephonyList";

const index = {
    icon: SettingsIcon,
    list: SettingsTelephonyList,
    edit: SettingsTelephonyEdit,
    create:SettingsTelephonyCreate
}

export default index