import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { svidaAPI } from '../../../../../api/svidaApi'
import { cityStyles } from './selectCityAndStreetStyles'
import { disableEnter } from '../../../../../Utils/utils'
const SelectCityFrom = props => {
	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const [regionValue, setRegionValue] = useState(null)
	const [regionIdFrom, setRegionIdFrom] = useState(27)
	const loading = open && options.length === 0

	useEffect(() => {
		let regionData = props.value
		if (regionData && regionData.regionId) {
			;(async () => {
				await svidaAPI.getRegion(regionData.regionId).then(response => {
					setRegionValue(response)
				})
			})()
		} else if (props.value && props.value.cityId) {
			;(async () => {
				await svidaAPI.getCity(props.value.cityId).then(response => {
					setRegionValue(response.region)
					props.onSelectRegionIdFrom(response.region.id)
				})
			})()
		}
	}, [props.value, props])

	useEffect(() => {
		if (props.regionIdFrom !== regionIdFrom) {
			setRegionValue(null)
		}
		setRegionIdFrom(props.regionIdFrom)
	}, [props.regionIdFrom, regionIdFrom])

	useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		;(async () => {
			await svidaAPI.getRegions().then(response => {
				if (active) {
					setOptions(
						Object.keys(response).map(key => {
							return response[key]
						}),
					)
				}
			})
		})()

		return () => {
			active = false
		}
	}, [loading])

	useEffect(() => {
		if (!open) {
			setOptions([])
		}
	}, [open])

	const onSelectRegionIdFrom = regionIdFrom => {
		props.onSelectRegionIdFrom(regionIdFrom)
	}
	const classes = cityStyles()

	return (
		<Autocomplete
			open={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			getOptionSelected={(option, value) => value.name}
			getOptionLabel={option =>
				option.name ? option.shortName + '. ' + option.name : `край. Ставропольский`
			}
			value={regionValue ? regionValue : 'Ставропольский'}
			options={options}
			loading={loading}
			loadingText={regionIdFrom ? 'Загрузка...' : 'Выберите регион'}
			noOptionsText='Нет совпадений'
			clearText='Очистить'
			openText='Открыть'
			className={`${classes.inputNewWidthCity} ${classes.inputNewMRCity}`}
			onInputChange={(event, value) => {
				if (!value) {
					onSelectRegionIdFrom(null)
				}

				;(async () => {
					await svidaAPI.getRegionsByName(value).then(response => {
						setOptions(
							Object.keys(response).map(key => {
								return response[key]
							}),
						)
					})
				})()
			}}
			onChange={(event, value) => {
				if (value) {
					onSelectRegionIdFrom(value.id)
					;(async () => {
						await svidaAPI.getCityByRegionName(value.name).then(response => {
							setRegionValue(value)
							props.onSelectRegionIdFrom(value.id)
						})
					})()
				}
				return !value ? false : setRegionValue(value)
			}}
			onKeyDown={disableEnter}
			renderInput={params => (
				<TextField
					{...params}
					label='Откуда: Регион'
					name={props.name}
					variant='filled'
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	)
}

export default SelectCityFrom
