import React, { useState } from "react";
import {
  Edit,
  maxValue,
  minValue,
  NumberInput,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ButtonsBottomToolbar from "../../../components/common/ButtonsBottomToolbar";
import { validateSymbol } from "../../../validators/validate";

const Title = ({ record }) => {
  return <span>Габариты {record ? `${record.name}` : ""}</span>;
};

const useStyles = makeStyles({
  inputWidth: {
    display: "inline-flex",
    marginRight: "1%",
    width: "32.6665%",
    "&:nth-child(3n)": {
      marginRight: "0",
    },
  },
});

const ReferenceDimensionsEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [initialValue, setInitialValue] = useState({
    length: "",
    width: "",
    height: "",
  });

  const onSuccess = () => {
    notify("Изменения сохранены");
    redirect("/reference/dimensions");
    refresh();
  };

  const onChangeForm = ({ target }) => {
    if (target) {
      if (!target.value) {
        target.value = 0;
      }
      setInitialValue({ ...initialValue, [target.name]: target.value });
    }
  };

  const classes = useStyles();

  return (
    <Edit
      title={<Title />}
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm toolbar={<ButtonsBottomToolbar />}>
        <NumberInput
          initialValue={initialValue.length}
          formClassName={classes.inputWidth}
          onChange={onChangeForm}
          source="length"
          label="Длина (м)"
          fullWidth
          validate={[
            validateSymbol,
            maxValue(1290, "Максимальное значение - 1290"),
            minValue(0, "Минимальное значение - 0м"),
          ]}
        />
        <NumberInput
          initialValue={initialValue.width}
          formClassName={classes.inputWidth}
          onChange={onChangeForm}
          source="width"
          label="Ширина (м)"
          fullWidth
          validate={[
            validateSymbol,
            maxValue(1290, "Максимальное значение - 1290"),
            minValue(0, "Минимальное значение - 0м"),
          ]}
        />
        <NumberInput
          initialValue={initialValue.height}
          formClassName={classes.inputWidth}
          onChange={onChangeForm}
          source="height"
          label="Высота (м)"
          fullWidth
          validate={[
            validateSymbol,
            maxValue(1290, "Максимальное значение - 1290"),
            minValue(0, "Минимальное значение - 0м"),
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default ReferenceDimensionsEdit;
