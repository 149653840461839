import React from 'react'
import { Filter, TextInput } from 'react-admin'

const ReferenceCarsFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput resettable label="Гос. номер" source="gosNum" alwaysOn />
        </Filter>
    )
}

export default ReferenceCarsFilter
