import React, { useEffect, useState } from 'react'
import { Pagination, ReferenceInput, SelectInput, useInput } from 'react-admin'
import { referencesAPI } from '../../../../api/api'
import { useFormState } from 'react-final-form'
import { any } from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const CarSelectInputEdit = React.memo(props => {
	const PostPagination = props => <Pagination rowsPerPageOptions={[100]} {...props} />
	const {
		input: { name, OnBlur, ...rest },
	} = useInput(props)
	const [carList, setCarList] = useState(null)
	const getcarsList = async () => {
		await referencesAPI.getCars().then(res => {
			setCarList(res)
		})
	}
	const toChoices = items => {
		if (items && items.data.length > 0) {
			items.data.map(item => ({ id: item.id, car: item }))
		}
	}
	const { values } = useFormState()
	useEffect(() => {
		getcarsList()
	}, [])

	return (
		<ReferenceInput
			reference='reference/cars'
			source='carId'
			sort={{ field: 'volume', order: 'ASC' }}
			onChange={async event => {
				event && (await props.onSelectCar(event.target.value))
			}}
			label='Автомобиль'
			pagination={<PostPagination />}
			perPage={100}
			fullWidth
			resettable
			filter={{
				isRepair: false,
				isBusy: props.Stage > 1 ? false : any,
				dateBusy: props.DateStart,
				dimension: {
					length: props.DimensionLength,
					width: props.DimensionWidth,
					height: props.DimensionHeight,
				},
			}}
		>
			<SelectInput
				optionText={choice => `${choice.gosNum} ${choice.marka}`}
				choices={toChoices(carList)}
			/>
		</ReferenceInput>
	)
})

export default CarSelectInputEdit
