import React from "react"
import { Filter, TextInput } from "react-admin"

const ReferenceUsersFilter = (props) => {
    return (
        <Filter {...props}>
            <TextInput resettable label="Логин" source="email" alwaysOn />
        </Filter>
    )
}

export default ReferenceUsersFilter
