import * as React from 'react'
import { cloneElement } from 'react'
import {
    CreateButton,
    sanitizeListRestProps,
    TopToolbar,
    useListContext,
} from 'react-admin'

const ButtonsTopToolbar = (props) => {
    const { className, exporter, filters, maxResults, ...rest } = props

    const {
        resource,
        displayedFilters,
        filterValues,
        basePath,
        showFilter,
    } = useListContext()
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            <CreateButton label="Создать" basePath={basePath} />
        </TopToolbar>
    )
}

export default ButtonsTopToolbar
