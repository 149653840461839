import { makeStyles } from '@material-ui/core/styles'

export const orderCreateFormStyles = makeStyles({
    formikWidth: {
        width: '1600px'
    },
    inputWidth: {
        display: 'inline-flex',
        marginRight: '1%',
        marginBottom: '1%',
        width: '32.6665%',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
    inputWidthComment: {
        display: 'inline-flex',
        marginRight: '1%',
        marginBottom: '1%',
        width: '100%',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
    inputSelectWrapper: {
        top: '-13px',
        position: 'relative',
    },
    inputWidthCity: {
        display: 'inline-block',
        width: '49.5%',
        marginBottom: '0%',
    },
    inputWidthStreet: {
        display: 'inline-block',
        width: '33.5%',
        marginBottom: '0%',
    },
    inputMLCity: {
        marginLeft: '0.5%',
    },
    inputMRCity: {
        marginRight: '0.5%',
    },
    inputMRStreet: {
        marginRight: '0.5%',
    },
    inputWidthHouse: {
        width: '24.75%',
    },
    inputNewWidth: {
        display: 'inline-flex',
        marginRight: '1%',
        marginBottom: '1%',
        width: '515.48px',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
    inputNewWidthComment: {
        display: 'inline-flex',
        marginRight: '1%',
        marginBottom: '1%',
        width: '1578px',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
    inputNewWidthCity: {
        //display: 'inline-flex',
        width: '200px',
        marginBottom: '0%',
    },
    inputNewWidthStreet: {
        //display: 'inline-flex',
        width: '200px',
        marginBottom: '0%',
    },
    inputNewMLCity: {
        marginLeft: '18px',
    },
    inputNewMRCity: {
        marginRight: '18px',
    },
    inputNewMRStreet: {
        marginRight: '18px',
    },
    inputNewWidthHouse: {
        width: '394px',
    },
    buttons: {
        textAlign: 'right',
        '& > button': {
            marginTop: '5px',
            marginRight: '10px',
            '&:last-child': {
                marginRight: '15px',
            }
        },
    },
    addButton: { display: 'flex', justifyContent: 'flex-start' },
    boxInputs: { marginTop: "10px", marginBottom: "10px" }
})

export const orderCreateModalStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'auto',
    },
    paper: {
        boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
        padding: theme.spacing(2, 4, 3),
        outline: 'none',
    },
}))

export const telephonyFormStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        outline: 'none',
    },
}))

export const newClientModalStyles = makeStyles((theme) => ({
    form: {
        maxWidth: '720px',
    },
    formTitle: {
        marginBottom: theme.spacing(1),
    },
    formPhone: {
        marginBottom: theme.spacing(1),
    },
    formRightColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    formInputWrapper: {
        padding: theme.spacing(1),
    },
    formInputMultiple: {
        height: 'calc(100% - 8px)',
    },
    formInput: {
        marginBottom: theme.spacing(1),
    },
    formAvatar: {
        padding: theme.spacing(1),
    },
    formImg: {
        maxWidth: '100%',
    },
    formButtons: {
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '& > button': {
            maxWidth: '145px',
            width: '100%',
            '&:last-child': {
                marginLeft: 'auto',
            },
        },
    },
}))

export const oldClientModalStyles = makeStyles((theme) => ({
    form: {
        maxWidth: '720px',
    },
    formTitle: {
        marginBottom: theme.spacing(1),
    },
    formPhone: {
        marginBottom: theme.spacing(1),
    },
    formRightColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    formInputWrapper: {
        padding: theme.spacing(1),
    },
    formInputMultiple: {
        height: 'calc(100% - 8px)',
    },
    formInput: {
        marginBottom: theme.spacing(1),
    },
    formAvatar: {
        padding: theme.spacing(1),
    },
    formImg: {
        maxWidth: '100%',
    },
    formButtons: {
        padding: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '& > button': {
            maxWidth: '145px',
            width: '100%',
            '&:last-child': {
                marginLeft: 'auto',
            },
        },
    },
    formClickCreateOrderButtons: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            margin: '6px 0',
        },
    },
}))