import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import { red } from '@material-ui/core/colors'

const StelsCloseButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        backgroundColor: red[500],
        boxShadow:
            '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        '&:hover': {
            backgroundColor: red[700],
            boxShadow:
                '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        '&:active': {
            boxShadow:
                '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
    },
}))(Button)

export default StelsCloseButton