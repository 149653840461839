import React, { Fragment, useState, useEffect, useRef } from 'react'
import {
	Datagrid,
	DateField,
	List,
	ReferenceField,
	TextField,
	useRefresh,
} from 'react-admin'
import { makeStyles, Typography } from '@material-ui/core'
import ButtonsTopToolbar from '../../components/common/ButtonsTopToolbar'
import BulkActionButtons from '../../components/common/BulkActionButtons'
import AddressField from '../../components/common/AddressField'
import OrdersFilter from './OrdersFilter'
import { useRecursiveTimeout } from '../../Utils/utils'

const useStyles = makeStyles({
	widthActionsColumn: {
		width: '160px',
		textAlign: 'right',
	},
	title: {
		marginTop: '20px',
	},
})
const orderRowStyle = (record, index) => ({
	backgroundColor:
		(new Date(record.dateStartOrder).valueOf() - new Date().valueOf()) / 1000 / 60 / 60 < 1 &&
		record.typeOrder === 1
			? 'rgba(255, 0, 0, .05)'
			: (new Date(record.dateStartOrder).valueOf() - new Date().valueOf()) / 1000 / 60 / 60 < 3 &&
			  record.typeOrder === 1
			? 'rgba(255, 255, 50, 1)'
			: record.typeOrder === 2
			? 'orange'
			: record.typeOrder === 3
			? 'gray'
			: 'white',
})
const OrdersList = React.memo(({ permissions, ...props }) => {
	const classes = useStyles()
	const refresh = useRefresh()
	useRecursiveTimeout(() => refresh(), 60000)
	const addZero = date => {
		return date.toString().padStart(2, '0')
	}
	refresh()
	let now = new Date()
	let date = now.getFullYear() + '-' + addZero(now.getMonth() + 1) + '-' + addZero(now.getDate())
	return (
		<>
			<List {...props}
				title='Заказы'
				filters={permissions !== 'Driver' ? <OrdersFilter /> : null}
				actions={permissions !== 'Driver' ? <ButtonsTopToolbar /> : null}
				bulkActionButtons={permissions !== 'Driver' ? <BulkActionButtons /> : false}
				sort={{ field: 'stagesOrder', order: 'ASC' }}
				empty={false}
			>
				<Datagrid rowClick='edit' rowStyle={orderRowStyle} typeOrder={1}>
					{permissions !== 'Driver' ? <TextField source='client.fioAndPhone' label='ФИО' /> : null}
					{permissions !== 'Driver' ? (
						<ReferenceField
							reference='reference/company'
							source='companyId'
							label='Компания'
							link={false}
						>
							<TextField source='name' />
						</ReferenceField>
					) : null}

					{permissions === 'Driver' ? (
						<TextField source='dateOrder' label='Время заказа' showTime />
					) : null}
					{permissions === 'Driver' ? <TextField source='clientName' label='Клиент' /> : null}

					<ReferenceField
						reference='reference/stages'
						source='stageId'
						label='Состояние заказа'
						link={false}
					>
						<TextField source='name' label='Стадия заказа' />
					</ReferenceField>
					{permissions !== 'Driver' ? (
						<DateField source='dateCreated' label='Дата создания заказа' showTime />
					) : null}
					{permissions !== 'Driver' ? (
						<DateField source='dateStartOrder' label='Дата и время заказа' showTime />
					) : null}
					{permissions === 'Driver' ? (
						<ReferenceField
							reference='reference/payments'
							source='paymentId'
							label='Способ оплаты'
							link={false}
						>
							<TextField source='name' label='Способ оплаты' />
						</ReferenceField>
					) : null}
					<AddressField source='cityFrom' label='Откуда' />

					<AddressField source='cityTo' label='Куда' />
					{permissions !== 'Driver' ? <TextField label='Авто' source='car.gosNum' /> : null}
					{permissions !== 'Driver' ? <TextField label='Водитель' source='driver.fio' /> : null}
					{permissions !== 'Driver' ? (
						<TextField label='Габариты авто' source='dimension.sizeCar' />
					) : null}

					<TextField source='countWorkers' label='Кол-во грузчиков' />
					<TextField source='tariff' label='Тариф' />
					<TextField source='price' label='Цена заказа' />

					{/*permissions !== 'Driver' && (
                        <GroupButtonsActionTable addLabel label="" cellClassName={classes.widthActionsColumn} />
                    )*/}
				</Datagrid>
			</List>
		</>
	)
})
export default OrdersList
