import * as React from "react";
import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Create";
import DoneIcon from "@material-ui/icons/Done";
import { useFormik } from "formik";
import { ordersAPI, referencesAPI, userAPI } from "../../api/api";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { useGetIdentity, usePermissions } from "react-admin";
import { appDataProvider } from "../../api/rest/rest";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    whiteSpace: "nowrap",
  },
  editInput: {
    display: "inline-flex",
    width: "calc(100% - 64px)",
  },
}));

const OrderCardShowComponent = ({ children }) => {
  const commonData = children.props.record;
  const [data, setData] = useState(commonData);
  const [disabledButton, setDisabledButton] = useState(false);

  const { loading, permissions } = usePermissions();
  const { identity, loading: identityLoading } = useGetIdentity();

  const classes = useStyles();

  const [stagesList, setStagesList] = useState([]);
  const [carsList, setCarsList] = useState([]);
  const [logist, setLogist] = useState([]);

  useEffect(() => {
    (async () => {
      await referencesAPI.getStages().then((res) => setStagesList(res.data));

      /* Условие выборки авто при наличии/отсутствия габаритов */
      if (!data.dimensionId) {
        await appDataProvider
          .getList(`reference/cars`, {
            pagination: { page: 1, perPage: 10000 },
            sort: { field: "sizecarslength", order: "ASC" },
            filter: {
              isRepair: false,
              isBusy: false,
              dateBusy: data.dateCreated,
            },
          })
          .then((res) => setCarsList(res.data));
      } else {
        await appDataProvider
          .getList(`reference/cars`, {
            pagination: { page: 1, perPage: 10000 },
            sort: { field: "sizecarslength", order: "ASC" },
            filter: {
              dimension: {
                length: !!data.dimension ? data.dimension.length : null,
                width: !!data.dimension ? data.dimension.width : null,
                height: !!data.dimension ? data.dimension.height : null,
              },
              isRepair: false,
              isBusy: false,
              dateBusy: data.dateCreated,
            },
          })
          .then((res) => setCarsList(res.data));
      }

      /* Получения пользоватенля для проверки,
               является ли он ответственным логистом для этого заказа
               для изменения длительности заказа */
      if (data.managerId) {
        await userAPI.getUser(data.managerId).then((res) => {
          setLogist(res);
        });
      }
    })();
  }, [data.dateCreated, data.dimensionId, data.managerId, data.dimension]);

  const [editMode, setEditMode] = useState({
    durationOrder: false,
    comment: false,
    stage: false,
    countWorkers: false,
    car: false,
  });

  const handleEditMode = (name) => {
    setEditMode({ ...editMode, [name]: !editMode[name] });
  };

  const formik = useFormik({
    initialValues: {
      data,
    },
    onSubmit: async (values) => {
      setDisabledButton(true);
      await ordersAPI
        .updateOrder(data.id, values.data)
        .then((res) => setData(res.data))
        .then(() => setDisabledButton(false));
    },
  });

  const handleSubmit = (e) => {
    const field = e.target.dataset.field;
    formik.handleSubmit();
    handleEditMode(field);
  };

  return loading || identityLoading ? (
    <div>Загрузка...</div>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              ФИО
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {data.client.name ? data.client.name + " " : ""}
              {data.client.surname ? data.client.surname + " " : ""}
              {data.client.patronymic ? data.client.patronymic : ""}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Компания
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {data.client.company ? data.client.company.name : "Отсутствует"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Номер телефона
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <div>Основной: {data.client.phone}</div>
              {!!data.client.additionalPhone && (
                <div>Дополнительный: {data.client.additionalPhone}</div>
              )}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Дата и время заказа
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {new Date(data.dateCreated).toLocaleString()}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Какой автомобиль нужен (габариты)
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {!!data.dimension ? data.dimension.sizeCar : "(не указано)"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Автомобиль, который выполнит заказ
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {editMode.car ? (
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl className={classes.editInput}>
                      <Select
                        id="carId"
                        name="data.carId"
                        value={formik.values.data.carId}
                        onChange={formik.handleChange}
                        resettable
                      >
                        {carsList.map((car) => (
                          <MenuItem key={car.id} value={car.id}>
                            {`${car.marka} ${car.gosNum} ${car.dimensions.sizeCar}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {permissions !== "Driver" && (
                      <Button
                        disabled={disabledButton}
                        data-field="car"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                      >
                        <DoneIcon />
                      </Button>
                    )}
                  </form>
                </div>
              ) : (
                <>
                  <div className={classes.editInput}>
                    {data.car
                      ? `${data.car.marka} ${data.car.gosNum}`
                      : "не указан"}
                  </div>
                  {permissions !== "Driver" && (
                    <Button
                      disabled={disabledButton}
                      onClick={() => handleEditMode("car")}
                    >
                      <EditIcon />
                    </Button>
                  )}
                </>
              )}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Способ оплаты
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {!!data.payment ? data.payment.name : "(не указано)"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Откуда
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {!!data.cityFrom.city ? `г. ${data.cityFrom.city.name} ` : "- "}
              {!!data.cityFrom.street
                ? `ул. ${data.cityFrom.street.name} `
                : "- "}
              {!!data.cityFrom.house ? `д. ${data.cityFrom.house} ` : "- "}
              {!!data.cityFrom.apartment
                ? `кв. ${data.cityFrom.apartment} `
                : "- "}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Куда
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Typography variant="body2" color={"textSecondary"} gutterBottom>
                {!!data.cityTo.city ? `г. ${data.cityTo.city.name} ` : "- "}
                {!!data.cityTo.street
                  ? `ул. ${data.cityTo.street.name} `
                  : "- "}
                {!!data.cityTo.house ? `д. ${data.cityTo.house} ` : "- "}
                {!!data.cityTo.apartment
                  ? `кв. ${data.cityTo.apartment} `
                  : "- "}
              </Typography>
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Количество грузчиков
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {editMode.countWorkers ? (
                <>
                  <TextField
                    id="countWorkers"
                    name="data.countWorkers"
                    type="number"
                    value={formik.values.data.countWorkers}
                    onChange={formik.handleChange}
                    className={classes.editInput}
                    resettable
                  />
                  {permissions !== "Driver" && (
                    <Button
                      disabled={disabledButton}
                      data-field="countWorkers"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <DoneIcon />
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <div className={classes.editInput}>{data.countWorkers}</div>
                  {permissions !== "Driver" && (
                    <Button
                      disabled={disabledButton}
                      onClick={() => handleEditMode("countWorkers")}
                    >
                      <EditIcon />
                    </Button>
                  )}
                </>
              )}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Длительность заказа (мин)
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {editMode.durationOrder ? (
                <div>
                  <TextField
                    id="durationOrder"
                    name="data.durationOrder"
                    type="number"
                    value={formik.values.data.durationOrder}
                    onChange={formik.handleChange}
                    className={classes.editInput}
                    resettable
                  />
                  <Button
                    disabled={disabledButton}
                    data-field="durationOrder"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <DoneIcon />
                  </Button>
                </div>
              ) : (
                <>
                  <div className={classes.editInput}>{data.durationOrder}</div>
                  {((permissions === "Logist" &&
                    data.managerId === parseInt(identity.id)) ||
                    permissions === "Driver") && (
                    <Button
                      disabled={disabledButton}
                      onClick={() => handleEditMode("durationOrder")}
                    >
                      <EditIcon />
                    </Button>
                  )}
                </>
              )}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Стадия заказа
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {editMode.stage ? (
                <div>
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl className={classes.editInput}>
                      <Select
                        id="stageId"
                        name="data.stageId"
                        value={formik.values.data.stageId}
                        onChange={formik.handleChange}
                        resettable
                      >
                        {stagesList.map((stage) => (
                          <MenuItem key={stage.id} value={stage.id}>
                            {stage.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      disabled={disabledButton}
                      data-field="stage"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      <DoneIcon />
                    </Button>
                  </form>
                </div>
              ) : (
                <>
                  <div className={classes.editInput}>
                    {data.stage ? data.stage.name : ""}
                  </div>
                  <Button
                    disabled={disabledButton}
                    onClick={() => handleEditMode("stage")}
                  >
                    <EditIcon />
                  </Button>
                </>
              )}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Цена
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {data.price}
            </Typography>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Тарифная сетка
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Link
                href="https://docs.google.com/spreadsheets/d/1-Ly0CsZGCL_MDDdZC_kup7JuBuAhnoow6hxx7vzAM1w/edit#gid=2052981550"
                target="_blank"
              >
                Ссылка на файл
              </Link>
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Ответственный логист
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {logist ? logist.fio : "Не указан"}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Paper className={classes.paper}>
            <Typography
              noWrap
              gutterBottom
              color="textPrimary"
              variant="subtitle1"
            >
              Комментарий
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {editMode.comment ? (
                <>
                  <TextField
                    id="comment"
                    name="data.comment"
                    value={formik.values.data.comment}
                    onChange={formik.handleChange}
                    className={classes.editInput}
                    resettable
                  />
                  <Button
                    disabled={disabledButton}
                    data-field="comment"
                    onClick={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <DoneIcon />
                  </Button>
                </>
              ) : (
                <>
                  <div className={classes.editInput}>{data.comment}</div>
                  <Button
                    disabled={disabledButton}
                    onClick={() => handleEditMode("comment")}
                  >
                    <EditIcon />
                  </Button>
                </>
              )}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

export default OrderCardShowComponent;
