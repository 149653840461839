import { Tooltip, Typography, Zoom } from '@material-ui/core'
import React from 'react'

const formattedTime = (hour, min) => {
	return hour.toString().padStart(2, '0') + ':' + min.toString().padStart(2, '0')
}

export const getContent = classes => {
	// Get an array of data with time
	let spans = []
	let hour = 0
	let vw = 0.06944444444

	while (hour < 24) {
		let min = 0
		while (min < 60) {
			let countHour = hour * 60 + min
			let incrCountHour = countHour + 1
			let time = formattedTime(hour, min)

			if (countHour % 60 === 0) {
				spans.push(
					<>
						<span
							style={{ width: vw * 60 + '%' }}
							className={classes.timelineItem}
							id={incrCountHour}
						>
							{time}
						</span>
					</>,
				)
			}
			min++
		}
		hour++
	}
	return spans
}

export const getContentOverlay = (timeOrdersList, classes) => {
	if (!timeOrdersList) {
		return false
	}
	let widthEl = 0.06944444444
	return timeOrdersList.map(timesObj => {
		if (!timesObj) {
			return false
		}
		let times = timesObj.time
		let widthSpan
		let minFrom = timesObj.time.from % 60
		let hourFrom = (timesObj.time.from - minFrom) / 60
		let minTo = timesObj.time.to % 60
		let hourTo = (timesObj.time.to - minTo) / 60

		let timeFrom = formattedTime(hourFrom, minFrom)
		let timeTo = formattedTime(hourTo, minTo)

		let timeInTooltip = (
			<div className={classes.tooltip}>
				<Typography color='inherit'>{timesObj.phone}</Typography>
				<Typography variant='body2' color='inherit'>
					{timeFrom + ' - ' + timeTo}
					{','}
					{timesObj.address.from + ' - ' + timesObj.address.to}
					{','}
					{timesObj.stage}
				</Typography>
			</div>
		)

		let infoInSpan = ' №' + timesObj.id
		let orderHref = '#/orders/' + timesObj.id
		widthSpan = times.to - times.from

		if (widthSpan < 35) infoInSpan = 'i'
		if (widthSpan < 5) widthSpan = 3
		return (
			<Tooltip TransitionComponent={Zoom} placement='top' title={timeInTooltip} arrow>
				<span
					className={
						timesObj.stageOrder >= 5 && timesObj.stageOrder < 9
							? classes.timelineItemWork
							: timesObj.stageOrder >= 9
							? classes.timelineItemSuccess
							: classes.timelineItemWait
					}
					style={{
						width: widthSpan * widthEl + '%',
						position: 'absolute',
						left: times.from * widthEl + '%',
						height: '100%',
					}}
				>
					<a href={orderHref}>
						<b>{infoInSpan}</b>
					</a>
				</span>
			</Tooltip>
		)
	})
}
