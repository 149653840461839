import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useInput } from 'react-admin'
import { disableEnter } from '../../../Utils/utils'

const AutocompleteSelectWithDefaultValue = props => {
	const {
		input: { name, onChange },
		meta: { touched, error },
	} = useInput(props)

	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const loading = open && options.length === 0
	const [value, setValue] = useState(options[0] || null)

	useEffect(() => {
		if (props.defaultValue) {
			setValue(props.defaultValue)
			onChange(props.defaultValue.id)
		}
	}, [props.defaultValue, onChange])

	useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		switch (props.targetList.type) {
			case 'normal': {
				;(async () => {
					await props.targetList.get().then(res => {
						if (active) {
							setOptions(res.data)
						}
					})
				})()
				break
			}
			case 'cities': {
				;(async () => {
					await props.targetList.get().then(res => {
						if (active) {
							setOptions(
								Object.keys(res).map(key => {
									return res[key]
								}),
							)
						}
					})
				})()
				break
			}
			default:
				break
		}

		return () => {
			active = false
		}
	}, [loading, props.targetList])

	useEffect(() => {
		if (!open) {
			setOptions([])
		}
	}, [open])

	return (
		<Autocomplete
			filterOptions={props.filterOptions}
			fullWidth
			name={props.source}
			open={open}
			loadingText='Загрузка...'
			noOptionsText='Нет совпадений'
			clearText='Очистить'
			openText='Открыть'
			value={value}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			onBlur={() => {
				if (props.name === 'clientId') {
					let clientInput = document.getElementById('clientInput')
					let datavalue = clientInput.getAttribute('data-value')
					if (datavalue && !value) {
						let newInput = { 0: { fio: datavalue, phone: '' } }
						setOptions(
							Object.keys(newInput).map(key => {
								return newInput[key]
							}),
						)
						setValue(newInput[0])
					}
				}
			}}
			getOptionSelected={(option, value) => option.name === value.name}
			getOptionLabel={option => {
				let result = ''

				if (!props.optionLabel) {
					return option.name
				} else {
					let optionLabels = props.optionLabel.split(' ')

					optionLabels.forEach(function (item, i) {
						result += option[item] + ' '
					})

					return result.trim()
				}
			}}
			onInputChange={(event, value) => {
				switch (props.targetList.type) {
					case 'normal': {
						;(async () => {
							await props.targetList.getInputChange(value).then(res => {
								setOptions(res.data)
							})
						})()
						break
					}
					case 'cities': {
						;(async () => {
							await props.targetList.getInputChange(value).then(res => {
								setOptions(
									Object.keys(res).map(key => {
										return res[key]
									}),
								)
							})
						})()
						break
					}
					default:
						break
				}

				if (props.name === 'clientId') {
					let clientInput = document.getElementById('clientInput')
					if (clientInput && value) clientInput.setAttribute('data-value', value)
				}
				if (value.length === 0) {
					setOptions([])
				}
			}}
			onChange={(_, value) => {
				if (props.name === 'clientId' && !value) {
					let clientInput = document.getElementById('clientInput')
					clientInput.setAttribute('data-value', '')
				}
				setValue(value)
				return !value ? false : onChange(value.id)
			}}
			onFocus={() => {
				if (props.name === 'clientId' && value) {
					;(async () => {
						await props.targetList.getInputChange(value.fio + value.phone).then(res => {
							setOptions(res.data)
						})
					})()
				}
			}}
			onKeyDown={disableEnter}
			options={options}
			loading={loading}
			renderInput={(params, input) => (
				<TextField
					{...params}
					{...input}
					name={name}
					label={props.label}
					variant='filled'
					error={!!(touched && error)}
					helperText={touched && error}
					size='small'
					style={{ position: 'relative', top: '-8px' }}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{loading ? <CircularProgress color='inherit' size={20} /> : null}
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
		/>
	)
}

export default AutocompleteSelectWithDefaultValue
