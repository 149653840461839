import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useInput } from "react-admin";
import { svidaAPI } from "../../../../api/svidaApi";

const AsyncSelectStreetToEdit = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [cityIdTo, setCityIdTo] = useState(null);
  const [value, setValue] = useState(null);
  const loading = open && options.length === 0 && cityIdTo;

  useEffect(() => {
    if (props.cityIdTo) {
      setCityIdTo(props.cityIdTo);
      setValue(null);
    }
  }, [props.cityIdTo]);

  useEffect(() => {
    if (
      props.record.cityTo &&
      props.record.cityTo.city &&
      props.record.cityTo.street &&
      props.record.cityTo.city.id &&
      props.record.cityTo.street.id &&
      !props.cityIdTo
    ) {
      let streetId = props.record.cityTo.street.id;
      let cityId = props.record.cityTo.city.id;

      setCityIdTo(cityId);
      (async () => {
        await svidaAPI.getStreetsByCityId(cityId).then((response) => {
          return setOptions(
            Object.keys(response).map((key) => {
              return response[key];
            })
          );
        });

        await svidaAPI
          .getStreet(streetId)
          .then((response) => setValue(response));
      })();
    }
  }, [props.record.cityTo, props.cityIdTo]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (cityIdTo && active) {
      (async () => {
        await svidaAPI.getStreetsByCityId(cityIdTo).then((response) => {
          setOptions(
            Object.keys(response).map((key) => {
              return response[key];
            })
          );
        });
      })();
    }

    return () => {
      active = false;
    };
  }, [loading, cityIdTo]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => "ул. " + option.name}
      loadingText={cityIdTo ? "Загрузка..." : "Выберите город"}
      noOptionsText="Нет совпадений"
      clearText="Очистить"
      openText="Открыть"
      options={options}
      loading={loading}
      onInputChange={(event, value) => {
        (async () => {
          await svidaAPI.getStreetsByName(value, cityIdTo).then((response) => {
            setOptions(
              Object.keys(response).map((key) => {
                return response[key];
              })
            );
          });
        })();
      }}
      onChange={(event, value) => {
        setValue(value);
        return !value ? onChange(null) : onChange(value.id);
      }}
      onFocus={(event, value) => {
        if (
          props.record.cityTo &&
          props.record.cityTo.city &&
          cityIdTo == null
        ) {
          let cityId = props.record.cityTo.city.id;
          setCityIdTo(cityId);
        }
        if (cityIdTo) {
          (async () => {
            await svidaAPI.getStreetsByCityId(cityIdTo).then((response) => {
              return setOptions(
                Object.keys(response).map((key) => {
                  return response[key];
                })
              );
            });
          })();
        }
      }}
      value={value}
      renderInput={(params, input) => {
        return (
          <TextField
            {...params}
            {...input}
            name={name}
            label="Куда: Улица"
            size="small"
            variant="filled"
            error={!!(touched && error)}
            helperText={touched && error}
            required={isRequired}
            {...rest}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default AsyncSelectStreetToEdit;
