import React from 'react'
import { useRecordContext } from 'react-admin'

const WhereFromField = (props) => {
    const record = useRecordContext(props)
    let city = ''
    if (record.cityFrom && record.cityFrom.city && record.cityFrom.city.name) {
         city += `г. ${record.cityFrom.city.name} 
`
        if (record.cityFrom.street && record.cityFrom.street.name) {
            city += `ул. ${record.cityFrom.street.name} 
`
            if (record.cityFrom.house) {
                city += `д. ${record.cityFrom.house} `
                if (record.cityFrom.apartment) {
                    city += `д. ${record.cityFrom.apartment}`
                }
            }
        }

    }
    return <span><pre>{city}</pre></span>
}

export default WhereFromField
