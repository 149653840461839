import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { Grid, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

const AccordionForOrder = (props) => {
    const [orders, setOrders] = useState(null)

    useEffect(() => {
        props.orders && setOrders(props.orders)
    }, [props.orders])

    const classes = useStyles()

    if (!orders) {
        return (
            <Typography variant="body1" gutterBottom>
                Загрузка данных...
            </Typography>
        )
    }

    return (
        <div className={classes.root}>
            {orders.map((order, idx) => {
                let path = ''
                if (order.cityFrom.city && order.cityTo.city && order.cityFrom.street && order.cityTo.street) {
                    path =
                        order.cityFrom.city.shortName +
                        '.' +
                        order.cityFrom.city.name +
                        ' ул.' +
                        order.cityFrom.street.name +
                        ' - ' +
                        order.cityTo.city.shortName +
                        '.' +
                        order.cityTo.city.name +
                        ' ул.' +
                        order.cityTo.street.name
                }
                let client = order.client.fio.trim() ? order.client.fio : 'Не указано фио'
                let responsibleFio = order.responsible && order.responsible.fio ? order.responsible.fio : 'Не указано'
                let responsiblePhoneNumber =
                    order.responsible && order.responsible.phoneNumber ? order.responsible.phoneNumber : 'Не указано'
                let paymentName = order.payment && order.payment.name ? order.payment.name : 'Не указано'

                return (
                    <>
                        {!idx && (
                            <Typography noWrap gutterBottom color="textPrimary" variant="subtitle1">
                                Выполненные заказы
                            </Typography>
                        )}
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>Заказ №{order.id + ' ' + path}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography noWrap gutterBottom color="textPrimary" variant="subtitle1">
                                            Клиент
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            Фио: {client}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            Телефон: {order.client.phone}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography noWrap gutterBottom color="textPrimary" variant="subtitle1">
                                            Ответственный
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            Фио: {responsibleFio}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            Телефон: {responsiblePhoneNumber}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography noWrap gutterBottom color="textPrimary" variant="subtitle1">
                                            Количество грузчиков
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {order.countWorkers ?? 'Не указано'}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography noWrap gutterBottom color="textPrimary" variant="subtitle1">
                                            Дата создания
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {order.dateCreated && new Date(order.dateCreated).toLocaleString()}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography noWrap gutterBottom color="textPrimary" variant="subtitle1">
                                            Длительность заказа(мин)
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {order.durationOrder ?? 'Не указано'}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography noWrap gutterBottom color="textPrimary" variant="subtitle1">
                                            Оплата
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {paymentName}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={3}>
                                        <Typography noWrap gutterBottom color="textPrimary" variant="subtitle1">
                                            Цена
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            {order.price ?? 'Не указано'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    </>
                )
            })}
        </div>
    )
}
export default AccordionForOrder
