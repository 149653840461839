import React from 'react'
import { Backdrop, Modal } from '@material-ui/core'
import NewClientModal from './modals/NewClientModal'
import OldClientModal from './modals/OldClientModal'
import { telephonyFormStyles } from './TelephonyFormStyles'

const TelephonyForm = ({ infoForModal, closeForm, ...props }) => {
	const classes = telephonyFormStyles()
	return (
		<div>
			<Modal
				disablePortal
				disableEnforceFocus
				disableAutoFocus
				open={true}
				aria-labelledby='telephony-title'
				aria-describedby='telephony-description'
				className={classes.modal}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<div className={classes.paper}>
					{infoForModal.identifyClient && infoForModal.order === null ? (
						<NewClientModal
							callId={infoForModal.callId}
							client={infoForModal.clientData}
							closeForm={closeForm}
							props
						/>
					) : (
						<OldClientModal
							client={infoForModal.clientData}
							lastCall={infoForModal.lastCall}
							closeForm={closeForm}
							order={infoForModal.order}
							callId={infoForModal.callId}
						/>
					)}
				</div>
			</Modal>
		</div>
	)
}

export default TelephonyForm
