import axios from 'axios'

export const instanceSvida = axios.create({
    baseURL: process.env.REACT_APP_SVIDA_API_URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
    },
})

export const svidaAPI = {
    getRegions() {
        return instanceSvida.get(`/regions`)
            .then(response => {
                return response.data
            })
    },
    getRegion(id) {
        return instanceSvida.get(`/regions/${id}`)
            .then(response => {
                return response.data
            })
    },
    getRegionsByName(name) {
        return instanceSvida.get(`/regions/?name=${name}`)
            .then(response => {
                return response.data
            })
    },
    getCityByRegionName(name) {
        return instanceSvida.get(`/cities?region.name=${name}`)
            .then(response => {
                return response.data
            })
    },
    getCities() {
        return instanceSvida.get(`/cities`)
            .then(response => {
                return response.data
            })
    },
    getCity(id) {
        return instanceSvida.get(`/cities/${id}`)
            .then(response => {
                return response.data
            })
    },
    getCityByName(name,regionName) {
        return instanceSvida.get(`/cities?name=${name}&region.name=${regionName}`)
            .then(response => {
                return response.data
            })
    },
    getCityAll(name) {
        return instanceSvida.get(`/cities?name=${name}`)
            .then(response => {
                return response.data
            })
    },
    getStreet(id) {
        return instanceSvida.get(`/streets/${id}`)
            .then(response => {
                return response.data
            })
    },
    getStreetsByCityId(cityId) {
        return instanceSvida.get(`/streets?city.id=${cityId}`)
            .then(response => {
                return response.data
            })
    },
    getStreetsByName(streetName, cityId) {
        return instanceSvida.get(`/streets?name=${streetName}&city.id=${cityId}`)
            .then(response => {
                return response.data
            })
    },
}