import React from 'react'
import { Datagrid, List, TextField, usePermissions } from 'react-admin'
import GroupButtonsActionTable from '../../../components/common/GroupButtonsActionTable'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsTopToolbar from '../../../components/common/ButtonsTopToolbar'
import BulkActionButtons from '../../../components/common/BulkActionButtons'

const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const ReferencePaymentMethodsList = props => {
    const { loading, permissions } = usePermissions()

    const classes = useStyles()

    return loading ? (
      <div>Подождите пожалуйста</div>
    ) : (
      <List title="Справочник способов оплаты"
            actions={permissions === 'Administrators' ? <ButtonsTopToolbar /> : null}
            bulkActionButtons={permissions === 'Administrators' ? <BulkActionButtons /> : false}
            {...props}
      >
          {permissions !== "Driver" ?(<Datagrid defaultChecked={null}>
              <TextField source="name" label="Наименование" />
              {
                  permissions === "Administrators" &&
                  <GroupButtonsActionTable cellClassName={classes.widthActionsColumn} />
              }
          </Datagrid>):null}
      </List>
    )
}

export default ReferencePaymentMethodsList