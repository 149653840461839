import React from 'react'
import {Datagrid, DateField, FunctionField, List, Pagination, ReferenceField, TextField} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsTopToolbar from '../../components/common/ButtonsTopToolbar'
import BulkActionButtons from '../../components/common/BulkActionButtons'
import GroupButtonsActionTable from '../../components/common/GroupButtonsActionTable'
import CallsFilter from "./CallsFilter";
import {PhoneField} from "../calls/PhoneField";

const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const RingsList = (props) => {
    const classes = useStyles()
    return (
        <List
            title="Звонки"
            actions={null}
            bulkActionButtons={null}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<CallsFilter/>}
           filterDefaultValues={{ dateCreated: new Date(), statusid: 5, isWorkedOutCall: true}}
            {...props}
        >
            <Datagrid>
                <TextField source="id" label="№" />
                <FunctionField
                    label="ФИО"
                    render={({client}) => `
                    ${client.name !== null ? client.name : ''}
                     ${client.surname !== null ? client.surname : ''}
                      ${client.patronymic !== null ? client.patronymic : ''}`}
                />;
                <TextField source="client.phone" label="Телефон" />
                <ReferenceField
                    reference="clients"
                    source="clientId"
                    label="Компания"
                    sortBy="company"
                    link={false}
                >
                    <ReferenceField reference="reference/company" source="companyId" link={false}>
                        <TextField source="name" />
                    </ReferenceField>
                </ReferenceField>
                <DateField source="updateAt" showTime label="Дата" />
            </Datagrid>
        </List>
    )
}

export default RingsList