import React, { useState } from 'react'
import {
    BooleanInput,
    Button,
    FormDataConsumer,
    FormWithRedirect,
    SaveButton,
    TextInput,
    useCreate,
    useNotify,
} from 'react-admin'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import {
    validateAdditionalPhoneNumberFunction,
    validatePhoneNumberFunction,
    validateRequired,
} from '../../../validators/validate'
import TextMaskPhone from '../../../components/common/TextMaskPhone'
import IconContentAdd from '@material-ui/icons/Add'
import IconCancel from '@material-ui/icons/Cancel'
import { appDataProvider as dataProvider } from '../../../api/rest/rest'
import { CreateClientModalButtonStyles } from './CreateClientModalButtonStyles'

const CreateClientModalButton = ({ onChange }) => {
    const [showDialog, setShowDialog] = useState(false)
    const [create, { loading }] = useCreate('clients')
    const notify = useNotify()

    const handleOpenClick = () => {
        setShowDialog(true)
    }

    const handleCloseClick = () => {
        setShowDialog(false)
    }

    const handleSubmit = async (values) => {
        create(
            { payload: { data: values } },
            {
                onSuccess: ({ data }) => {
                    setShowDialog(false)

                    dataProvider
                        .getList('clients', {
                            pagination: { page: 1, perPage: 1 },
                            sort: { field: 'id', order: 'DESC' },
                        })
                        .then((response) => {
                            onChange(response.data[0])
                        })
                },
                onFailure: ({ error }) => {
                    notify(error.message, 'error')
                },
            }
        )
    }

    const classes = CreateClientModalButtonStyles()

    return (
        <>
            <Button onClick={handleOpenClick} className={classes.button} label="">
                <IconContentAdd />
            </Button>
            <Dialog fullWidth open={showDialog} onClose={handleCloseClick} aria-label="Создать клиента">
                <DialogTitle>Создать клиента</DialogTitle>

                <FormWithRedirect
                    resource="clients"
                    save={handleSubmit}
                    render={({ handleSubmitWithRedirect, pristine, saving }) => (
                        <>
                            <DialogContent>
                                <TextInput
                                    source="name"
                                    label="Имя"
                                    resettable
                                    formClassName={classes.inputWidth}
                                    fullWidth
                                    validate={validateRequired}
                                />
                                <TextInput
                                    source="surname"
                                    label="Фамилия"
                                    resettable
                                    formClassName={classes.inputWidth}
                                    fullWidth
                                />
                                <TextInput
                                    source="patronymic"
                                    label="Отчество"
                                    resettable
                                    formClassName={classes.inputWidth}
                                    fullWidth
                                />
                                <BooleanInput
                                    source="corporate"
                                    label="Юридическое лицо"
                                    formClassName={classes.inputWidth}
                                    fullWidth
                                />
                                <FormDataConsumer
                                    subscription={{ values: true }}
                                    formClassName={classes.inputWidth}
                                    fullWidth
                                >
                                    {({ formData, ...rest }) =>
                                        formData.corporate && (
                                            <TextInput
                                                source="companyName"
                                                label="Компания"
                                                resettable
                                                formClassName={classes.inputWidth}
                                                fullWidth
                                                {...rest}
                                            />
                                        )
                                    }
                                </FormDataConsumer>
                                <TextInput
                                    source="phone"
                                    label="Телефон"
                                    resettable
                                    formClassName={classes.inputWidth}
                                    fullWidth
                                    validate={[validatePhoneNumberFunction, validateRequired]}
                                    InputProps={{
                                        inputComponent: TextMaskPhone,
                                    }}
                                />
                                <TextInput
                                    source="additionalPhone"
                                    label="Дополнительный телефон"
                                    resettable
                                    formClassName={classes.inputWidth}
                                    fullWidth
                                    validate={[validateAdditionalPhoneNumberFunction]}
                                    InputProps={{
                                        inputComponent: TextMaskPhone,
                                    }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    label="Назад"
                                    onClick={handleCloseClick}
                                    disabled={loading}
                                    variant={'outlined'}
                                    color={'default'}
                                >
                                    <IconCancel style={{ color: 'rgba(0, 0, 0, 0.23)' }} />
                                </Button>
                                <SaveButton
                                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                                    pristine={pristine}
                                    saving={saving}
                                    disabled={loading}
                                    size={'small'}
                                />
                            </DialogActions>
                        </>
                    )}
                />
            </Dialog>
        </>
    )
}

export default CreateClientModalButton
