import { Filter } from 'react-admin'
import React from 'react'
import { userAPI } from '../../api/api'
import AutocompleteSelectFilter from '../../components/common/Autocomplete/AutocompleteSelectFilter'
import { makeStyles } from '@material-ui/core/styles'
import { filterOptions } from '../../Utils/utils'

const ClientsFilter = (props) => {
    const useStyles = makeStyles((theme) => ({
        inputStyle: {
            width: '220px',
            margin: '16px 0 2px',
        },
        inputAutocompleteStyle: {
            width: '456px',
            margin: '16px 0 2px',
        },
    }))

    const classes = useStyles()

    return (
        <Filter {...props}>
            <AutocompleteSelectFilter
                targetList={{
                    get: userAPI.getClients,
                    getInputChange: userAPI.getClientsForFilter,
                    type: 'normal',
                }}
                source="IdString"
                optionLabel="fio phone"
                label="Клиент"
                filterOptions={filterOptions}
                className={classes.inputAutocompleteStyle}
                alwaysOn
            />
        </Filter>
    )
}

export default ClientsFilter
