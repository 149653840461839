import React from 'react'
import { Datagrid, List, ReferenceField, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsTopToolbar from '../../components/common/ButtonsTopToolbar'
import BulkActionButtons from '../../components/common/BulkActionButtons'
import GroupButtonsActionTable from '../../components/common/GroupButtonsActionTable'
import ClientsFilter from "./ClientsFilter";

const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const ClientsList = (props) => {
    const classes = useStyles()

    return (
        <List
            title="Клиенты"
            actions={<ButtonsTopToolbar />}
            bulkActionButtons={<BulkActionButtons />}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<ClientsFilter/>}
            {...props}
        >
            <Datagrid>
                <TextField source="fio" label="ФИО" />
                <ReferenceField reference="reference/company" source="companyId" label="Компания" link={false}>
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="phone" label="Телефон" />
                <TextField source="additionalPhone" label="Дополнительный телефон" />
                <GroupButtonsActionTable cellClassName={classes.widthActionsColumn} />
            </Datagrid>
        </List>
    )
}

export default ClientsList