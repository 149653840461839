import { makeStyles } from '@material-ui/core/styles'

export const timelineStyles = makeStyles((theme) => ({
    timelineWrapper: {
        display: 'flex',
        position: 'relative',
    },
    timelineTitle: {
        width: '130px',
        marginRight: '10px',
        fontSize: '.9em',
        position: 'relative',
        '& > p': {
            margin: 0,
        },
    },
    isBusyBus: {
        top: '5px',
        left: '110px',
        width: '20px',
        height: '20px',
        position: 'absolute',
        textAlign: 'center',
        lineHeight: '20px',
        borderRadius: '50%',
        backgroundColor: 'red',
        color:'white',
        fontSize: '0.625rem',
    },

    timelineContent: {
        borderLeft: '0.01em solid lightgrey',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        flexGrow: 1,
    },

    now: {
        position: 'absolute',
        left: 0,
        bottom: '10px',
        width: 0,
        height: 0,
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid red',
    },
    timelineItem: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30px',
        background: '#56b3fa',
        color: '#fff',
        boxShadow: 'inset 1px 0px 2px rgb(0, 0, 0, .2)',
        userSelect: 'none',
    },
    timelineItemFill: {
        fontSize: '0.7rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '46px',
        boxShadow: 'inset 1px 0px 2px rgb(0, 0, 0, .2)',
        color: '#fff',
        textAlign: 'center',
        userSelect: 'none',
    },
    timelineItemNearest: {
        fontSize: '0.7rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '46px',
        boxShadow: 'inset 1px 0px 2px rgb(0, 0, 0, .2)',
        color: '#fff',
        textAlign: 'center',
        userSelect: 'none',
        background: 'rgba(255, 100, 0, 1)',
    },
    timelineItemWork: {
        fontSize: '0.7rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '46px',
        boxShadow: 'inset 1px 0px 2px rgb(0, 0, 0, .2)',
        color: '#0000ff',
        textAlign: 'center',
        userSelect: 'none',
        background: 'rgba(255, 255, 50, 1)',
    },
    timelineItemWait: {
        fontSize: '0.7rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '46px',
        boxShadow: 'inset 1px 0px 2px rgb(0, 0, 0, .2)',
        color: '#fff',
        textAlign: 'center',
        userSelect: 'none',
        background: 'rgba(255, 69, 0, 1)',
    },
    timelineItemSuccess: {
        fontSize: '0.7rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '46px',
        boxShadow: 'inset 1px 0px 2px rgb(0, 0, 0, .2)',
        color: '#fff',
        textAlign: 'center',
        userSelect: 'none',
        background: 'rgba(0, 128, 0, 1)',
    },
    tooltip: {
        textAlign: 'center',
    },
}))
