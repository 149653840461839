import React from 'react'
import { Layout, Sidebar } from 'react-admin'
import Menu from './Menu'
// import {darkTheme, lightTheme} from "./themes"

const CustomSidebar = (props) => <Sidebar {...props} size={200} />

const LayoutApp = (props) => {
    // const theme = useSelector((state) => {
    //     state.theme === 'dark' ? darkTheme : lightTheme
    // })

    return <Layout {...props} sidebar={CustomSidebar} menu={Menu} />
}
export default LayoutApp
