import {BooleanField, DateInput, Filter, SelectInput} from 'react-admin'
import React, { useEffect, useState } from 'react'
import { referencesAPI, userAPI } from '../../api/api'
import { svidaAPI } from '../../api/svidaApi'
import AutocompleteSelectFilter from '../../components/common/Autocomplete/AutocompleteSelectFilter'
import { makeStyles } from '@material-ui/core/styles'

const OrdersFilter = (props) => {
    const [stages, setStages] = useState([])
    const [logisticians, setLogisticians] = useState([])
    const [cars, setCars] = useState([])
    const [dimensions, setDimensions] = useState([])

    useEffect(() => {
        ;(async () => {
            await referencesAPI.getStages().then((res) => setStages(res.data))

            await userAPI.getLogisticians().then((res) => setLogisticians(res))

            await referencesAPI.getCars().then((res) => setCars(res.data))

            await referencesAPI.getDimensions().then((res) => setDimensions(res.data))

        })()
    }, [])

    const CarField = ({ record }) => (
        <span>
            {record.marka} {record.gosNum}
        </span>
    )

    const useStyles = makeStyles((theme) => ({
        inputStyle: {
            width: '220px',
            margin: '16px 0 2px',
        },
        inputAutocompleteStyle: {
            width: '456px',
            margin: '16px 0 2px',
        },
    }))

    const classes = useStyles()

    return (
        <Filter {...props}>
            <DateInput
                source="dateBegin"
                label="Дата заказа (от)"
                initialValue={new Date()}
                className={classes.inputStyle}
                alwaysOn
            />

            <DateInput
                source="dateEnd"
                label="Дата заказа (до)"
                initialValue={new Date()}
                className={classes.inputStyle}
                alwaysOn
            />

            <SelectInput
                source="stage.order"
                label="Состояние заказа"
                choices={stages}
                optionValue="order"
                className={classes.inputStyle}
                alwaysOn
                resettable
            />

            <SelectInput
                source="managerId"
                label="Ответственный"
                choices={logisticians}
                optionText="fio"
                className={classes.inputStyle}
                alwaysOn
                resettable
            />

            <SelectInput
                source="carId"
                label="Автомобиль"
                choices={cars}
                optionText={<CarField />}
                className={classes.inputStyle}
                alwaysOn
                resettable
            />

            <SelectInput
                source="dimensionId"
                label="Габариты"
                choices={dimensions}
                optionText="sizeCar"
                className={classes.inputStyle}
                alwaysOn
                resettable
            />

            <AutocompleteSelectFilter
                targetList={{
                    get: userAPI.getClients,
                    getInputChange: userAPI.getClientsForFilter,
                    type: 'normal',
                }}
                source="clientId"
                optionLabel="fio phone"
                label="Клиент"
                className={classes.inputAutocompleteStyle}
                alwaysOn
            />

            <AutocompleteSelectFilter
                targetList={{
                    get: svidaAPI.getCities,
                    getInputChange: svidaAPI.getCityByName,
                    type: 'cities',
                }}
                source="placeId"
                label="Город"
                className={classes.inputAutocompleteStyle}
                alwaysOn
            />
        </Filter>
    )
}

export default OrdersFilter
