import React, {useState} from 'react'
import {Notification, useLogin, useNotify} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Avatar from '@material-ui/core/Avatar'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Copyright from './Copyright/Copyright'
import { useFormik } from 'formik'
import {
    validatePhoneNumberAuth, validatePhoneNumberFunction, validateRequired
} from '../../../src/validators/validate'
import TextMaskPhone from "../common/TextMaskPhone";
import {Link} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

const SignUp = () => {
    const classes = useStyles()

    const login = useLogin()
    const notify = useNotify()
    const [authPhoneNumber,setAuthPhoneNumber] = useState(false)
    const handleAuthPhone = (event) => {
        event.preventDefault();
        setAuthPhoneNumber(!authPhoneNumber)
    }
    const formik = useFormik({
        initialValues: {
            username: '',
            phonenumber:'',
            password: '',
        },
        onSubmit: (values) => {
            login({ username: values.username, phonenumber: values.phonenumber, password: values.password }).catch((e) => {
                notify('Неверный логин или пароль', 'error')
            })
        },
    })

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Авторизация
                </Typography>

                <form onSubmit={formik.handleSubmit} className={classes.form}>
                    <Grid container spacing={2}>
                        {!authPhoneNumber && <Grid item xs={12}>
                            <TextField
                                name="username"
                                label="Логин"
                                type="email"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                id="username"
                                fullWidth
                                variant="outlined"
                                required
                            />
                        </Grid>}
                        {authPhoneNumber && <Grid item xs={12}>
                            <TextField
                                name="phonenumber"
                                label="Телефон"
                                type="phone"
                                value={formik.values.phonenumber}
                                onChange={formik.handleChange}
                                id="phonenumber"
                                fullWidth
                                variant="outlined"
                                required
                                validate={[validatePhoneNumberFunction, validateRequired]}
                                InputProps={{
                                    inputComponent: TextMaskPhone,
                                }}
                            />
                        </Grid>}
                        <Grid item xs={12}>
                            <TextField
                                name="password"
                                label="Пароль"
                                id="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                fullWidth
                                variant="outlined"
                                required
                                type="password"
                            />
                        </Grid>
                    </Grid>
                    <Typography className={classes.typography} variant="body2" color="textSecondary" gutterBottom>
                        <Link
                            href=""
                            onClick = {handleAuthPhone}
                            target="_blank"
                        >
                            Другой способ авторизации
                        </Link>
                    </Typography>
                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Войти
                    </Button>

                    {/*<Grid container justify="flex-end">*/}
                    {/*    <Grid item>*/}
                    {/*        <Link href="#" variant="body2">*/}
                    {/*            Забыли пароль?*/}
                    {/*        </Link>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </form>
            </div>

            <Box mt={5}>
                <Copyright />
            </Box>
            <Notification />
        </Container>
    )
}

export default SignUp
