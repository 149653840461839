import axios from 'axios'
import { stringify } from 'query-string'
import { dateNow } from '../Utils/utils'

export const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	timeout: 30000,
	mode: 'cors',
	credentials: 'same-origin',
	headers: {
		Accept: 'application/json',
		'Content-Type': 'application/json; charset=utf-8',
		'Access-Control-Allow-Origin': '*',
	},
	transformRequest: [
		data => {
			return JSON.stringify(data)
		},
	],
})

instance.interceptors.request.use(config => {
	if (localStorage.getItem('token')) {
		config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token')
	}

	return config
})

export const userAPI = {
	changePassword(userId, password) {
		return instance.put(`reference/users/${userId}/changepassword`, password)
	},
	dismissUser(userId, date) {
		return instance.put(`reference/users/${userId}/dismiss`, date)
	},
	getUser(id) {
		return instance.get(`reference/users/${id}`).then(response => response.data)
	},
	getLogisticians() {
		return instance
			.get(`reference/users`)
			.then(response =>  {
				return response.data.data.filter(manager => manager.role.name === 'Logist')
			})
	},
	getLogisticiansRange(range) {
		return instance
			.get(`reference/users?${stringify({ filter: JSON.stringify({ roleuser: 'Logist'}),range: JSON.stringify(range) })}`)
			.then(response => {
				return response.data.data })

	},
	getClients() {
		return instance.get(`clients`).then(response => response.data)
	},
	getClient(id) {
		return instance.get(`clients/${id}`).then(response => response.data)
	},
	getClientsForFilter(name) {
		return instance
			.get(`clients?${stringify({ filter: JSON.stringify({ fio: name }) })}`)
			.then(response => response.data)
	},
	getCarsForFilter(name) {
		return instance
			.get(
				`reference/cars?${stringify({
					filter: JSON.stringify({ gosNum: name }),
				})}`,
			)
			.then(response => response.data)
	},
	getDimensionsForFilter(name) {
		return instance
			.get(
				`reference/dimensions?${stringify({
					filter: JSON.stringify({ sizeCar: name }),
				})}`,
			)
			.then(response => response.data)
	},
	updateClient(id, data) {
		return instance.put(`clients/${id}`, data).then(response => response.data)
	},
	updateClientInTelephony(values) {
		return instance.put(`clients`, values)
	},
}

export const referencesAPI = {
	getStages() {
		return instance.get(`reference/stages`).then(response => response.data)
	},
	getStage(id) {
		return instance.get(`reference/stages/${id}`).then(response => response.data)
	},
	getCars() {
		return instance.get(`reference/cars`).then(response => response.data)
	},
	getCar(id) {
		return instance.get(`reference/cars/${id}`).then(response => response.data)
	},
	getDrivers(range) {
		return instance
			.get(`reference/drivers?${stringify({ range: JSON.stringify(range) })}`)
			.then(response => response.data)
	},
	getDriver(id) {
		return instance.get(`reference/drivers/${id}`).then(response => response.data)
	},
	getDriversByCar(carId) {
		return instance
			.get(
				`reference/drivers?${stringify({
					filter: JSON.stringify({ carId: carId }),
				})}`,
			)
			.then(response => response.data)
	},
	getCompany() {
		return instance.get(`reference/company`).then(response => response.data)
	},
	getCompanyForFilter(name) {
		return instance
			.get(
				`reference/company?${stringify({
					filter: JSON.stringify({ name: name }),
				})}`,
			)
			.then(response => response.data)
	},
	getDimensions() {
		return instance.get(`reference/dimensions`).then(response => response.data)
	},
	getDimension(id) {
		return instance.get(`reference/dimensions/${id}`).then(response => response.data)
	},
	getPayments() {
		return instance.get(`reference/payments`).then(response => response.data)
	},
	getCallsClosing() {
		return instance.get(`reference/calls`).then(response => response.data)
	},
	getStatusRings() {
		return instance.get(`beeline/status`).then(response => response.data)
	},
}
export const carsAPI = {
	getDriverByCar(id) {
		return instance.get(`reference/cars/${id}`).then(response => response.data)
	},
	getDefaultCar() {
		return instance.get(`reference/cars/?${stringify({
			filter: JSON.stringify({gosnum: 'без авто'})})}`).then(response => response.data)
	},
}
export const ordersAPI = {
	getOrder(id) {
		return instance.get(`orders/${id}`).then(response => response.data)
	},
	getCarOrder(gosNum, date) {
		return instance
			.get(
				`orders/autopark?${stringify({
					filter: JSON.stringify({
						dateautopark: date,
						car: { gosnum: gosNum },
					}),
				})}`,
			)
			.then(response => response.data)
	},
	getCarCompletedOrder(gosNum, date) {
		return instance
			.get(
				`orders/autopark?${stringify({
					filter: JSON.stringify({
						dateautopark: date,
						car: { gosnum: gosNum },
						stage: { order: 10 },
					}),
				})}`,
			)
			.then(response => response.data)
	},
	getOrdersByCarId(carId) {
		return instance.get(`orders/?${stringify({
			filter: JSON.stringify({carId: carId})})}`).then(response => response.data)
	},
}

export const beelineAPI = {
	getMissedCalls() {
		const date = dateNow()

		return instance
			.get(
				`beeline/calls?${stringify({
					filter: JSON.stringify({
						statusId: 6,
						dateCreated: date,
						isWorkedOutCall: false,
					}),
				})}`,
			)
			.then(response => response.data)
	},
	updateCalls(id, data) {
		return instance.put(`beeline/calls/${id}`, data).then(response => response.data)
	},
}
// export const saveQr = async (file) => {
//   await axios.post(process.env.REACT_APP_API_URL + "/api/qr/save", file, {
//     timeout: 30000,
//     mode: "cors",
//     // credentials: "same-origin",
//     headers: {
//       Accept: "multipart/form-data",
//       // "Content-Type": "multipart/form-data; boundary=something",
//       "Content-Type": "application/json; charset=utf-8",
//       "Access-Control-Allow-Origin": "*",
//       Authorization: "Bearer " + localStorage.getItem("token"),
//     },
//   });
// };

export const postInfoCall = async body => {
	await instance.post('beeline/info/calls', body)
}
export const putInfoCall = async body => {
	await instance.put('beeline/info/calls', body)
}
export const saveQr = async link => {
	await instance.post('/api/qr/generate', link)
}

// export const saveQr = async (file) => {
//   await axios.post(process.env.REACT_APP_API_URL + "/api/qr/generate", file, {
//     timeout: 30000,
//     mode: "cors",
//     credentials: "same-origin",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "multipart/form-data; boundary",
//       "Access-Control-Allow-Origin": "*",
//       Authorization: "Bearer " + localStorage.getItem("token"),
//     },
//   });
// };
