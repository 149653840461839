import React from 'react'
import {
    Datagrid, DateField,
    List,
    TextField,
    usePermissions,
} from 'react-admin'

const HistoryDriversList = (props) => {
    const { permissions } = usePermissions()

    return (
        <List
            title="История изменений водителей"
            {...props}
        >
            {permissions !== "Driver" ?(<Datagrid>
                <DateField source="dateCreated" showTime label="Дата изменения" />
                <TextField label="Гос номер" source="car.gosNum" />
                <TextField label="Водитель" source="driver.fio" />
            </Datagrid>):null}
        </List>
    )
}

export default HistoryDriversList
