import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { svidaAPI } from "../../../../../api/svidaApi";
import { cityStyles } from "./selectCityAndStreetStyles";
import { disableEnter } from "../../../../../Utils/utils";

const SelectCityFrom = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [cityIdFrom, setCityIdFrom] = useState(92732);
  const [regionId, setRegionId] = useState(27);
  const [regionName, setRegionName] = useState("Ставропольский");
  const [value, setValue] = useState(null);
  const loading = open && options.length === 0;

  useEffect(() => {
      (async () => {
        await svidaAPI.getCity(92732).then((response) => {
          setValue(response);
        });
      })();
  }, []);

  useEffect(() => {
    if (props.cityIdFrom !== cityIdFrom) {
      setValue(null);
    }
    setCityIdFrom(props.cityIdFrom);
  }, [props.cityIdFrom, cityIdFrom]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await svidaAPI.getCities().then((response) => {
        if (active) {
          setOptions(
            Object.keys(response).map((key) => {
              return response[key];
            })
          );
        }
      });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  useEffect(() => {
    (async () => {
      await svidaAPI.getRegion(props.RegionIdFrom).then((response) => {
        setRegionId(response.id);
        setRegionName(response.name);
        svidaAPI.getCityByRegionName(response.name).then((response) => {
          return setOptions(
            Object.keys(response).map((key) => {
              return response[key];
            })
          );
        });
      });
    })();
  }, [props]);

  const onSelectCityIdFrom = (cityIdFrom) => {
    props.onSelectCityIdFrom(cityIdFrom);
  };

  const classes = cityStyles();

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) =>
        option.shortName +
        ". " +
        option.name +
        " (" +
        option.region.name +
        " " +
        option.region.shortName +
        ")"
      }
      value={value}
      options={options}
      loading={loading}
      loadingText={cityIdFrom ? "Загрузка..." : "Выберите город"}
      noOptionsText="Нет совпадений"
      clearText="Очистить"
      openText="Открыть"
      className={`${classes.inputNewWidthCity} ${classes.inputNewMRCity}`}
      onInputChange={(event, value) => {
        if (!value) {
          onSelectCityIdFrom(null);
        }
        (async () => {
          if (props.RegionIdFrom) {
            await svidaAPI.getRegion(props.RegionIdFrom).then((response) => {
              setRegionId(response.id);
              setRegionName(response.name);
            });
            svidaAPI.getCityByName(value, regionName).then((response) => {
              setOptions(
                Object.keys(response).map((key) => {
                  return response[key];
                })
              );
            });
          }
        })();
      }}
      onChange={(event, value) => {
        if (value) {
          onSelectCityIdFrom(value.id);
          (async () => {
            await svidaAPI.getStreetsByCityId(value.id).then((response) => {
              setValue(value);
              props.onSelectCityIdFrom(value.id);
            });
          })();
        }
        return !value ? false : setValue(value);
      }}
      onKeyDown={disableEnter}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Откуда: Город"
          name={props.name}
          variant="filled"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SelectCityFrom;
