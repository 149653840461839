import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useInput } from "react-admin";
import { svidaAPI } from "../../../../api/svidaApi";

const AsyncSelectCityFromCreate = React.memo((props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const loading = open && options.length === 0;
  const cityDefault = [
    {
      id: 92732,
      name: "Ставрополь",
      shortName: "г",
      country: { id: 38, name: "Российская Федерация" },
      region: { id: 27, name: "Ставропольский", shortName: "край" },
    },
  ];

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await svidaAPI.getCities().then((response) => {
        if (active) {
          setOptions(
            Object.keys(response).map((key) => {
              return response[key];
            })
          );
        }
      });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onSelectCityIdFrom = (cityIdFrom) => {
    props.onSelectCityIdFrom(cityIdFrom);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) =>
        option.name == null
          ? "г. Ставрополь (Ставропольский край)"
          : option.shortName +
            ". " +
            option.name +
            " (" +
            option.region.name +
            " " +
            option.region.shortName +
            ")"
      }
      loadingText="Загрузка..."
      noOptionsText="Нет совпадений"
      clearText="Очистить"
      openText="Открыть"
      options={options}
      defaultValue={cityDefault}
      onInputChange={(event, value) => {
        if (!value) {
          onSelectCityIdFrom(null);
        }

        (async () => {
          await svidaAPI.getCityByName(value).then((response) => {
            setOptions(
              Object.keys(response).map((key) => {
                return response[key];
              })
            );
          });
        })();
      }}
      onChange={(event, value) => {
        if (value) {
          onSelectCityIdFrom(value.id);
        }

        return !value ? false : onChange(value.id);
      }}
      renderInput={(params, input) => {
        return (
          <TextField
            {...params}
            {...input}
            name={name}
            label="Откуда: Город"
            size="small"
            variant="filled"
            error={!!(touched && error)}
            helperText={touched && error}
            required={isRequired}
            {...rest}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
});

export default AsyncSelectCityFromCreate;
