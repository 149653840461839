import React from 'react'
import {Datagrid, List, TextField, usePermissions} from 'react-admin'
import GroupButtonsActionTable from '../../../components/common/GroupButtonsActionTable'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsTopToolbar from '../../../components/common/ButtonsTopToolbar'
import BulkActionButtons from '../../../components/common/BulkActionButtons'

const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const ReferenceDimensionsList = (props) => {
    const classes = useStyles()
    const { permissions } = usePermissions()
    return (
        <List
            title="Справочник габаритов"
            actions={<ButtonsTopToolbar />}
            bulkActionButtons={<BulkActionButtons />}
            {...props}
        >
            {permissions !== "Driver" ?(<Datagrid>
                <TextField source="length" label="Длина (м)" />
                <TextField source="width" label="Ширина (м)" />
                <TextField source="height" label="Высота (м)" />
                <TextField source="volume" label="Объем (м³)" />
                <GroupButtonsActionTable
                    cellClassName={classes.widthActionsColumn}
                />
            </Datagrid>):null}
        </List>
    )
}

export default ReferenceDimensionsList
