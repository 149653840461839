import React, { useEffect, useState } from 'react'
import { Grid, Typography, Tooltip } from '@material-ui/core'
import { timelineStyles } from './TimelineStyles'
import { getContentOverlay } from './timelineLogics'
import { ordersAPI } from '../../../api/api'

import { dateNow, dateToMinutes } from '../../../Utils/utils'

const Timeline = ({ id, record, resource }) => {
	const workOrders = <p>Незавершенные заказы</p>
	const classes = timelineStyles()
	const [timeOrders, setTimeOrders] = useState(null)

	useEffect(() => {
		const date = dateNow()

		const getCarOrder = () => {
			ordersAPI.getCarOrder(record.gosNum, date).then(res => {
				let orders = res.data
				let timeOrder = orders.map(order => {
					if (order.historyStatus.length !== 0) {
						let isWorkedOrder = order.historyStatus.filter(history => history.stageOrder === 5)
						let startDate =
							(order.dateStartOrder && new Date(order.dateStartOrder) > new Date()) ||
							isWorkedOrder.length > 0
								? new Date(order.dateStartOrder)
								: new Date()
						let endDate =
							(order.dateEndOrder && new Date(order.dateEndOrder) > startDate) ||
							isWorkedOrder.length > 0
								? new Date(order.dateEndOrder)
								: startDate
						let timeOrderFrom =
							new Date(startDate).getDate() < new Date().getDate() ? 0 : dateToMinutes(startDate)
						let durationPrevPeriod =
							new Date(startDate).getDate() < new Date().getDate()
								? dateToMinutes(new Date(endDate).setHours(23, 59, 59, 999)) -
								  dateToMinutes(startDate)
								: 0
						let timeOrderTo =
							order.durationOrder !== 0
								? timeOrderFrom + order.durationOrder
								: dateToMinutes(endDate)

						if (durationPrevPeriod > 0) timeOrderTo = timeOrderTo - durationPrevPeriod
						if (timeOrderFrom > timeOrderTo) timeOrderTo = timeOrderFrom
						let cityFromName =
							order.cityFrom?.city?.name === undefined ? 'Не указано' : order.cityFrom?.city?.name
						let cityToName =
							order.cityTo?.city?.name === undefined ? 'Не указано' : order.cityTo?.city?.name
						if (timeOrderTo > 1440) timeOrderTo = 1440
						let car = order.car.marka + ' ' + order.car.gosNum,
							cityFromAddress =
								cityFromName +
								',' +
								(order.cityFrom?.street?.shortName ?? '-') +
								(order.cityFrom.street?.name ?? '-') +
								'-' +
								(order.cityFrom?.house ?? '-'),
							cityToAddress =
								cityToName +
								',' +
								(order.cityTo.street?.shortName ?? '-') +
								(order.cityTo.street?.name ?? '-') +
								'-' +
								(order.cityTo?.house ?? '-')

						return {
							id: order.id,
							phone: order.client.phone,
							stage: order.stage.name,
							stageOrder: order.stage.order,
							time: { from: timeOrderFrom, to: timeOrderTo },
							car,
							address: { from: cityFromAddress, to: cityToAddress },
						}
					}

					return false
				})

				setTimeOrders(timeOrder)
			})
		}

		getCarOrder()
	}, [record])
	return (
		<Grid container>
			<Grid item xs={12}>
				<div className={classes.timelineWrapper}>
					<Typography
						noWrap
						gutterBottom
						color='textPrimary'
						variant='subtitle1'
						className={classes.timelineTitle}
					>
						{record.marka} {record.gosNum} <p>Объем: {record.dimension.volume}м³</p>
					</Typography>
					{record.workOrders > 0 && (
						<Tooltip title={workOrders} placement='left' arrow>
							<div className={classes.isBusyBus}>{record.workOrders}</div>
						</Tooltip>
					)}
					<div className={classes.timelineContent}>
						{timeOrders !== null && getContentOverlay(timeOrders, classes)}
					</div>
				</div>
			</Grid>
		</Grid>
	)
}

export default Timeline
