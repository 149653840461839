import ClientsIcon from '@material-ui/icons/AssignmentInd'
import ClientsList from './ClientsList'
import ClientsEdit from './ClientsEdit'
import ClientsCreate from './ClientsCreate'

const index = {
    icon: ClientsIcon,
    list: ClientsList,
    edit: ClientsEdit,
    create: ClientsCreate,
}

export default index