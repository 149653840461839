import ReferenceCompanyIcon from '@material-ui/icons/EmojiTransportation'
import ReferenceCompanyList from './ReferenceCompanyList'
import ReferenceCompanyEdit from './ReferenceCompanyEdit'
import ReferenceCompanyCreate from './ReferenceCompanyCreate'

const index = {
    icon: ReferenceCompanyIcon,
    list: ReferenceCompanyList,
    edit: ReferenceCompanyEdit,
    create: ReferenceCompanyCreate
}

export default index