import React from 'react'
import {
    Edit,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { validateName } from '../../../validators/validate'
import ButtonsBottomToolbar from '../../../components/common/ButtonsBottomToolbar'

const Title = ({ record }) => {
    return <span>Способ оплаты {record ? `${record.name}` : ''}</span>
}

const useStyles = makeStyles({
    inputWidth: {
        display: 'inline-flex',
        marginRight: '1%',
        width: '32.6665%',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
})

const ReferenceCompanyEdit = (props) => {
    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()

    const onSuccess = () => {
        notify('Изменения сохранены')
        redirect('/reference/company')
        refresh()
    }

    const classes = useStyles()

    return (
        <Edit
            title={<Title />}
            onSuccess={onSuccess}
            mutationMode="pessimistic"
            {...props}
        >
            <SimpleForm toolbar={<ButtonsBottomToolbar />}>
                <TextInput
                    source="name"
                    label="Имя"
                    resettable
                    validate={validateName}
                    formClassName={classes.inputWidth}
                    fullWidth
                />
            </SimpleForm>
        </Edit>
    )
}

export default ReferenceCompanyEdit
