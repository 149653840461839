import React from 'react'
import { EditButton, TextField } from 'react-admin'
import DeleteWithConfirmButton from './CustomDeleteButton/DeleteWithConfirmButton'

const GroupButtonsActionTable = (props) => {
    return (
        <>
            <EditButton label="" size="medium" {...props} />
            <DeleteWithConfirmButton mutationMode="pessimistic" redirect={props.basePath} {...props} />
        </>
    )
}

GroupButtonsActionTable.defaultProps = TextField.defaultProps

export default GroupButtonsActionTable