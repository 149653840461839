import React from 'react'
import {ListButton, SaveButton, SimpleForm, Toolbar} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import {Link, Typography} from "@material-ui/core";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: "flex-end",
    },
    listButton: {
        marginRight: '10px',
        '& svg': {
            color: 'rgba(0, 0, 0, 0.23)',
        },
        boxShadow: '0 3px 4px -2px rgba(0, 0, 0, .13)',
        '&:hover': {
            boxShadow: '0 3px 4px -2px rgba(0, 0, 0, .2)',
        },
    },
    typography: {
        marginRight: 10,
    }
})

const ButtonsBottomToolbar = (props) => {
    const classes = useStyles()
    return (
        <Toolbar {...props} className={classes.toolbar}>
            {(props.resource === 'orders') &&
            <Typography className={classes.typography} variant="body2" color="textSecondary" gutterBottom>
                <Link
                    href="https://docs.google.com/spreadsheets/d/1-Ly0CsZGCL_MDDdZC_kup7JuBuAhnoow6hxx7vzAM1w/edit#gid=2052981550"
                    target="_blank"
                >
                    Тарифная сетка
                </Link>
            </Typography>}
            <ListButton
                basePath={props.basePath}
                label="Назад"
                variant={'outlined'}
                size={'medium'}
                color={'default'}
                icon={<IconCancel fontSize={'large'} />}
                className={classes.listButton}
            />
            <SaveButton label="Сохранить" />

        </Toolbar>
    )
}

export default ButtonsBottomToolbar