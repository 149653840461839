import { BooleanField, DateInput, Filter, SelectInput } from "react-admin";
import React, { useEffect, useState } from "react";
import { referencesAPI, userAPI } from "../../api/api";
import { makeStyles } from "@material-ui/core/styles";
import { useGlobal } from "../../GlobalState";

const CallsFilter = (props) => {
  const useStyles = makeStyles((theme) => ({
    inputStyle: {
      width: "220px",
      margin: "16px 0 2px",
    },
    inputAutocompleteStyle: {
      width: "456px",
      margin: "16px 0 2px",
    },
  }));
  const [globalState, globalActions] = useGlobal();
  const [status, setStatus] = useState([]);
  const workOut = [
    { status: true, nameRu: "Отработан", name: "Worked out" },
    { status: false, nameRu: "Не отработан", name: "Not worked out" },
  ];
  const classes = useStyles();
  useEffect(() => {
    (async () => {
      await referencesAPI.getStatusRings().then((res) => setStatus(res.data));
    })();
  }, []);
  return (
    <Filter {...props}>
      <DateInput
        source="dateCreated"
        label="Дата звонка"
        initialValue={globalState.date}
        className={classes.inputStyle}
        alwaysOn
      />
      <SelectInput
        source="statusid"
        label="Статус звонка"
        initialValue={5}
        choices={status}
        optionValue="id"
        optionText="nameRu"
        className={classes.inputStyle}
        alwaysOn
      />
      <SelectInput
        source="isWorkedOutCall"
        label="Статус работы"
        choices={workOut}
        optionValue="status"
        optionText="nameRu"
        className={classes.inputStyle}
        alwaysOn
      />
    </Filter>
  );
};

export default CallsFilter;
