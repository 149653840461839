import React from 'react'
import { Datagrid, List, TextField,usePermissions } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const ReferenceStagesList = props => {
    const classes = useStyles()

    return (
      <List title="Справочник по стадиям заказа"
            actions={null}
            bulkActionButtons={null}
            {...props}
      >
          <Datagrid>
              <TextField source="name" label="Стадия" />
              <TextField source="description" label="Описание" />
              <TextField source="order" label="Порядок" />
              <TextField source="orderDriver" label="Порядок для водителей" />
          </Datagrid>
      </List>
    )
}

export default ReferenceStagesList