import React, { useEffect, useState } from 'react'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import OrderCreateForm from './OrderCreateForm'
import { orderCreateModalStyles } from '../../TelephonyFormStyles'
import {putInfoCall} from "../../../../api/api";

const OrderCreateModal = props => {
	const [open, setOpen] = useState(false)
	const [orderInfoForModal, setOrderInfoForModal] = useState(null)

	useEffect(() => {
		props.orderInfoForModal &&
			setOrderInfoForModal(orderInfoForModal => ({
				...orderInfoForModal,
				orderData: props.orderInfoForModal.orderData,
				typeOrderData: props.orderInfoForModal.typeOrderData,
			}))
	}, [props.orderInfoForModal])

	useEffect(() => {
		props.createOrderOpenModal && setOpen(true)
	}, [props.createOrderOpenModal])

	const handleClose = () => {
		console.log('closeStelsModal')
		setOpen(false)
		props.closeForm()
		const userId = JSON.parse(localStorage.getItem('userId'))
		const callId = JSON.parse(localStorage.getItem('callId'))
		putInfoCall({ userId:userId,callId:callId })
	}

	const classes = orderCreateModalStyles()

	return (
		<span>
			<Modal
				aria-labelledby='order-create-modal-title'
				aria-describedby='order-create-modal-description'
				disablePortal
				disableEnforceFocus
				disableAutoFocus
				className={classes.modal}
				open={open}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<div className={classes.paper}>
						<h2 id='order-create-modal-title'>Создание заказа</h2>
						<span id='order-create-modal-description'>
							<OrderCreateForm
								client={props.client}
								orderInfoForModal={orderInfoForModal}
								openModal={handleClose}
							/>
						</span>
					</div>
				</Fade>
			</Modal>
		</span>
	)
}

export default OrderCreateModal
