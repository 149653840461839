import React, { useEffect, useState } from "react";
import { SelectInput, SimpleForm, TextInput, useInput} from "react-admin";
import { useFormState } from "react-final-form";
import { referencesAPI, ordersAPI } from "../../../../api/api";

const DriverSelectInputEdit = React.memo((props) => {
  const {
    input: { ...inputProps },
    meta: { touched, error },
  } = useInput(props);
  const [carId, setCarId] = useState(null);
  const [options, setOptions] = useState([]);
  const { values } = useFormState();
  const getdriver = (carId) => {
    if (carId != null) {
      referencesAPI.getDriversByCar(carId).then((res) => {
        if (res.data.length > 0) {
          props.record.driverId = res.data[0].id;
          props.record.driver = res.data[0];
        } else {
          props.record.driverId = null;
          props.record.driver = null;
        }
      });
    } else {
      props.record.driverId = null;
      props.record.driver = null;
    }
  };
  const getDriverOrder = () => {
    ordersAPI.getOrder(props.record.id).then((res) => {
      props.record.driverId = res.driverId;
      props.record.driver = res.driver;
    });
  };
  useEffect(() => {
    referencesAPI.getDrivers([0,99]).then((res) => {
      setOptions(
        Object.keys(res.data).map((key) => {
          return res.data[key];
        })
      );
    });
    !props.DriverCar && getDriverOrder();
  }, []);
  useEffect(() => {
    if (props.CarId != carId)
      setCarId(props.CarId)
  }, [props.CarId]);
  useEffect(() => {
    props.DriverCar && getdriver(carId)
  }, [carId]);
  return (
    <SelectInput
      source="driverId"
      choices={options}
      optionText="fio"
      label="Водитель"
      fullWidth
      resettable
      onChange={async (event) => {
        event && (await props.onSelectDriver());
      }}
    />
  );
});

export default DriverSelectInputEdit;
