import { makeStyles } from '@material-ui/core/styles'

export const streetStyles = makeStyles({
    inputWidthStreet: {
        display: 'inline-block',
        width: '49.5%',
        marginBottom: '0.0%',
    },
    inputMLStreet: {
        marginLeft: '0.5%',
    },
    inputMRStreet: {
        marginRight: '0.5%',
    },
    inputNewWidthStreet: {
        display: 'inline-block',
        width: '788.22px',
        marginBottom: '0.0%',
    },
    inputNewMLStreet: {
        marginLeft: '0.5%',
    },
    inputNewMRStreet: {
        marginRight: '0.5%',
    },
})

export const cityStyles = makeStyles({
    inputWidthCity: {
        display: 'inline-block',
        width: '49.5%',
        marginBottom: '0.0%',
    },
    inputMRCity: {
        marginRight: '0.5%',
    },
    inputNewWidthCity: {
        display: 'inline-block',
        width: '788.22px',
        marginBottom: '0.0%',
    },
    inputNewMRCity: {
        marginRight: '0.5%',
    },
})
