import { instance } from '../../api/api'
import decodeJwt from 'jwt-decode'
import connection from '../../containers/telephony/telephony'

const AuthReducer = {
    // called when the user attempts to log in
    login: ({ username, phonenumber, password }) => {
        return instance
            .post('auth/login', { username, phonenumber, password })
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText)
                }

                return response.data
            })
            .then((jsonData) => {
                const decodedToken = decodeJwt(jsonData.token)
                const expTimeStamp = decodedToken.exp * 1000
                if (decodedToken.role === "Driver")
                {
                    throw new Error("Доступ ограничен")
                }
                localStorage.setItem('userId', decodedToken.userId)
                localStorage.setItem('userName', decodedToken.userName)

                localStorage.setItem('token', jsonData.token)
                localStorage.setItem('permissions', decodedToken.role)
                localStorage.setItem('expires', expTimeStamp.toString())

                connection
                    .start()
                    .then((response) => {
                        localStorage.setItem('isConnection', true)
                        console.log('My connection id is ', connection.connectionId)
                    })
                    .catch((e) => console.log(e))
            })
            .catch(() => {
                return Promise.reject()
            })
    },

    // called when the user clicks on the logout button
    logout: () => {
        localStorage.clear()
        connection.stop().catch((e) => console.log(e))
        return Promise.resolve()
    },

    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            return Promise.reject()
        }

        return Promise.resolve()
    },

    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject()
    },

    getIdentity: () => {
        try {
            const id = localStorage.getItem('userId')
            const name = localStorage.getItem('userName')
            return Promise.resolve({ id, fullName: name, avatar: name })
        } catch (error) {
            return Promise.reject(error)
        }
    },

    getPermissions: () => {
        const role = localStorage.getItem('permissions')
        return role ? Promise.resolve(role) : Promise.reject()
    },
}

export default AuthReducer