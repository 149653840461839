import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ordersAPI } from '../../../api/api'
import AccordionForOrder from './AccordionForOrder/AccordionForOrder'
import { dateNow } from '../../../Utils/utils'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        whiteSpace: 'nowrap',
    },
}))

const History = ({ id, record, resource }) => {
    const [completedOrders, setCompletedOrders] = useState(null)

    useEffect(() => {
        const date = dateNow()

        ;(async () => {
            await ordersAPI.getCarCompletedOrder(record.gosNum, date).then((res) => {
                setCompletedOrders(res.data)
                return res.data
            })
        })()
    }, [record])

    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div className={classes.paper}>
                        <AccordionForOrder orders={completedOrders} />
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default History
