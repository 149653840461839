import React, { useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { InputPropTypes, useInput } from 'react-admin'
import { svidaAPI } from '../../../../api/svidaApi'
import { useGlobal } from '../../../../GlobalState'
import { disableEnter } from '../../../../Utils/utils'

const filter = createFilterOptions()

const AsyncSelectStreetFromEdit = React.memo(props => {
	const {
		input: { name, onChange, ...rest },
		meta: { touched, error, submitting },
		isRequired,
	} = useInput(props)

	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const [cityIdFrom, setCityIdFrom] = useState(null)
	const [value, setValue] = useState(null)
	const loading = open && options.length === 0 && cityIdFrom
	const [globalState, globalActions] = useGlobal()

	const [validateStreet, setValidateStreet] = useState(false)

	useEffect(() => {
		if (props.cityIdFrom) {
			setCityIdFrom(props.cityIdFrom)
			setValue(null)
		}
		;(async () => {
			await svidaAPI.getStreetsByCityId(globalState.cityId).then(response => {
				if (!cityIdFrom) {
					setOptions(
						Object.keys(response).map(key => {
							return response[key]
						}),
					)
				}
			})
		})()
	}, [props, globalState.cityId])

	useEffect(() => {
		if (props.record && props.record.cityId && !props.cityIdFrom) {
			let cityId = props.record.cityId

			setCityIdFrom(cityId)

			const getCity = async () => {
				if (cityId !== props.record.cityId)
					await svidaAPI.getStreetsByCityId(cityId).then(response => {
						return setOptions(
							Object.keys(response).map(key => {
								return response[key]
							}),
						)
					})
			}

			getCity()
		}

		let streetValue = props.record?.street

		if (streetValue) {
			switch (typeof streetValue) {
				case 'number':
					svidaAPI.getStreet(streetValue).then(response => setValue(response))
					break
				case 'string':
					setValue({
						name: streetValue,
					})
					break
				default:
					console.log('Нет совпадений по улицам')
			}
		}
	}, [props])

	useEffect(() => {
		let active = true
		if (!loading) {
			return undefined
		}
		if (cityIdFrom && active) {
			;(async () => {
				if (cityIdFrom !== props.record.cityId)
					await svidaAPI.getStreetsByCityId(cityIdFrom).then(response => {
						return setOptions(
							Object.keys(response).map(key => {
								return response[key]
							}),
						)
					})
			})()
		}

		return () => {
			active = false
		}
	}, [loading, cityIdFrom, props])

	useEffect(() => {
		if (!open) {
			setOptions([])
		}
	}, [open])

	useEffect(() => {
		if (typeof value === 'string') {
			setValue(null)
		}
	}, [value])

	return (
		<Autocomplete
			onKeyDown={disableEnter}
			open={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			getOptionSelected={(option, value) => {
				return option.name === value.name
			}}
			getOptionLabel={option => {
				if (typeof option === 'string') {
					return option
				}
				if (option.inputValue) {
					return 'ул. ' + option.inputValue
				}
				return 'ул. ' + option.name
			}}
			loadingText={cityIdFrom ? 'Загрузка...' : 'Выберите город'}
			noOptionsText='Нет совпадений'
			clearText='Очистить'
			openText='Открыть'
			options={options}
			loading={loading}
			onInputChange={(event, value) => {
				;(async () => {
					await svidaAPI.getStreetsByName(value, cityIdFrom).then(response => {
						setOptions(
							Object.keys(response).map(key => {
								return response[key]
							}),
						)
					})
				})()
			}}
			onChange={(event, newValue) => {
				if (typeof newValue === 'string') {
					// setValue({
					// 	name: value,
					// })
					setValue('')
					setValidateStreet(true)
					localStorage.setItem('validateStreet', false)
					setTimeout(() => {
						setValidateStreet(false)
						localStorage.setItem('validateStreet', true)
					}, 3000)
				} else if (newValue && newValue.inputValue) {
					setValue({
						name: newValue.inputValue,
					})
					setValidateStreet(false)
					localStorage.setItem('validateStreet', true)
				} else {
					setValue(newValue)
					setValidateStreet(false)
					localStorage.setItem('validateStreet', true)
				}

				return !newValue
					? onChange()
					: newValue.id
					? onChange(newValue.id)
					: onChange(newValue.inputValue)
			}}
			filterOptions={(options, params) => {
				const filtered = filter(options, params)

				const { inputValue } = params
				const isExisting = options.some(option => inputValue === option.name)
				if (inputValue !== '' && !isExisting) {
					filtered.push({
						inputValue,
						name: `Добавить ул "${inputValue}"`,
					})
				}
				return filtered
			}}
			onFocus={event => {
				if (props.record && cityIdFrom == null) {
					let cityId = props.record.cityId
					if (!cityId) cityId = 92732
					setCityIdFrom(cityId)
				}
				if (cityIdFrom) {
					;(async () => {
						await svidaAPI.getStreetsByCityId(cityIdFrom).then(response => {
							return setOptions(
								Object.keys(response).map(key => {
									return response[key]
								}),
							)
						})
					})()
				}
			}}
			value={value}
			renderInput={(params, input) => {
				return (
					<>
						<TextField
							{...params}
							{...input}
							name={name}
							label={props.label}
							size='small'
							variant='filled'
							error={validateStreet}
							helperText={validateStreet && <div>Необходимо выбрать из списка</div>}
							required={validateStreet}
							{...rest}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{loading ? <CircularProgress color='inherit' size={20} /> : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					</>
				)
			}}
		/>
	)
})

export default AsyncSelectStreetFromEdit
