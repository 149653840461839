import QrCodeIcon from '@material-ui/icons/Settings'
import QrCode from "../qrCode/QrCode";
import QrCodeList from "../qrCode/QrCodeList";

const index = {
    icon: QrCodeIcon,
    list: QrCodeList,
    edit: QrCode
}

export default index