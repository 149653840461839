import React, { useState } from "react";
import keyBy from "lodash.keyby";
import {
  Datagrid,
  DateField,
  ListContextProvider,
  ListView,
  Loading,
  Pagination,
  TextField,
  useQuery,
} from "react-admin";
import { dateNow } from "../../Utils/utils";
import { PhoneField } from "./PhoneField";

const MissedCalls = () => {
  const [reload, setReload] = useState(1);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sort, setSort] = useState({ field: "id", order: "ASC" });
  const date = dateNow();

  const { data, total, loading, error } = useQuery({
    type: "getList",
    resource: "beeline/calls",
    payload: {
      pagination: { page, perPage },
      sort,
      filter: { statusId: 6, dateCreated: date, isWorkedOutCall: false },
    },
  });

  const reloadComponent = () => {
    setReload((prev) => prev + 1);
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>ERROR: {error}</p>;
  }

  if (reload) {
    return (
      <ListContextProvider
        value={{
          data: keyBy(data, "id"),
          ids: data.map(({ id }) => id),
          total,
          page,
          perPage,
          setPage,
          setPerPage: (data) => setPerPage(data),
          currentSort: sort,
          setSort: (field, order) => setSort({ field, order }),
          basePath: "beeline/calls",
          resource: "beeline/calls",
          selectedIds: [],
        }}
      >
        <ListView title="Пропущенные вызовы">
          <Datagrid>
            <TextField source="id" label="№" />
            <PhoneField
              label="Телефон"
              data={data}
              reloadComponent={reloadComponent}
            />
            <DateField source="updateAt" showTime label="Дата" />
          </Datagrid>
        </ListView>
      </ListContextProvider>
    );
  }
};

export default MissedCalls;
