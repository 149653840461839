import React from 'react'
import {
    Edit, SimpleForm,
    TextInput,
    useRedirect,
    useRefresh
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsBottomToolbar from "../../components/common/ButtonsBottomToolbar";
import ButtonsBottomToolbarBid from "../../components/common/ButtonsBottomToolbarBid";

const useStyles = makeStyles({
    inputWidth: {
        display: 'inline-flex',
        marginRight: '1%',
        width: '32.6665%',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
})

const SettingsEdit = (props) => {
    const refresh = useRefresh()
    const redirect = useRedirect()
    const onSuccess = () => {
        redirect('/email')
        refresh()
    }

    const classes = useStyles()

    return (
            <Edit onSuccess={onSuccess} mutationMode="pessimistic" {...props}>
                <SimpleForm toolbar= {<ButtonsBottomToolbar />}>
                    <TextInput
                        source="server"
                        label="Сервер"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextInput
                        source="port"
                        label="Порт"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextInput
                        source="login"
                        label="Имя пользователя"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextInput
                        source="password"
                        label="Пароль"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                </SimpleForm>
            </Edit>
    )
}

export default SettingsEdit
