import React from "react";
import {
  Datagrid,
  List,
  NumberField,
  Pagination,
  TextField,
} from "react-admin";
import WhereFromField from "./CustomFields/WhereFromField";
import WhereField from "./CustomFields/WhereField";
import StageField from "./CustomFields/StageField";
import History from "./History/History";
import AutoparkFilter from "./AutoparkFilter";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  row: {
    width: "40px",
    display: "inline-block",
    textAlign: "center",
  },
});

const Autopark = (props) => {
  const PostPagination = (props) => (
    <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
  );
  const classes = useStyles();
  return (
    <List
      title="Автопарк"
      filters={<AutoparkFilter />}
      actions={null}
      bulkActionButtons={false}
      empty={false}
      {...props}
      pagination={<PostPagination />}
      perPage={100}
    >
      <Datagrid expand={<History />} rowClick={"expand"}>
        <NumberField
          sortable={false}
          className={classes.row}
          source="row"
          label="№ п/п"
        />
        <TextField source="marka" label="Авто" />
        <TextField source="gosNum" label="Гос. номер" />
        <TextField source="dimension.sizeCar" label="Габариты" />

        <WhereFromField sourse="cityFrom" label="Откуда" />
        <WhereField sourse="cityTo" label="Куда" />

        <StageField source="stages" label="Состояние заказа" />

        <TextField
          source="estimateMinuteOrder"
          label="Время до окончания заказа"
        />
        <TextField
          source="timeNextOrder"
          label="Время следующего заказа в очереди (ЧЧ.ММ)"
        />
        <NumberField source="sumOrder" label="Сумма текущего заказа" />
        <NumberField
          source="successOrders"
          label="Количество выполненных заказов"
        />
        <NumberField
          source="nextOrders"
          label="Количество заказов в очереди (на сегодня)"
        />
        <NumberField
          source="sumSuccessOrders"
          label="Сумма выполненных заказов"
        />
        <NumberField source="sumNextOrders" label="Сумма заказов в очереди" />
      </Datagrid>
    </List>
  );
};

export default Autopark;
