import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import {Grid, MenuItem, Select, TextField, Typography} from '@material-ui/core'
import ClientImg from '../../../images/avatar-client.jpg'
import StelsSaveButton from '../../../components/common/ButtonStyles/StelsSaveButton'
import StelsCloseButton from '../../../components/common/ButtonStyles/StelsCloseButton'
import { beelineAPI, referencesAPI } from '../../../api/api'
import OrderCreateModal from './order/OrderCreateModal'
import { oldClientModalStyles } from '../TelephonyFormStyles'
import {putInfoCall} from "../../../../src/api/api";

const OldClientModal = (props) => {
    const DATA_FROM_PREV_ORDER = 'data-from-prev-order'

    const client = props.client ?? null
    const [isClickSave, setIsClickSave] = useState(true)
    const [createOrderOpenModal, setCreateOrderOpenModal] = useState(false)
    const [isCallClosingField, setIsCallClosingField] = useState(false)
    const [callsClosing, setCallClosing] = useState(null)
    const [isClickCreateOrder, setIsClickCreateOrder] = useState(false)

    const [orderData, setOrderData] = useState(null)
    const [orderInfoForModal, setOrderInfoForModal] = useState(null)
    const [levelModal, setLevelModal] = useState(0)

    useEffect(() => {
        props.order && setOrderData((orderData) => ({ ...orderData, ...props.order }))
    }, [props.order])

    useEffect(() => {
        referencesAPI.getCallsClosing().then((res) => setCallClosing(res.data))
    }, [])

    let fio = null
    let companyName = null
    let phone = null
    let lastCall = null

    if (client) {
        if (!(client.surname && client.name && client.surname)) {
            fio = 'Фио: не указано'
        } else {
            fio = client.surname ?? ' '
                fio += ' '
                fio += client.name ?? ' '
                    fio += ' '
                    fio += client.patronymic ?? ''
        }
        companyName = !!client.company && client.company.length > 0 ? client.company : ''
        phone = !!client.phone && client.phone.length > 0 ? client.phone : 'Телефон не указан'
        lastCall = !!props.lastCall ? props.lastCall : ''
    }

    // Data for the customer with the order
    let dateCreated = null
    let cityFrom = 'Не указано'
    let cityTo = 'Не указано'
    let price = null
    let comment = null

    if (orderData) {
        dateCreated = new Date(orderData.dateCreated).toLocaleString()

        if (orderData.cityFrom && orderData.cityFrom.city && orderData.cityFrom.city.name) {
            cityFrom = (orderData.cityFrom.city.shortName ?? '') +
                ' ' +orderData.cityFrom.city.name +
                ' '
            if (orderData.cityFrom.street && orderData.cityFrom.street.name) {
                cityFrom += (orderData.cityFrom.street.shortName ?? '') +
                ' ' + orderData.cityFrom.street.name
            }
        }
        if (orderData.cityTo && orderData.cityTo.city && orderData.cityTo.city.name) {
            cityTo =
                (orderData.cityTo.city.shortName ?? '') +
                ' ' + orderData.cityTo.city.name +
                ' '
            if (orderData.cityTo.street  && orderData.cityTo.street.name) {
                cityTo += (orderData.cityTo.street.shortName ?? '') +
                    ' ' + orderData.cityTo.street.name
            }
        }

        price = orderData.price ?? ''
        comment = orderData.comment ?? ''
    }

    const [formValue, setFormValue] = useState({
        comment: '',
        callsClosingId: null,
    })

    const formik = useFormik({
        initialValues: { ...formValue },
        onSubmit: (values) => {
            setFormValue({ ...values })

            if (isClickSave) {
                setIsClickCreateOrder(true)
                // userAPI.updateClientInTelephony({ ...values }).then((res) => {
                //     setCreateOrderOpenModal(true)
                // })
            } else {
                if (isCallClosingField) {
                    beelineAPI.updateCalls(props.callId, {
                            comment: values.comment,
                            callsClosingId: values.callsClosingId,
                        })
                        .then(() => {
                            setIsClickSave(true)
                            props.closeForm()
                        })
                }

                setIsCallClosingField(true)
            }
        },
    })

    const handleCloseClick = () => {
        setIsClickSave(false)
        formik.handleSubmit()
        const userId = JSON.parse(localStorage.getItem('userId'))
        const callId = JSON.parse(localStorage.getItem('callId'))
        levelModal == 1 && setLevelModal(0)
        levelModal == 0 && setLevelModal(1)
        levelModal == 1 && putInfoCall({ userId:userId, callId:callId })
    }

    const createOrderFunc = (e) => {
        if (e === DATA_FROM_PREV_ORDER) {
            setOrderInfoForModal((orderInfoForModal) => ({
                ...orderInfoForModal,
                orderData: props.order,
                typeOrderData: DATA_FROM_PREV_ORDER,
            }))
        }

        setCreateOrderOpenModal(true)
    }

    const classes = oldClientModalStyles()

    return createOrderOpenModal ? (
        <OrderCreateModal
            client={client}
            closeForm={() => props.closeForm()}
            createOrderOpenModal={createOrderOpenModal}
            orderInfoForModal={orderInfoForModal}
        />
    ) : (
        client && (
            <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container>
                    <Grid item xs={6} className={classes.formInputWrapper}>
                        <img src={ClientImg} alt="" className={classes.formImg} />
                    </Grid>
                    <Grid item xs={6} className={classes.formRightColumn}>
                        <Grid item xs={12} className={classes.formInputWrapper}>
                            <Typography variant={'h5'} className={classes.formTitle}>
                                Входящий звонок
                            </Typography>

                            <Typography variant={'h6'} className={classes.formTitle}>
                                {fio}
                            </Typography>

                            {companyName && (
                                <Typography variant={'subtitle1'} className={classes.formPhone}>
                                    Компания: {companyName}
                                </Typography>
                            )}

                            <Typography variant={'subtitle1'} className={classes.formPhone}>
                                {phone}
                            </Typography>

                            {isClickCreateOrder ? (
                                <div className={classes.formClickCreateOrderButtons}>
                                    <StelsSaveButton onClick={() => createOrderFunc('data-new-order')}>
                                        Создать чистый (новый) заказ
                                    </StelsSaveButton>
                                    <StelsSaveButton onClick={() => createOrderFunc('data-from-prev-order')}>
                                        Создать заказ на основе предыдущего
                                    </StelsSaveButton>
                                </div>
                            ) : isCallClosingField ? (
                                    <>

                                <Select
                                    fullWidth
                                    id="callsClosingId"
                                    className={classes.formInput}
                                    name="callsClosingId"
                                    value={formik.values.callsClosingId}
                                    onChange={formik.handleChange}
                                >
                                    {callsClosing.map((call) => (
                                        <MenuItem key={call.id} value={call.id}>
                                            {call.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                        <TextField
                                            fullWidth
                                            id="comment"
                                            name="comment"
                                            label="Комментарий"
                                            multiline={true}
                                            rows={6}
                                            variant="filled"
                                            className={`${classes.formInputMultiple} ${classes.formInput}`}
                                            value={formik.values.comment}
                                            onChange={formik.handleChange}
                                        />
                                    </>

                            ) : orderData ? (
                                <>
                                    <Typography variant={'subtitle1'} className={classes.formPhone}>
                                        Дата заказа: {dateCreated}
                                    </Typography>
                                    <Typography variant={'subtitle1'} className={classes.formPhone}>
                                        От: {cityFrom}
                                    </Typography>
                                    <Typography variant={'subtitle1'} className={classes.formPhone}>
                                        До: {cityTo}
                                    </Typography>
                                    <Typography variant={'subtitle1'} className={classes.formPhone}>
                                        Цена: {price}
                                    </Typography>
                                    <Typography variant={'subtitle1'} className={classes.formPhone}>
                                        Комментарий: {comment}
                                    </Typography>

                                    {/*<Typography variant={'subtitle1'} className={classes.formPhone}>*/}
                                    {/*    <TextField*/}
                                    {/*        fullWidth*/}
                                    {/*        id="comment"*/}
                                    {/*        name="comment"*/}
                                    {/*        label="Комментарий"*/}
                                    {/*        multiline={true}*/}
                                    {/*        rows={6}*/}
                                    {/*        variant="filled"*/}
                                    {/*        value={formik.values.comment}*/}
                                    {/*        onChange={formik.handleChange}*/}
                                    {/*    />*/}
                                    {/*</Typography>*/}
                                </>
                            ) : (
                                <>
                                    <Typography variant={'subtitle1'} className={classes.formPhone}>
                                        Последний звонок: {new Date(lastCall.createdAt).toLocaleString()}
                                    </Typography>
                                    <Typography variant={'subtitle1'} className={classes.formPhone}>
                                        Сделка закрыта: {lastCall.callsClosing ?? 'не указано'}
                                    </Typography>
                                    <Typography variant={'subtitle1'} className={classes.formPhone}>
                                        Комментарий: {lastCall.comment ?? ''}
                                    </Typography>

                                    {/*<Typography variant={'subtitle1'} className={classes.formPhone}>*/}
                                    {/*    <TextField*/}
                                    {/*        fullWidth*/}
                                    {/*        id="comment"*/}
                                    {/*        name="comment"*/}
                                    {/*        label="Комментарий"*/}
                                    {/*        multiline={true}*/}
                                    {/*        rows={6}*/}
                                    {/*        variant="filled"*/}
                                    {/*        value={formik.values.comment}*/}
                                    {/*        onChange={formik.handleChange}*/}
                                    {/*    />*/}
                                    {/*</Typography>*/}
                                </>
                            )}
                        </Grid>

                        {!isClickCreateOrder && (
                            <Grid item xs={12} className={classes.formButtons}>
                                {!isCallClosingField && <StelsSaveButton type="submit">Создать заказ</StelsSaveButton>}

                                <StelsCloseButton type="button" onClick={handleCloseClick}>
                                    Закрыть
                                </StelsCloseButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </form>
        )
    )
}

export default OldClientModal
