import React, { useEffect, useState } from "react";
import { Button, useNotify } from "react-admin";
import { Form, Formik } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { beelineAPI, referencesAPI, userAPI } from "../../../../api/api";
import SelectCityFrom from "../../../telephony/modals/order/SelectCityAndStreet/SelectCityFrom";
import SelectStreetFrom from "../../../telephony/modals/order/SelectCityAndStreet/SelectStreetFrom";
import SelectCityTo from "../../../telephony/modals/order/SelectCityAndStreet/SelectCityTo";
import SelectStreetTo from "../../../telephony/modals/order/SelectCityAndStreet/SelectStreetTo";
import { appDataProvider } from "../../../../api/rest/rest";
import { sortByField } from "../../../../Utils/utils";
import { orderCreateFormStyles } from "../../../telephony/TelephonyFormStyles";
import IconCancel from "@material-ui/icons/Cancel";
import SelectRegionFrom from "../../../telephony/modals/order/SelectCityAndStreet/SelectRegionFrom";
import SelectRegionTo from "../../../telephony/modals/order/SelectCityAndStreet/SelectRegionTo";

const OrderCreateForm = ({ client, handleClose, processingCallId }) => {
  const notify = useNotify();
  const [regionIdFrom, setRegionIdFrom] = useState(27);
  const [regionIdTo, setRegionIdTo] = useState(27);
  const [cityIdFrom, setCityIdFrom] = useState(92732);
  const [cityIdTo, setCityIdTo] = useState(92732);
  const [lists, setLists] = useState({
    dimensions: [],
    stages: [],
    payments: [],
    managers: [],
  });

  let tzoffset = new Date().getTimezoneOffset() * 60000;
  let dateNow = new Date(Date.now() - tzoffset).toISOString().slice(0, -8);

  const initialValues = {
    dateCreated: dateNow,
    dimensionId: "",
    countWorkers: "",
    stageId: "",
    paymentId: "",
    price: "",
    managerId: "",
    comment: "",
    durationOrder: 60,
    clientId: client.id,
    cityFrom: {
      region: { id: 27 },
      city: { id: 92732 },
      street: { id: null },
      house: null,
      apartment: null,
    },
    cityTo: {
      region: { id: 27 },
      city: { id: 92732 },
      street: { id: null },
      house: null,
      apartment: null,
    },
    pointOrders: [
      {
        regionId: 27,
        cityId: 92732,
        street: null,
        house: null,
        apartment: null,
      },
      {
        regionId: 27,
        cityId: 92732,
        street: null,
        house: null,
        apartment: null,
      },
    ],
  };

  useEffect(() => {
    appDataProvider
      .getList(`reference/dimensions`, {
        pagination: { page: 1, perPage: 100 },
        sort: "ASC",
      })
      .then((res) => setLists((list) => ({ ...list, dimensions: res.data })));
    // referencesAPI.getDimensions().then((res) => setLists((list) => ({ ...list, dimensions: res.data })))
    referencesAPI.getStages().then((res) => {
      let stages = res.data.sort(sortByField("order"));
      return setLists((list) => ({ ...list, stages }));
    });
    referencesAPI
      .getPayments()
      .then((res) => setLists((list) => ({ ...list, payments: res.data })));
    userAPI
      .getLogisticiansRange([0,99])
      .then((res) => setLists((list) => ({ ...list, managers: res })));
  }, []);

  const submit = (values, actions) => {
    values.cityFrom.region.id = regionIdFrom;
    values.cityTo.region.id = regionIdTo;
    values.cityFrom.city.id = cityIdFrom;
    values.cityTo.city.id = cityIdTo;
    values.pointOrders[0].cityId = cityIdFrom;
    values.pointOrders[1].cityId = cityIdTo;
    appDataProvider
      .create("orders", { data: { ...values } })
      .then(async () => {
        await beelineAPI.updateCalls(processingCallId, {
          isWorkedOutCall: true,
        });
      })
      .then((values) => {
        setRegionIdFrom(null);
        setRegionIdTo(null);
        setCityIdFrom(null);
        setCityIdTo(null);
        initialValues.house = "";
        initialValues.apartment = "";
        initialValues.house = "";
        initialValues.apartment = "";
        actions.resetForm({ values: initialValues });
        handleClose("nextMissedCall");
        notify("Заказ успешно создан", "info");
      })
      .catch((e) => {
        notify(e.message, "error");
      });
  };

  const classes = orderCreateFormStyles();

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={submit}>
        {({ handleChange, values, setFieldValue }) => (
          <Form>
            <FormControl className={classes.inputWidth}>
              <TextField
                variant="filled"
                id="dateCreated"
                label="Дата и время заказа"
                type="datetime-local"
                name="dateCreated"
                value={values.dateCreated}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>

            <TextField
              name="clientId"
              label="Клиент"
              variant="filled"
              disabled
              value={client.name}
              className={classes.inputWidth}
            />

            <FormControl variant="filled" className={classes.inputWidth}>
              <InputLabel id="dimension-select-filled-label">
                Габариты авто
              </InputLabel>
              <Select
                id="dimensionId"
                labelId="dimension-select-filled-label"
                name="dimensionId"
                value={values.dimensionId}
                onChange={handleChange}
                fullWidth
              >
                {lists.dimensions.map((dimension) => (
                  <MenuItem key={dimension.id} value={dimension.id}>
                    {`${dimension.sizeCar}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="countWorkers"
              name="countWorkers"
              type="number"
              variant="filled"
              label="Кол-во грузчиков"
              value={values.countWorkers}
              onChange={handleChange}
              className={classes.inputWidth}
            />

            <FormControl variant="filled" className={classes.inputWidth}>
              <InputLabel id="stage-select-filled-label">
                Стадия заказа
              </InputLabel>
              <Select
                id="stageId"
                labelId="stage-select-filled-label"
                name="stageId"
                value={values.stageId}
                onChange={handleChange}
                fullWidth
              >
                {lists.stages.map((stage) => (
                  <MenuItem key={stage.id} value={stage.id}>
                    {stage.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl variant="filled" className={classes.inputWidth}>
              <InputLabel id="payment-select-filled-label">
                Способ оплаты
              </InputLabel>
              <Select
                id="paymentId"
                labelId="payment-select-filled-label"
                name="paymentId"
                value={values.paymentId}
                onChange={handleChange}
              >
                {lists.payments.map((payment) => (
                  <MenuItem key={payment.id} value={payment.id}>
                    {payment.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
                id="tariff"
                name="tariff"
                label="Тариф"
                variant="filled"
                value={values.tariff}
                onChange={handleChange}
                className={classes.inputWidth}
            />
            <TextField
              id="price"
              name="price"
              type="number"
              variant="filled"
              label="Цена"
              value={values.price}
              onChange={handleChange}
              className={classes.inputWidth}
            />

            <FormControl variant="filled" className={classes.inputWidth}>
              <InputLabel id="manager-select-filled-label">
                Ответственный логист
              </InputLabel>
              <Select
                id="managerId"
                labelId="manager-select-filled-label"
                name="managerId"
                value={values.managerId}
                onChange={handleChange}
              >
                {lists.managers.map((manager) => (
                  <MenuItem key={manager.id} value={manager.id}>
                    {manager.fio}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              id="comment"
              name="comment"
              label="Комментарий"
              variant="filled"
              multiline
              rowsMax="3"
              value={values.comment}
              onChange={handleChange}
              className={classes.inputWidthComment}
            />
            <TextField
              id="durationOrder"
              initialValue={60}
              formClassName={classes.inputHidden}
              type="hidden"
            />
            <SelectRegionFrom
              name="pointOrders[0].regionId"
              initialValue={27}
              value={values.cityFrom.region.id}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onSelectRegionIdFrom={(regionIdFrom) => {
                setRegionIdFrom(regionIdFrom);
              }}
            />
            <SelectCityFrom
              name="pointOrders[0].cityid"
              initialValue={92732}
              value={values.cityFrom.city.id}
              onChange={handleChange}
              RegionIdFrom={regionIdFrom}
              setFieldValue={setFieldValue}
              onSelectCityIdFrom={(cityIdFrom) => {
                setCityIdFrom(cityIdFrom);
              }}
            />
            <SelectStreetFrom
              name="pointOrders[0].street"
              value={values.cityFrom.street.id}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              cityIdFrom={cityIdFrom}
              className={`${classes.inputWidthCity} ${classes.inputMRCity}`}
            />
            <TextField
              name="pointOrders[0].streetName"
              label="Введите улицу"
              variant="filled"
              value={values.cityFrom.street.name}
              onChange={handleChange}
              className={`${classes.inputWidthCity} ${classes.inputMLCity}`}
              fullWidth
            />
            <TextField
              name="pointOrders[0].house"
              label="Откуда: Дом"
              variant="filled"
              value={values.cityFrom.house}
              onChange={handleChange}
              className={`${classes.inputWidthCity} ${classes.inputMRCity}`}
              fullWidth
            />
            <TextField
              name="pointOrders[0].apartment"
              label="Откуда: Квартира"
              variant="filled"
              value={values.cityFrom.apartment}
              onChange={handleChange}
              className={`${classes.inputWidthCity} ${classes.inputMRCity}`}
              fullWidth
            />
            <SelectRegionTo
              name="pointOrders[1].regionId"
              initialValue={27}
              value={values.cityTo.region.id}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              onSelectRegionIdTo={(regionIdTo) => {
                setRegionIdTo(regionIdTo);
              }}
              className={`${classes.inputWidthCity}`}
            />
            <SelectCityTo
              name="pointOrders[1].cityid"
              initialValue={92732}
              value={values.cityTo.city.id}
              onChange={handleChange}
              RegionIdTo={regionIdTo}
              setFieldValue={setFieldValue}
              onSelectCityIdTo={(cityIdTo) => {
                setCityIdTo(cityIdTo);
              }}
              className={`${classes.inputWidthCity}`}
            />
            <SelectStreetTo
              name="pointOrders[1].street"
              value={values.cityTo.street.id}
              onChange={handleChange}
              setFieldValue={setFieldValue}
              cityIdTo={cityIdTo}
              className={`${classes.inputWidthCity} ${classes.inputMRCity}`}
            />
            <TextField
              name="pointOrders[1].streetName"
              label="Введите улицу"
              variant="filled"
              value={values.cityTo.street.name}
              onChange={handleChange}
              className={`${classes.inputWidthCity} ${classes.inputMLCity}`}
              fullWidth
            />
            <TextField
              name="pointOrders[1].house"
              label="Куда: Дом"
              variant="filled"
              value={values.cityTo.house}
              onChange={handleChange}
              className={`${classes.inputWidthCity} ${classes.inputMRCity}`}
              fullWidth
            />
            <TextField
              name="pointOrders[1].apartment"
              label="Куда: Квартира"
              variant="filled"
              value={values.cityTo.apartment}
              onChange={handleChange}
              className={`${classes.inputWidthCity} ${classes.inputMRCity}`}
              fullWidth
            />

            <div className={classes.buttons}>
              <Button
                label="Закрыть"
                onClick={() => {
                  handleClose();
                }}
                variant={"outlined"}
                color={"default"}
              >
                <IconCancel style={{ color: "rgba(0, 0, 0, 0.23)" }} />
              </Button>
              <Button
                label="Сохранить"
                variant="contained"
                color="primary"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OrderCreateForm;
