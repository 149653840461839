import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useInput } from "react-admin";
import { svidaAPI } from "../../../../api/svidaApi";

const AsyncSelectCityToEdit = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [cityValue, setCityValue] = useState(null);
  const loading = open && options.length === 0;

  useEffect(() => {
    let cityData = props.record.cityTo.city;
    if (cityData) {
      (async () => {
        await svidaAPI
          .getCity(cityData.id)

          .then((response) => {
            setCityValue(response);
          });
      })();
    }
  }, [props.record.cityTo.city]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      await svidaAPI.getCities().then((response) => {
        if (active) {
          setOptions(
            Object.keys(response).map((key) => {
              return response[key];
            })
          );
        }
      });
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onSelectCityIdTo = (cityIdTo) => {
    props.onSelectCityIdTo(cityIdTo);
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) =>
        option.shortName +
        ". " +
        option.name +
        " (" +
        option.region.name +
        " " +
        option.region.shortName +
        ")"
      }
      loadingText="Загрузка..."
      noOptionsText="Нет совпадений"
      clearText="Очистить"
      openText="Открыть"
      options={options}
      loading={loading}
      value={cityValue}
      onInputChange={(event, value) => {
        if (!value) {
          onSelectCityIdTo(null);
        }

        (async () => {
          await svidaAPI.getCityByName(value).then((response) => {
            setOptions(
              Object.keys(response).map((key) => {
                return response[key];
              })
            );
          });
        })();
      }}
      onChange={(event, value) => {
        if (value) {
          onSelectCityIdTo(value.id);
          (async () => {
            await svidaAPI.getStreetsByCityId(value.id).then((response) => {
              setCityValue(value);
            });
          })();
        }

        return !value ? false : onChange(value.id);
      }}
      renderInput={(params, input) => {
        return (
          <TextField
            {...params}
            {...input}
            name={name}
            label="Куда: Город"
            size="small"
            variant="filled"
            error={!!(touched && error)}
            helperText={touched && error}
            required={isRequired}
            {...rest}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default AsyncSelectCityToEdit;
