import React, { useState } from 'react'
import { Box } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { DashboardMenuItem, MenuItemLink, usePermissions } from 'react-admin'
import SubMenu from './SubMenu'
import autopark from '../containers/autopark'
import references from '../containers/references'
import users from './../containers/references/referenceUsers'
import company from './../containers/references/referenceCompany'
import dimensions from './../containers/references/referenceDimensions'
import stages from './../containers/references/referenceStages'
import paymentMethods from './../containers/references/referencePaymentMethods'
import closingCall from './../containers/references/referenceClosingCall'
import cars from './../containers/references/referenceCars'
import drivers from './../containers/references/referenceDrivers'
import clients from './../containers/clients'
import orders from './../containers/orders'
import missedCalls from './../containers/calls'
import settings from './../containers/settings'

const useStyles = makeStyles({
	box: {
		maxWidth: '240px',
		'& a': {
			whiteSpace: 'normal',
		},
	},
})

const Menu = ({ onMenuClick, logout, dense = false }) => {
	const { loading, permissions } = usePermissions()
	const classes = useStyles()
	const [state, setState] = useState({
		menuReferences: false,
		menuSettingsReferences: false,
		menuHistory: false,
	})

	const open = useSelector(state => state.admin.ui.sidebarOpen)
	useSelector(state => state.theme)

	const handleToggle = menu => {
		setState(state => ({ ...state, [menu]: !state[menu] }))
	}

	return loading ? (
		<div>Пожалуйста подождите</div>
	) : (
		<Box className={classes.box} mt={2}>
			{permissions !== 'Driver' && <DashboardMenuItem onClick={onMenuClick} sidebarIsOpen={open} />}

			{permissions !== 'Driver' && (
				<MenuItemLink
					to={`/autopark`}
					primaryText='Автопарк'
					leftIcon={<autopark.icon />}
					onClick={onMenuClick}
					sidebarIsOpen={open}
					dense={dense}
				/>
			)}
			{permissions !== 'Driver' && (
				<MenuItemLink
					to={`/clients`}
					primaryText='Клиенты'
					leftIcon={<clients.icon />}
					onClick={onMenuClick}
					sidebarIsOpen={open}
					dense={dense}
				/>
			)}
			<MenuItemLink
				to={`/orders`}
				primaryText='Заказы'
				leftIcon={<orders.icon />}
				onClick={onMenuClick}
				sidebarIsOpen={open}
				dense={dense}
			/>
			{/*<MenuItemLink*/}
			{/*    to={`/transfer`}*/}
			{/*    primaryText="Сторонние заказы"*/}
			{/*    leftIcon={<orders.icon />}*/}
			{/*    onClick={onMenuClick}*/}
			{/*    sidebarIsOpen={open}*/}
			{/*    dense={dense}*/}
			{/*/>*/}
			{permissions !== 'Driver' && (
				<SubMenu
					handleToggle={() => {
						handleToggle('menuHistory')
					}}
					isOpen={state.menuHistory}
					sidebarIsOpen={open}
					name='История'
					icon={<references.icon />}
					dense={dense}
				>
					{permissions === 'Administrators' && (
						<MenuItemLink
							to={`/history/drivers`}
							primaryText='История водителей'
							leftIcon={<users.icon />}
							onClick={onMenuClick}
							sidebarIsOpen={open}
							dense={dense}
						/>
					)}
				</SubMenu>
			)}

			{permissions !== 'Driver' && (
				<SubMenu
					handleToggle={() => {
						handleToggle('menuReferences')
					}}
					isOpen={state.menuReferences}
					sidebarIsOpen={open}
					name='Справочники'
					icon={<references.icon />}
					dense={dense}
				>
					{permissions === 'Administrators' && (
						<MenuItemLink
							to={`/reference/users`}
							primaryText='Пользователи'
							leftIcon={<users.icon />}
							onClick={onMenuClick}
							sidebarIsOpen={open}
							dense={dense}
						/>
					)}
					<MenuItemLink
						to={`/reference/company`}
						primaryText='Компании'
						leftIcon={<company.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
					{/*FIXME По тз справочник автомобилей показан только с ролью админа */}
					<MenuItemLink
						to={`/reference/cars`}
						primaryText='Автомобили'
						leftIcon={<cars.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
					<MenuItemLink
						to={`/reference/drivers`}
						primaryText='Водители'
						leftIcon={<drivers.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
					<MenuItemLink
						to={`/reference/dimensions`}
						primaryText='Габариты'
						leftIcon={<dimensions.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
					<MenuItemLink
						to={`/reference/stages`}
						primaryText='Стадии заказа'
						leftIcon={<stages.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
					<MenuItemLink
						to={`/reference/payments`}
						primaryText='Способы оплаты'
						leftIcon={<paymentMethods.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
					<MenuItemLink
						to={`/reference/calls`}
						primaryText='Варианты закрытия звонка'
						leftIcon={<closingCall.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
				</SubMenu>
			)}
			{permissions !== 'Driver' && (
				<MenuItemLink
					to={`/missed-calls`}
					primaryText='Пропущ. вызовы'
					leftIcon={<missedCalls.icon />}
					onClick={onMenuClick}
					sidebarIsOpen={open}
					dense={dense}
				/>
			)}
			{permissions !== 'Driver' && (
				<MenuItemLink
					to={`/beeline/calls`}
					primaryText='Вызовы'
					leftIcon={<missedCalls.icon />}
					onClick={onMenuClick}
					sidebarIsOpen={open}
					dense={dense}
				/>
			)}
			{permissions === 'Administrators' && (
				<SubMenu
					handleToggle={() => {
						handleToggle('menuSettingsReferences')
					}}
					isOpen={state.menuSettingsReferences}
					sidebarIsOpen={open}
					name='Настройки'
					icon={<settings.icon />}
					dense={dense}
				>
					<MenuItemLink
						to={`/email`}
						primaryText='Интеграция с сайтом'
						leftIcon={<settings.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
					<MenuItemLink
						to={`/settings/telephony`}
						primaryText='Телефония'
						leftIcon={<settings.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
					<MenuItemLink
						to={`/api/qr`}
						primaryText='QrCode'
						leftIcon={<settings.icon />}
						onClick={onMenuClick}
						sidebarIsOpen={open}
						dense={dense}
					/>
				</SubMenu>
			)}
		</Box>
	)
}

export default Menu
