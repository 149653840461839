import React from 'react'
import { ListButton, SaveButton, Toolbar } from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import { makeStyles } from '@material-ui/core/styles'
import StelsSaveButton from "../../components/common/ButtonStyles/StelsSaveButton";
import StelsCloseButton from "../../components/common/ButtonStyles/StelsCloseButton";
import FormControl from "@material-ui/core/FormControl";
import {ordersAPI} from "../../api/api";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles({
    toolbar: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    listButton: {
        marginRight: '10px',
        '& svg': {
            color: 'rgba(0, 0, 0, 0.23)',
        },
        boxShadow: '0 3px 4px -2px rgba(0, 0, 0, .13)',
        '&:hover': {
            boxShadow: '0 3px 4px -2px rgba(0, 0, 0, .2)',
        },
    },
})
const handleCreateOrder = (values) => {
    values.typeOrder = 1
    ordersAPI
        .updateOrder(values.id, values)
    window.location.href = "/#/orders"
}
const handleClose = (values) => {
    values.typeOrder = 3
    ordersAPI
        .updateOrder(values.id, values)
    window.location.href = "/#/orders"
}
const ButtonsBottomToolbarBid = (props) => {
    const classes = useStyles()

    return (
        <Toolbar {...props} className={classes.toolbar}>
                <StelsSaveButton type="button" className={classes.listButton} onClick={() => handleCreateOrder(props.record)}>Создать
                    заказ</StelsSaveButton>

                <StelsCloseButton type="button"
                                  onClick={() => handleClose(props.record)}
                >
                    Закрыть
                </StelsCloseButton>
        </Toolbar>
    )
}

export default ButtonsBottomToolbarBid