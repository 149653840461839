import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

const TimeLineSeparator = () => {
    let date=new Date();
    const useStyles = makeStyles({
        separator: {
            marginLeft: '140px',
            position: 'relative',
            '&::after': {
                content: '""',
                borderLeft: '1px dotted red',
                height: '99999px',
                position: 'absolute',
                left: `${(date.getHours() * 60 + date.getMinutes()) * 0.06944444444}%`,
                bottom: 0,
            },
        },
        separatorWrapper: {
            padding: '0 16px',
            position: 'sticky',
        },
    })
    const [time, setTime] = useState(Date.now())

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 5 * 60 * 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    const classes = useStyles()
    return (
        <div className={classes.separatorWrapper}>
            <div className={classes.separator}/>
        </div>
    )
}
export default TimeLineSeparator