import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import OrderCreateForm from './OrderCreateForm'

const OrderCreateModal = ({ open, client, handleClose, processingCallId }) => {
    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-create-order" maxWidth="lg">
            <DialogTitle id="form-create-order">Создание заказа</DialogTitle>

            <DialogContent>
                <OrderCreateForm client={client} handleClose={handleClose} processingCallId={processingCallId} />
            </DialogContent>
        </Dialog>
    )
}

export default OrderCreateModal
