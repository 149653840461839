import React, { useState } from "react";
import {
  Create,
  maxValue,
  minValue,
  NumberInput,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import ButtonsBottomToolbar from "../../../components/common/ButtonsBottomToolbar";
import { validateSymbol } from "../../../validators/validate";

const useStyles = makeStyles({
  inputWidth: {
    display: "inline-flex",
    marginRight: "1%",
    width: "32.6665%",
    "&:nth-child(3n)": {
      marginRight: "0",
    },
  },
});

const ReferenceDimensionsCreate = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [initialValue, setInitialValue] = useState({
    length: "",
    width: "",
    height: "",
  });

  const onChangeForm = ({ target }) => {
    if (target) {
      if (!target.value) {
        target.value = 0;
      }
      setInitialValue({ ...initialValue, [target.name]: target.value });
    }
  };

  const onSuccess = () => {
    notify("Габариты созданы");
    redirect("/reference/dimensions");
    refresh();
  };

  const classes = useStyles();
  return (
    <Create title="Создать габарит" onSuccess={onSuccess} {...props}>
      <SimpleForm toolbar={<ButtonsBottomToolbar />}>
        <NumberInput
          initialValue={initialValue.length}
          formClassName={classes.inputWidth}
          onChange={onChangeForm}
          step={0.1}
          source="length"
          label="Длина"
          fullWidth
          validate={[
            validateSymbol,
            maxValue(1290, "Максимальное значение - 1290м"),
            minValue(0, "Минимальное значение - 0м"),
          ]}
          InputProps={{
            endAdornment: <InputAdornment position="start">м</InputAdornment>,
          }}
        />
        <NumberInput
          initialValue={initialValue.width}
          formClassName={classes.inputWidth}
          onChange={onChangeForm}
          step={0.1}
          source="width"
          label="Ширина"
          fullWidth
          validate={[
            validateSymbol,
            maxValue(1290, "Максимальное значение - 1290м"),
            minValue(0, "Минимальное значение - 0м"),
          ]}
          InputProps={{
            endAdornment: <InputAdornment position="start">м</InputAdornment>,
          }}
        />
        <NumberInput
          initialValue={initialValue.height}
          formClassName={classes.inputWidth}
          onChange={onChangeForm}
          step={0.1}
          source="height"
          label="Высота"
          fullWidth
          validate={[
            validateSymbol,
            maxValue(1290, "Максимальное значение - 1290м"),
            minValue(0, "Минимальное значение - 0м"),
          ]}
          InputProps={{
            endAdornment: <InputAdornment position="start">м</InputAdornment>,
          }}
        />
      </SimpleForm>
    </Create>
  );
};

export default ReferenceDimensionsCreate;
