import React, { useEffect } from 'react'
import { Datagrid, List, Pagination, ResourceContextProvider, useRefresh } from 'react-admin'
import AutoparkFilter from '../autopark/AutoparkFilter'
import Timeline from './Timeline/Timeline'
import TimeLineFooter from '../../components/common/TimeLineFooter'
import TimeLineSeparator from '../../components/common/TimeLineSeparator'
import { makeStyles } from '@material-ui/core/styles'
import { useRecursiveTimeout } from '../../Utils/utils'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles({
	tableWrapper: {
		position: 'relative',
		overflow: 'hidden',
	},
	removePadding: {
		'& > tbody tr td': {
			padding: '1px 16px',
		},
	},
})

const Dashboard = props => {
	const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
	const classes = useStyles()

	const dispatch = useDispatch()

	const { currentOrder } = useSelector(state => state)

	const refresh = useRefresh()
	useRecursiveTimeout(() => refresh(), 30000)

	useEffect(() => {
		if (currentOrder.order !== null) {
			refresh()
			dispatch({ type: 'ADD_ORDER', payload: null })
		}
	}, [currentOrder])

	return (
		<ResourceContextProvider value='autopark'>
			<List
				title='Таймлайн'
				filters={<AutoparkFilter />}
				actions={null}
				bulkActionButtons={false}
				empty={false}
				syncWithLocation
				basePath='/autopark'
				pagination={<PostPagination />}
				perPage={100}
				{...props}
			>
				<>
					<div className={classes.tableWrapper}>
						<Datagrid className={classes.removePadding}>
							<Timeline />)
						</Datagrid>
						<TimeLineSeparator />
					</div>
					<TimeLineFooter />
				</>
			</List>
		</ResourceContextProvider>
	)
}

export default Dashboard
