import React from 'react'
import {
    BooleanField,
    Datagrid,
    List,
    ReferenceField, ReferenceInput,
    TextField, usePermissions,
} from 'react-admin'
import ReferenceCarsListActions from './ReferenceCarsListActions'
import GroupButtonsActionTable from '../../../components/common/GroupButtonsActionTable'
import { makeStyles } from '@material-ui/core/styles'
import BulkActionButtons from '../../../components/common/BulkActionButtons'
import ReferenceCarsFilter from './ReferenceCarsFilter'
import ButtonsTopToolbar from "../../../components/common/ButtonsTopToolbar";

const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const ReferenceCarsList = (props) => {
    const classes = useStyles()
    const { permissions } = usePermissions()

    return (
        <List
            title="Справочник автомобилей"
            actions={permissions === 'Administrators' ? <ReferenceCarsListActions />: null}
            bulkActionButtons={<BulkActionButtons />}
            filters={<ReferenceCarsFilter />}
            {...props}
        >
            {permissions !== "Driver" ?(<Datagrid>
                <TextField source="gosNum" label="Гос. номер" />
                <TextField source="marka" label="Марка" />
                <ReferenceField
                    label="Габариты"
                    source="dimensionId"
                    reference="reference/dimensions"
                    link={false}
                >
                    <TextField source="sizeCar" />
                </ReferenceField>
                <ReferenceField
                    label="Водитель"
                    source="driverId"
                    reference="reference/drivers"
                    link={false}
                >
                    <TextField source="fio" />
                </ReferenceField>
                <BooleanField
                    source="outSide"
                    label="Внештатный"
                    valueLabelTrue="Внештатный"
                    valueLabelFalse="Штатный"
                />
                <BooleanField
                    source="isRepair"
                    label="В ремонте"
                    valueLabelTrue="В ремонте"
                    valueLabelFalse="Не в ремонте"
                />
                <GroupButtonsActionTable
                    cellClassName={classes.widthActionsColumn}
                />
            </Datagrid>):null}
        </List>
    )
}

export default ReferenceCarsList
