import { email, minValue, required } from 'react-admin'

export const validateLogin = [
    required('Обязательно для заполнения'),
    email('Логин является почтой'),
]

export const validateName = [
    required("Обязательно для заполнения"),
    minValue(2, "Не менее 2 букв"),
]

export const validateRequired = required("Обязательно для заполнения")

export const validateSymbol = (value) => {
    if (!value) {
        return "Обязательно для заполнения. Поле может содержать только цифры и запятую";
    }
};

/* FIXME Поправить валидацию для маски номера телефона */
export const validatePhoneNumberFunction = (value, values) => {
    if (values.phone) {
        value = value.split("")
        let filtered = value.filter((el) => {
            if (!isNaN(el) && typeof parseInt(el) === "number" && el !== " ") {
                return true
            }
            return false
        })
        let numberCount = parseInt(filtered.join(""))
        let result = String(numberCount).length

        if (result < 10) {
            return "Введите номер телефона"
        }

        return undefined
    }
    return false
}

export const validateAdditionalPhoneNumberFunction = (value, values) => {
    if (values.additionalPhone) {
        value = value.split("")
        let filtered = value.filter((el) => {
            if (!isNaN(el) && typeof parseInt(el) === "number" && el !== " ") {
                return true
            }
            return false
        })
        let numberCount = parseInt(filtered.join(""))
        let result = String(numberCount).length

        if (result < 10) {
            return "Введите номер телефона"
        }

        return undefined
    }

    return false
}
export const validatePhoneNumberAuth = (value, values) => {
    if (values.phonenumber) {
        value = value.split("")
        let filtered = value.filter((el) => {
            if (!isNaN(el) && typeof parseInt(el) === "number" && el !== " ") {
                return true
            }
            return false
        })
        let numberCount = parseInt(filtered.join(""))
        let result = String(numberCount).length

        if (result < 10) {
            return "Введите номер телефона"
        }

        return undefined
    }
    return false
}
