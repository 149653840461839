import * as React from 'react'
import { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import ActionDelete from '@material-ui/icons/Delete'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { makeStyles } from '@material-ui/core/styles'
import {
    CRUD_DELETE_MANY,
    useDeleteMany,
    useNotify,
    useRefresh,
    useResourceContext,
    useUnselectAll,
} from 'ra-core'

import Confirm from './Confirm'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(
    (theme) => ({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    { name: 'RaBulkDeleteWithConfirmButton' }
)

const defaultIcon = (
    <ActionDelete style={{ marginRight: '5px' }} fontSize="small" />
)

const BulkDeleteWithConfirmButton = (props) => {
    const {
        basePath,
        classes: classesOverride,
        confirmTitle = 'Удаление',
        confirmContent = 'ra.message.bulk_delete_content',
        icon = defaultIcon,
        label,
        onClick,
        selectedIds,
        ...rest
    } = props
    const [isOpen, setOpen] = useState(false)
    const classes = useStyles(props)
    const notify = useNotify()
    const unselectAll = useUnselectAll()
    const refresh = useRefresh()
    const resource = useResourceContext(props)
    const [deleteMany, { loading }] = useDeleteMany(resource, selectedIds, {
        action: CRUD_DELETE_MANY,
        onSuccess: () => {
            refresh()
            notify('ra.notification.deleted', 'info', {
                smart_count: selectedIds.length,
            })
            unselectAll(resource)
        },
        onFailure: (error) => {
            notify(
                typeof error === 'string'
                    ? error
                    : error.message || 'ra.notification.http_error',
                'warning',
                {
                    _:
                        typeof error === 'string'
                            ? error
                            : error && error.message
                            ? error.message
                            : undefined,
                }
            )

            setOpen(false)
            refresh()
            unselectAll(resource)
        },
    })

    const handleClick = (e) => {
        setOpen(true)
        e.stopPropagation()
    }

    const handleDialogClose = () => {
        setOpen(false)
    }

    const handleDelete = (e) => {
        deleteMany()

        if (typeof onClick === 'function') {
            onClick(e)
        }
    }

    return (
        <Fragment>
            <Button
                onClick={handleClick}
                label="Удалить"
                className={classes.deleteButton}
                {...sanitizeRestProps(rest)}
            >
                {icon} Удалить
            </Button>
            <Confirm
                isOpen={isOpen}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                translateOptions={{
                    smart_count: selectedIds.length,
                    name: 'объект',
                }}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    )
}

const sanitizeRestProps = ({
    basePath,
    classes,
    filterValues,
    label,
    ...rest
}) => rest

BulkDeleteWithConfirmButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    label: PropTypes.string,
    resource: PropTypes.string,
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
    icon: PropTypes.element,
}

BulkDeleteWithConfirmButton.defaultProps = {
    label: 'ra.action.delete',
}

export default BulkDeleteWithConfirmButton
