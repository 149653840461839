import {
    TextInput,
    DateInput,
    Filter,
    SelectInput,
    usePermissions
} from 'react-admin'
import React, { useEffect, useState } from 'react'
import { referencesAPI, userAPI } from '../../api/api'
import { svidaAPI } from '../../api/svidaApi'
import AutocompleteSelectFilter from '../../components/common/Autocomplete/AutocompleteSelectFilter'
import { makeStyles } from '@material-ui/core/styles'
import { filterOptions } from '../../Utils/utils'
import {useGlobal} from "../../GlobalState";
import {Link, Typography} from "@material-ui/core";

const OrdersFilter = (props) => {
    const [stages, setStages] = useState([])
    const [logisticians, setLogisticians] = useState([])
    const [cars, setCars] = useState([])
    const [dimensions, setDimensions] = useState([])
    const types = [
        {status: false,nameRu:"Штатный", name: "Salaried"},
        {status: true,nameRu:"Внештатный", name: "FreeLance"}
    ]
    const typeorders = [
        {id: 1,name:"Заказ"},
        {id: 2,name:"Заявка"},
        {id: 3,name:"Прочее"},
    ]
    const [href, setHref] = useState("/#/orders")
    const [globalState, globalActions] = useGlobal();
    const { loading, permissions } = usePermissions();
    const resetFilter = () => {
        props.setFilters({}, []);
    };
    useEffect(() => {
        ;(async () => {
            await referencesAPI.getStages().then((res) => setStages(res.data))

            await userAPI.getLogisticians().then((res) => setLogisticians(res))

            await referencesAPI.getCars().then((res) => setCars(res.data))

            await referencesAPI.getDimensions().then((res) => setDimensions(res.data))

        })()
    }, [])

    const useStyles = makeStyles((theme) => ({
        inputStyle: {
            width: '220px',
            margin: '16px 0 2px',
        },
        inputAutocompleteStyle: {
            width: '456px',
            margin: '16px 0 2px',
        },
        inputAutocompleteSmallStyle: {
            width: '256px',
            margin: '16px 0 2px',
        },
        inputRezStyle: {
            width: '290px',
            margin: '6px 0 0 0',
            padding: '0px'
        },
        linkFilterStyle: {
            width: '290px',
            margin: '16px 0 2px ',
            padding: '10px 0'
        },
    }))

    const classes = useStyles()
    return (
       <Filter {...props}>
           {permissions !== "Driver" ?(<TextInput
               source="IdString"
               label="Номер заказа"
               className={classes.inputRezStyle}
               alwaysOn
           />):null}
           {permissions !== "Driver" ?(<DateInput
                source="dateBegin"
                label="Дата заказа (от)"
                initialValue={globalState.date}
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
           {permissions !== "Driver" ?(<DateInput
                source="dateEnd"
                label="Дата заказа (до)"
                initialValue={globalState.date}
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
           {permissions !== "Driver" ?(<SelectInput
                source="stage.order"
                label="Состояние заказа"
                choices={stages}
                optionValue="order"
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
           {permissions !== "Driver" ?(<SelectInput
                source="isFreelanceOrder"
                label="Тип заказа"
                choices={types}
                optionValue="status"
                optionText="nameRu"
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
            {permissions !== "Driver" ?(<AutocompleteSelectFilter
                targetList={{
                    get: referencesAPI.getDimensions,
                    getInputChange: userAPI.getDimensionsForFilter,
                    type: 'normal',
                }}
                source="dimensionId"
                optionLabel="sizeCar"
                label="Габариты"
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
            {permissions !== "Driver" ?(<AutocompleteSelectFilter
                targetList={{
                    get: referencesAPI.getCars,
                    getInputChange: userAPI.getCarsForFilter,
                    type: 'normal',
                }}
                source="carId"
                optionLabel="marka gosNum"
                label="Автомобиль"
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
            {permissions !== "Driver" ?(<SelectInput
                source="TypeOrder"
                label="Тип"
                choices={typeorders}
                optionValue="id"
                optionText="name"
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
            {permissions !== "Driver" ?(<AutocompleteSelectFilter
                targetList={{
                    get: userAPI.getClients,
                    getInputChange: userAPI.getClientsForFilter,
                    type: 'normal',
                }}
                source="clientId"
                optionLabel="fio phone"
                filterOptions={filterOptions}
                label="Клиент"
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
            {permissions !== "Driver" ?(<AutocompleteSelectFilter
                targetList={{
                    get: svidaAPI.getCities,
                    getInputChange: svidaAPI.getCityAll,
                    type: 'cities',
                }}
                source="placeId"
                label="Город"
                className={classes.inputRezStyle}
                alwaysOn
            />):null}
           {permissions !== "Driver" ?(<SelectInput
               source="managerId"
               label="Ответственный"
               choices={logisticians}
               optionText="fio"
               className={classes.inputRezStyle}
               alwaysOn
           />):null}
           {permissions !== "Driver" ?(
                   <Link
                       href="javascript:void(0);"
                       label="Сбросить фильтр"
                       onClick = {resetFilter}
                       className={classes.linkFilterStyle}
                       alwaysOn
                   >
                       Сбросить фильтр
                   </Link>
           ):null}
        </Filter>
    )
}

export default OrdersFilter
