import * as React from "react";

const AddressField = ({ source, record = {} }) => {
  if (record.id === undefined) {
    return <span>Загрузка...</span>;
  }

  let result;

  if (record[source] && record[source].city) {
    result = Object.entries(record[source]);
    result.map((el) => {
      switch (el[0]) {
        case "city":
          if (el[1] && el[1].name) {
            el[0] = el[1].shortName + ". " ?? "г. ";
            el[1] = el[1].name + " ";
            break;
          } else {
            el[0] = " ";
            el[1] = " ";
            break;
          }
        case "street":
          if (el[1]) {
            el[0] = el[1].shortName ?? "ул. ";
            el[1] = el[1].name + " ";
          } else {
            el[0] = " ";
            el[1] = " Не указано ";
          }
          break;
        case "house":
          if (el[1]) {
            el[0] = "д. ";
          } else {
            el[0] = " ";
            el[1] = " ";
          }
          break;
        case "apartment":
          if (el[1]) {
            el[0] = "кв. ";
          } else {
            el[0] = " ";
            el[1] = " ";
          }
          break;
        default:
          break;
      }

      return el;
    });
  }

  return (
    <>
      {result ? (
        result.map((item, key) => {
          return <span key={key}>{item[1] === null ? " " : item}</span>;
        })
      ) : (
        <span>Не указано</span>
      )}
    </>
  );
};

export default AddressField;
