import { matchSorter } from 'match-sorter'
import { useEffect, useRef } from 'react'

export const SaveDataToLocalStorage = (key, data) => {
	let a = []
	a = JSON.parse(localStorage.getItem(key)) || []
	a.push(data)
	localStorage.setItem(key, JSON.stringify(a))
}

export const deepCopyFunction = inObject => {
	let outObject, value, key

	if (typeof inObject !== 'object' || inObject === null) {
		return inObject // Return the value if inObject is not an object
	}

	// Create an array or object to hold the values
	outObject = Array.isArray(inObject) ? [] : {}

	for (key in inObject) {
		value = inObject[key]

		// Recursively (deep) copy for nested objects, including arrays
		outObject[key] = deepCopyFunction(value)
	}

	return outObject
}

export const sortByField = field => {
	return (a, b) => (a[field] > b[field] ? 1 : -1)
}

// 2021-06-09T17:39:44
export const dateNow = () => {
	const addZero = date => {
		return date.toString().padStart(2, '0')
	}

	let now = new Date()
	return (
		now.getFullYear() +
		'-' +
		addZero(now.getMonth() + 1) +
		'-' +
		addZero(now.getDate()) +
		'T' +
		addZero(now.getHours()) +
		':' +
		addZero(now.getMinutes()) +
		':' +
		addZero(now.getSeconds())
	)
}

export const dateToMinutes = date => {
	let timeHour = new Date(date).getHours() * 60,
		timeMin = new Date(date).getMinutes()
	return timeHour + timeMin
}

export const filterOptions = (options, { inputValue }) => {
	if (!inputValue || !inputValue.length) {
		return options
	}
	const terms = inputValue.split(' ')
	if (!terms) {
		return options
	}

	return terms.reduceRight(
		(results, term) =>
			matchSorter(results, term, {
				keys: ['name', 'surname', 'patronymic', 'phone', 'phone8', 'company.name'],
			}),
		options,
	)
}

export const convertDate = date => {
	let d = new Date(date)
	return (
		('0' + d.getDate()).slice(-2) +
		'.' +
		('0' + (d.getMonth() + 1)).slice(-2) +
		'.' +
		d.getFullYear()
	)
}
export const getFieldsClient = strClient => {
	let masStrClient = strClient ? strClient.split(' ') : null
	let fields = {
		phone: '',
		name: '',
		surname: '',
		patronymic: '',
	}

	if (masStrClient && masStrClient.length > 0) {
		for (let i = 0; i <= masStrClient.length - 1; i++) {
			if (isNaN(masStrClient[i]) === false) {
				fields.phone = masStrClient[i]
			} else {
				if (
					masStrClient[i].indexOf('(') > 0 ||
					masStrClient[i].indexOf(')') > 0 ||
					masStrClient[i].indexOf('+') > 0 ||
					masStrClient[i].indexOf('-') > 0
				)
					break
				if (fields.name == '') fields.name = masStrClient[i]
				else if (fields.patronymic == '') fields.patronymic = masStrClient[i]
				else if (fields.surname == '') fields.surname = masStrClient[i]
			}
		}
	}

	return fields
}
export const useRecursiveTimeout = (callback, delay) => {
	const savedCallback = useRef(callback)

	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	useEffect(() => {
		let id

		function tick() {
			const ret = savedCallback.current()

			if (ret instanceof Promise) {
				ret.then(() => {
					if (delay !== null) {
						id = setTimeout(tick, delay)
					}
				})
			} else {
				if (delay !== null) {
					id = setTimeout(tick, delay)
				}
			}
		}
		if (delay !== null) {
			id = setTimeout(tick, delay)
			return () => id && clearTimeout(id)
		}
	}, [delay])
}

export const disableEnter = e => e.code === 'Enter' && e.preventDefault()
