import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useInput } from 'react-admin'
import { svidaAPI } from '../../../../api/svidaApi'
import { useGlobal } from '../../../../GlobalState'
import { disableEnter } from '../../../../Utils/utils'

const AsyncSelectCityFromEdit = React.memo(props => {
	const {
		input: { name, onChange, ...rest },
		meta: { touched, error },
		isRequired,
	} = useInput(props)

	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const [cityValue, setCityValue] = useState(null)
	const [regionId, setRegionId] = useState(27)
	const [regionName, setRegionName] = useState('Ставропольский')

	const loading = open && options.length === 0

	const [globalState, globalActions] = useGlobal()

	useEffect(() => {
		if (!props.record && globalState.defaultRegion) {
			svidaAPI.getCityByRegionName(globalState.defaultRegion).then(res => {
				let cityMain = res.filter(cities => cities.name === 'Ставрополь')
				if (cityMain.length > 0) {
					setCityValue(cityMain[0])
					onChange(cityMain[0].id)
				}
			})
		}
	}, [onChange])

	useEffect(() => {
		let cityData = props.record
		if (cityData && cityData.cityId) {
			;(async () => {
				await svidaAPI.getCity(cityData.cityId).then(response => {
					setCityValue(response)
				})
			})()
		}
	}, [props, onChange])

	useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		;(async () => {
			await svidaAPI.getCityByRegionName(globalState.region).then(response => {
				if (active) {
					setOptions(
						Object.keys(response).map(key => {
							return response[key]
						}),
					)
				}
			})
		})()

		return () => {
			active = false
		}
	}, [loading, globalState.region])

	useEffect(() => {
		if (!open) {
			setOptions([])
		}
	}, [open])

	const onSelectCityIdFrom = cityIdFrom => {
		props.onSelectCityIdFrom(cityIdFrom)
	}

	return (
		<Autocomplete
			onKeyDown={disableEnter}
			open={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			getOptionSelected={(option, value) => option.name === value.name}
			getOptionLabel={option =>
				option.shortName +
				'. ' +
				option.name +
				' (' +
				option.region.name +
				' ' +
				option.region.shortName +
				')'
			}
			loadingText='Загрузка...'
			noOptionsText='Нет совпадений'
			clearText='Очистить'
			openText='Открыть'
			options={options}
			loading={loading}
			value={cityValue}
			onInputChange={(event, value) => {
				if (!value) {
					onSelectCityIdFrom(null)
				}
				;(async () => {
					if (props.record && props.record.regionId) {
						await svidaAPI.getRegion(props.record.regionId).then(response => {
							setRegionId(response.id)
							setRegionName(response.name)
						})
						svidaAPI.getCityByName(value, regionName).then(response => {
							setOptions(
								Object.keys(response).map(key => {
									return response[key]
								}),
							)
						})
					}
				})()
			}}
			onChange={(event, value) => {
				if (value) {
					onSelectCityIdFrom(value.id)
					;(async () => {
						await svidaAPI.getStreetsByCityId(value.id).then(response => {
							setCityValue(value)
						})
					})()
				}
				return !value ? false : onChange(value.id)
			}}
			renderInput={(params, input) => {
				return (
					<TextField
						{...params}
						{...input}
						name={name}
						label={props.label}
						size='small'
						variant='filled'
						error={!!(touched && error)}
						helperText={touched && error}
						required={isRequired}
						{...rest}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color='inherit' size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)
			}}
		/>
	)
})

export default AsyncSelectCityFromEdit
