import { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import Button from '@material-ui/core/Button'
import { ClientCreateModal } from './forms/client/ClientCreateModal'
import OrderCreateModal from './forms/order/OrderCreateModal'
import { beelineAPI } from '../../api/api'

export const PhoneField = ({ record, updatePage, reloadComponent }) => {
    const [client, setClient] = useState(null)
    const [openClientModal, setOpenClientModal] = useState(false)
    const [openOrderModal, setOpenOrderModal] = useState(false)
    const [processingCallId, setProcessingCallId] = useState(null)
    const notify = useNotify()

    useEffect(() => {
        setClient(record.client)
        setProcessingCallId(record.id)
    }, [record])

    const isClient = (client) => {
        if (!client.name) {
            setOpenClientModal(true)
        } else {
            setOpenOrderModal(true)
        }
    }

    let missedCall = async () => {
        await beelineAPI
            .getMissedCalls()
            .then((res) => {
                let dataCalls = res.data

                if (dataCalls[0]) {
                    let dataCall = dataCalls[0]
                    setClient(dataCall.client)
                    setProcessingCallId(dataCall.id)

                    return dataCall
                } else {
                    notify('Нет пропущенных звонков', 'info')
                }
            })
            .then((dataCall) => {
                if (dataCall) {
                    isClient(dataCall.client)
                } else {
                    notify('Нет пропущенных звонков', 'info')
                }
            })
    }

    const handleClickOpenClientModal = () => {
        isClient(client)
    }

    const handleCloseClientModal = (data) => {
        setOpenClientModal(false)

        if (data !== 'closeClick') {
            setClient(data)
            setOpenOrderModal(true)
        }
    }

    const handleCloseOrderModal = (data) => {
        setOpenOrderModal(false)

        if (data === 'nextMissedCall') {
            missedCall()
        } else {
            reloadComponent()
        }
    }

    return (
        <>
            <Button variant="text" color="primary" onClick={handleClickOpenClientModal}>
                {record.client.phone}
            </Button>

            {client && (
                <>
                    <ClientCreateModal
                        open={openClientModal}
                        handleClose={handleCloseClientModal}
                        client={client}
                        processingCallId={processingCallId}
                    />

                    <OrderCreateModal
                        open={openOrderModal}
                        handleClose={handleCloseOrderModal}
                        client={client}
                        processingCallId={processingCallId}
                    />
                </>
            )}
        </>
    )
}