import React, { useState } from 'react'
import {
    DateTimeInput,
    Edit,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    useNotify,
    useRedirect,
    useRefresh,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsBottomToolbar from '../../components/common/ButtonsBottomToolbar'
import AsyncSelectCityFromEdit from '../../components/common/SelectInput/AsyncSelectPlaceFromEdit/AsyncSelectCityFromEdit'
import AsyncSelectStreetFromEdit from '../../components/common/SelectInput/AsyncSelectPlaceFromEdit/AsyncSelectStreetFromEdit'
import AsyncSelectCityToEdit from '../../components/common/SelectInput/AsyncSelectPlaceToEdit/AsyncSelectCityToEdit'
import AsyncSelectStreetToEdit from '../../components/common/SelectInput/AsyncSelectPlaceToEdit/AsyncSelectStreetToEdit'

const Title = ({ record }) => {
    if (record.cityFrom.city && record.cityTo.city) {
        return (
            <span>
                Заказ {record.cityFrom.city.name} - {record.cityTo.city.name}
            </span>
        )
    } else {
        return <span>Заказ {record ? `${record.id}` : ''}</span>
    }
}

const useStyles = makeStyles({
    inputWidth: {
        display: 'inline-flex',
        marginRight: '1%',
        width: '32.6665%',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
    inputWidthCity: {
        display: 'inline-block',
        width: '49.5%',
    },
    inputMLCity: {
        marginLeft: '0.5%',
    },
    inputMRCity: {
        marginRight: '0.5%',
    },
})

const OrdersEdit = (props) => {
    const [cityIdFrom, setCityIdFrom] = useState(null)
    const [cityIdTo, setCityIdTo] = useState(null)

    const notify = useNotify()
    const refresh = useRefresh()
    const redirect = useRedirect()

    const onSuccess = () => {
        notify('Изменения сохранены')
        redirect('/orders')
        refresh()
    }

    const onFailure = (error) => {
        notify(`${error.message}`, 'error')
    }

    const classes = useStyles()

    return (
        <Edit title={<Title />} onSuccess={onSuccess} onFailure={onFailure} mutationMode="pessimistic" {...props}>
            <SimpleForm toolbar={<ButtonsBottomToolbar />}>
                <DateTimeInput
                    source="dateCreated"
                    label="Дата и время заказа"
                    formClassName={classes.inputWidth}
                    fullWidth
                />

                <ReferenceInput
                    reference="clients"
                    source="clientId"
                    label="Клиент"
                    resettable
                    formClassName={classes.inputWidth}
                    fullWidth
                >
                    <SelectInput optionText={(choice) => `${choice.fio} ${choice.phone}`} />
                </ReferenceInput>

                <ReferenceInput
                    reference="reference/dimensions"
                    source="dimensionId"
                    label="Габариты"
                    resettable
                    formClassName={classes.inputWidth}
                    fullWidth
                >
                    <SelectInput optionText="sizeCar" />
                </ReferenceInput>

                <NumberInput
                    source="countWorkers"
                    label="Кол-во грузчиков"
                    formClassName={classes.inputWidth}
                    fullWidth
                />

                <ReferenceInput
                    reference="reference/stages"
                    source="stageId"
                    sort={{ field: 'order', order: 'ASC' }}
                    label="Стадия заказа"
                    resettable
                    formClassName={classes.inputWidth}
                    fullWidth
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <NumberInput source="price" label="Цена" formClassName={classes.inputWidth} fullWidth />

                <ReferenceInput
                    reference="reference/users"
                    source="managerId"
                    label="Ответственный логист"
                    resettable
                    formClassName={classes.inputWidth}
                    filter={{ roleuser: 'Logist' }}
                    fullWidth
                >
                    <SelectInput optionText="fio" />
                </ReferenceInput>
                <ReferenceInput
                    reference="reference/users"
                    source="managerId"
                    label="Ответственный логист"
                    resettable
                    formClassName={classes.inputWidth}
                    filter={{ roleuser: 'Logist' }}
                    fullWidth
                >
                    <SelectInput optionText="fio" />
                </ReferenceInput>

                <AsyncSelectCityFromEdit
                    name="cityFrom.city.id"
                    onSelectCityIdFrom={(cityIdFrom) => {
                        setCityIdFrom(cityIdFrom)
                    }}
                    formClassName={`${classes.inputWidthCity} ${classes.inputMRCity}`}
                />
                <AsyncSelectStreetFromEdit
                    name="cityFrom.street.id"
                    cityIdFrom={cityIdFrom}
                    formClassName={`${classes.inputWidthCity} ${classes.inputMLCity}`}
                />
                <TextInput
                    source="cityFrom.house"
                    label="Откуда: Дом"
                    resettable
                    formClassName={`${classes.inputWidthCity} ${classes.inputMRCity}`}
                    fullWidth
                />
                <TextInput
                    source="cityFrom.apartment"
                    label="Откуда: Квартира"
                    resettable
                    formClassName={`${classes.inputWidthCity} ${classes.inputMLCity}`}
                    fullWidth
                />

                <AsyncSelectCityToEdit
                    name="cityTo.city.id"
                    onSelectCityIdTo={(cityIdTo) => {
                        setCityIdTo(cityIdTo)
                    }}
                    formClassName={`${classes.inputWidthCity} ${classes.inputMRCity}`}
                />
                <AsyncSelectStreetToEdit
                    name="cityTo.street.id"
                    cityIdTo={cityIdTo}
                    formClassName={`${classes.inputWidthCity} ${classes.inputMLCity}`}
                />
                <TextInput
                    source="cityTo.house"
                    label="Куда: Дом"
                    resettable
                    formClassName={`${classes.inputWidthCity} ${classes.inputMRCity}`}
                    fullWidth
                />
                <TextInput
                    source="cityTo.apartment"
                    label="Куда: Квартира"
                    resettable
                    formClassName={`${classes.inputWidthCity} ${classes.inputMLCity}`}
                    fullWidth
                />
            </SimpleForm>
        </Edit>
    )
}

export default OrdersEdit
