import { useInput, useListContext } from "react-admin";
import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import {instanceSvida, svidaAPI} from "../../../api/svidaApi";
import { userAPI } from "../../../api/api";

const AutocompleteSelectFilter = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const { filterValues, setFilters } = useListContext();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (filterValues) {
      for (let filterValue in filterValues) {
        switch (props.source) {
          case "placeId": {
            if (filterValues.placeId) {
              (async () => {
                await svidaAPI.getCity(filterValues.placeId).then((res) => {
                  setValue(res);
                });
              })();
            }
            break;
          }
          case "clientId": {
            if (filterValues.clientId) {
              (async () => {
                await userAPI.getClient(filterValues.clientId).then((res) => {
                  setValue(res);
                });
              })();
            }
            break;
          }
          case "carId": {
            if (filterValues.gosNum) {
              (async () => {
                await userAPI.getCars(filterValues.gosNum).then((res) => {
                  setValue(res);
                });
              })();
            }
            break;
          }
          case "dimensionId": {
            if (filterValues.sizeCar) {
              (async () => {
                await userAPI.getCars(filterValues.sizeCar).then((res) => {
                  setValue(res);
                });
              })();
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }, [filterValues, props.source]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    switch (props.targetList.type) {
      case "normal": {
        (async () => {
          await props.targetList.get().then((res) => {
            if (active) {
              setOptions(res.data);
            }
          });
        })();
        break;
      }
      case "cities": {
        (async () => {
          await props.targetList.get().then((res) => {
            if (active) {
              setOptions(
                Object.keys(res).map((key) => {
                  return res[key];
                })
              );
            }
          });
        })();
        break;
      }
      default:
        break;
    }

    return () => {
      active = false;
    };
  }, [loading, props.targetList]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      filterOptions={props.filterOptions}
      className={props.className}
      alwaysOn
      open={open}
      loadingText="Загрузка..."
      noOptionsText="Нет совпадений"
      clearText="Очистить"
      openText="Открыть"
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      onInputChange={(event, value) => {
        switch (props.targetList.type) {
          case "normal": {
            (async () => {
              await props.targetList.getInputChange(value).then((res) => {
                setOptions(res.data);
              });
            })();
            break;
          }
          case "cities": {
            (async () => {
              await
                svidaAPI.getCityAll(value).then((res) => {
                  setOptions(
                      res)
                })
                /*setOptions(
                  Object.keys(res).map((key) => {
                    return res[key];
                  })
                );*/
            })();
            break;
          }
          default:
            break;
        }
      }}
      onChange={(event, value) => {
        if (value) {
          setFilters({ ...filterValues, [props.source]: value.id });
        } else {
          setValue(null);
          let temp = { ...filterValues };
          delete temp[props.source];
          setFilters({ ...temp }, [temp]);
        }

        setValue(value);
        return !value ? false : onChange(value.id);
      }}
      getOptionLabel={(option) => {
        switch (props.targetList.type) {
          case "normal": {
            let result = "";

            if (!props.optionLabel) {
              return option.name;
            } else {
              let optionLabels = props.optionLabel.split(" ");

              optionLabels.forEach(function (item, i) {
                if (option[item]) {
                  if (item === "volume") result += "(";
                  result += option[item] + " ";
                  if (item === "volume") result += "м³)";
                }
              });
              return result.trim();
            }
          }
          case "cities": {
            return (
              option.shortName +
              ". " +
              option.name +
              " (" +
              option.region.name +
              " " +
              option.region.shortName +
              ")"
            );
          }
          default:
            return option.name;
        }
      }}
      options={options}
      loading={loading}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant="filled"
          source={props.source}
          size="small"
          style={{ position: "relative", top: "-8px" }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteSelectFilter;
