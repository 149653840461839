import React from "react";
import {
  BooleanInput,
  Edit,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import ButtonsBottomToolbar from "../../components/common/ButtonsBottomToolbar";
import {
  validateAdditionalPhoneNumberFunction,
  validatePhoneNumberFunction,
  validateRequired,
} from "../../validators/validate";
import TextMaskPhone from "../../components/common/TextMaskPhone";
import AutocompleteSelectWithDefaultValue from "../../components/common/Autocomplete/AutocompleteSelectWithDefaultValue";
import { referencesAPI } from "../../api/api";

const Title = ({ record }) => {
  return <span>Клиент {record ? `${record.fio}` : ""}</span>;
};

const useStyles = makeStyles({
  inputWidth: {
    display: "inline-flex",
    marginRight: "1%",
    width: "32.6665%",
    "&:nth-child(3n)": {
      marginRight: "0",
    },
  },
});

const ClientsEdit = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Изменения сохранены", "info");
    redirect("/clients");
    refresh();
  };

  const classes = useStyles();

  return (
    <Edit
      title={<Title />}
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      {...props}
    >
      <SimpleForm toolbar={<ButtonsBottomToolbar />}>
        <TextInput
          source="name"
          label="Имя"
          formClassName={classes.inputWidth}
          fullWidth
          validate={validateRequired}
        />
        <TextInput
          source="patronymic"
          label="Отчество"
          formClassName={classes.inputWidth}
          fullWidth
        />
        <TextInput
          source="surname"
          label="Фамилия"
          formClassName={classes.inputWidth}
          fullWidth
        />
        <BooleanInput
          source="corporate"
          label="Юридическое лицо"
          formClassName={classes.inputWidth}
          fullWidth
        />
        <TextInput
          source="phone"
          label="Телефон"
          formClassName={classes.inputWidth}
          fullWidth
          validate={[validatePhoneNumberFunction, validateRequired]}
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
        />
        <TextInput
          source="additionalPhone"
          label="Дополнительный телефон"
          formClassName={classes.inputWidth}
          fullWidth
          validate={[validateAdditionalPhoneNumberFunction]}
          InputProps={{
            inputComponent: TextMaskPhone,
          }}
        />
        <FormDataConsumer
          subscription={{ values: true }}
          formClassName={classes.inputWidth}
          fullWidth
        >
          {({ formData, ...rest }) =>
            formData.corporate && (
              <AutocompleteSelectWithDefaultValue
                targetList={{
                  get: referencesAPI.getCompany,
                  getInputChange: referencesAPI.getCompanyForFilter,
                  type: "normal",
                }}
                name="company.id"
                optionLabel="name"
                label="Компания"
                defaultValue={rest.record.company}
                {...props}
                {...rest}
              />
            )
          }
        </FormDataConsumer>
      </SimpleForm>
    </Edit>
  );
};

export default ClientsEdit;
