import React from 'react'
import { getContent } from '../../containers/dashboard/Timeline/timelineLogics'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
    timelineTitle: {
        minWidth: '130px',
        marginRight: '10px',
        fontSize: '.9em',
        textAlign: 'center',
    },
    timelineContent: {
        display: 'flex',
        position: 'relative',
        flexGrow: 1,
    },
    timelineWrapper: {
        display: 'flex',
        padding: '0 16px 6px 16px',
        fontSize: '0.875rem',
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#fff',
        zIndex: 1,
    },
    timelineItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '30px',
        background: '#56b3fa',
        color: '#fff',
        boxShadow: 'inset 1px 0px 2px rgb(0, 0, 0, .2)',
        userSelect: 'none',
    },
})


const TimeLineFooter = () => {
    const classes = useStyles()

    return (
        <div className={classes.timelineWrapper}>
            <div className={classes.timelineTitle}><b>Шкала времени</b></div>
            <div className={classes.timelineContent}>
                {getContent(classes)}
            </div>
        </div>
    )


}
export default TimeLineFooter