import * as React from 'react'
import { Fragment } from 'react'
import BulkDeleteWithConfirmButton from './CustomDeleteButton/BulkDeleteWithConfirmButton'

const BulkActionButtons = (props) => (
    <Fragment>
        {/* default bulk delete action */}
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
)

export default BulkActionButtons
