import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useInput } from "react-admin";
import { svidaAPI } from "../../../../api/svidaApi";

const AsyncSelectStreetToCreate = (props) => {
  const {
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(props);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [cityIdTo, setCityIdTo] = useState(null);
  const [value, setValue] = useState(null);
  const loading = open && options.length === 0;

  useEffect(() => {
    if (props.cityIdTo !== cityIdTo) {
      setValue(null);
    }
    setCityIdTo(props.cityIdTo);
  }, [props.cityIdTo, cityIdTo]);

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (cityIdTo && active) {
      (async () => {
        await svidaAPI.getStreetsByCityId(cityIdTo).then((response) => {
          setOptions(
            Object.keys(response).map((key) => {
              return response[key];
            })
          );
        });
      })();
    }

    return () => {
      active = false;
    };
  }, [loading, cityIdTo]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => "ул. " + option.name}
      loadingText={cityIdTo ? "Загрузка..." : "Выберите город"}
      noOptionsText="Нет совпадений"
      clearText="Очистить"
      openText="Открыть"
      options={options}
      loading={loading}
      value={value}
      onInputChange={(event, value) => {
        (async () => {
          await svidaAPI.getStreetsByName(value, cityIdTo).then((response) => {
            setOptions(
              Object.keys(response).map((key) => {
                return response[key];
              })
            );
          });
        })();
      }}
      onChange={(event, value) => {
        if (value) {
          setValue(value);
        }

        return !value ? false : onChange(value.id);
      }}
      renderInput={(params, input) => {
        return (
          <TextField
            {...params}
            {...input}
            name={name}
            label={props.label}
            size="small"
            variant="filled"
            error={!!(touched && error)}
            helperText={touched && error}
            required={isRequired}
            {...rest}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default AsyncSelectStreetToCreate;
