import React from "react";
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  BooleanField,
  usePermissions,
} from "react-admin";
import ReferenceUsersFilter from "./ReferenceUsersFilter";
import { makeStyles } from "@material-ui/core/styles";
import PasswordRecoveryModal from "./modal/PasswordRecoveryModal";
import DismissUserModal from "./modal/DismissUserModal";
import ButtonsTopToolbar from "../../../components/common/ButtonsTopToolbar";
import BulkActionButtons from "../../../components/common/BulkActionButtons";
import DeleteWithConfirmButton from "../../../components/common/CustomDeleteButton/DeleteWithConfirmButton";

const ButtonsActions = ({ ...props }) => {
  return (
    <>
      <DismissUserModal userId={props.record.id} />
      <PasswordRecoveryModal userId={props.record.id} />
      <EditButton label="" size="medium" {...props} />
      <DeleteWithConfirmButton
        label=""
        mutationMode="pessimistic"
        redirect={props.basePath}
        {...props}
      />
    </>
  );
};

ButtonsActions.defaultProps = TextField.defaultProps;

const useStyles = makeStyles({
  widthActionsColumn: {
    width: "240px",
    textAlign: "right",
  },
});

const ReferenceUsersList = (props) => {
  const classes = useStyles();
  const { permissions } = usePermissions();
  return (
    <List
      title="Справочник пользователей"
      actions={<ButtonsTopToolbar />}
      filters={<ReferenceUsersFilter />}
      bulkActionButtons={<BulkActionButtons />}
      {...props}
    >
      {permissions !== "Driver" ? (
        <Datagrid>
          <TextField source="email" label="Логин пользователя" />
          <TextField source="fio" label="ФИО" />
          <TextField source="role.name" label="Роль" />
          <TextField source="phoneNumber" label="Телефон" />
          <TextField source="workPhone" label="Рабочий телефон" />
          <BooleanField source="emailConfirmed" label="Актив" />
          <ButtonsActions cellClassName={classes.widthActionsColumn} />
        </Datagrid>
      ) : null}
    </List>
  );
};

export default ReferenceUsersList;
