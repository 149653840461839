import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsBottomToolbar from '../../components/common/ButtonsBottomToolbar'
import {
	Create,
	DateTimeInput,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
} from 'react-admin'
import { validateRequired } from '../../validators/validate'
import CustomFieldClient from './selectInputClient/CustomFieldClient'
import AsyncSelectCityFromCreate from '../../components/common/SelectInput/AsyncSelectPlaceFromCreate/AsyncSelectCityFromCreate'
import AsyncSelectStreetFromCreate from '../../components/common/SelectInput/AsyncSelectPlaceFromCreate/AsyncSelectStreetFromCreate'
import AsyncSelectCityToCreate from '../../components/common/SelectInput/AsyncSelectPlaceToCreate/AsyncSelectCityToCreate'
import AsyncSelectStreetToCreate from '../../components/common/SelectInput/AsyncSelectPlaceToCreate/AsyncSelectStreetToCreate'

const useStyles = makeStyles({
	inputWidth: {
		display: 'inline-flex',
		marginRight: '1%',
		width: '32.6665%',
		'&:nth-child(3n)': {
			marginRight: '0',
		},
	},
	inputSelectWrapper: {
		top: '-13px',
		position: 'relative',
	},
	inputWidthCity: {
		display: 'inline-block',
		width: '49.5%',
	},
	inputMLCity: {
		marginLeft: '0.5%',
	},
	inputMRCity: {
		marginRight: '0.5%',
	},
})

const OrdersCreate = props => {
	const { source, ...rest } = props

	const [cityIdFrom, setCityIdFrom] = useState(null)
	const [cityIdTo, setCityIdTo] = useState(null)

	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	const onSuccess = () => {
		notify('Заказ создан', 'info')
		redirect('/orders')
		refresh()
	}

	const onFailure = error => {
		notify(`${error.message}`, 'error')
	}

	const classes = useStyles()

	return (
		<Create title='Создать заказ' onSuccess={onSuccess} onFailure={onFailure} {...props}>
			<SimpleForm toolbar={<ButtonsBottomToolbar />}>
				<DateTimeInput
					source='dateCreated'
					label='Дата и время заказа'
					formClassName={classes.inputWidth}
					validate={validateRequired}
					initialValue={new Date()}
					fullWidth
				/>

				<CustomFieldClient
					label='Клиент'
					formClassName={`${classes.inputWidth} ${classes.inputSelectWrapper}`}
					source='clientId'
					name='clientId'
					reference='clients'
					validate={validateRequired}
					fullWidth
					{...rest}
				/>

				<ReferenceInput
					reference='reference/dimensions'
					source='dimensionId'
					label='Габариты авто'
					resettable
					formClassName={classes.inputWidth}
					fullWidth
				>
					<SelectInput optionText='sizeCar' />
				</ReferenceInput>

				<NumberInput
					source='countWorkers'
					label='Кол-во грузчиков'
					formClassName={classes.inputWidth}
					fullWidth
					min='0'
				/>

				<ReferenceInput
					reference='reference/stages'
					source='stageId'
					sort={{ field: 'order', order: 'ASC' }}
					label='Стадия заказа'
					resettable
					formClassName={classes.inputWidth}
					fullWidth
				>
					<SelectInput optionText='name' />
				</ReferenceInput>

				<ReferenceInput
					reference='reference/payments'
					source='paymentId'
					label='Способ оплаты'
					resettable
					formClassName={classes.inputWidth}
					fullWidth
				>
					<SelectInput optionText='name' />
				</ReferenceInput>

				<NumberInput
					source='price'
					label='Цена'
					min='0'
					formClassName={classes.inputWidth}
					fullWidth
				/>

				<ReferenceInput
					reference='reference/users'
					source='managerId'
					label='Ответственный логист'
					resettable
					formClassName={classes.inputWidth}
					filter={{ roleuser: 'Logist' }}
					fullWidth
				>
					<SelectInput optionText='fio' />
				</ReferenceInput>

				<TextInput
					label='Комментарий'
					source='comment'
					multiline
					resettable
					formClassName={classes.inputWidth}
					fullWidth
				/>

				<AsyncSelectCityFromCreate
					name='cityFrom.city.id'
					onSelectCityIdFrom={cityIdFrom => {
						setCityIdFrom(cityIdFrom)
					}}
					formClassName={`${classes.inputWidthCity} ${classes.inputMRCity}`}
				/>
				<AsyncSelectStreetFromCreate
					name='cityFrom.street.id'
					cityIdFrom={cityIdFrom}
					formClassName={`${classes.inputWidthCity} ${classes.inputMLCity}`}
				/>
				<TextInput
					source='cityFrom.house'
					label='Откуда: Дом'
					resettable
					formClassName={`${classes.inputWidthCity} ${classes.inputMRCity}`}
					fullWidth
				/>
				<TextInput
					source='cityFrom.apartment'
					label='Откуда: Квартира'
					resettable
					formClassName={`${classes.inputWidthCity} ${classes.inputMLCity}`}
					fullWidth
				/>

				<AsyncSelectCityToCreate
					name='cityTo.city.id'
					onSelectCityIdTo={cityIdTo => {
						setCityIdTo(cityIdTo)
					}}
					formClassName={`${classes.inputWidthCity} ${classes.inputMRCity}`}
				/>
				<AsyncSelectStreetToCreate
					name='cityTo.street.id'
					cityIdTo={cityIdTo}
					formClassName={`${classes.inputWidthCity} ${classes.inputMLCity}`}
				/>
				<TextInput
					source='cityTo.house'
					label='Куда: Дом'
					resettable
					formClassName={`${classes.inputWidthCity} ${classes.inputMRCity}`}
					fullWidth
				/>
				<TextInput
					source='cityTo.apartment'
					label='Куда: Квартира'
					resettable
					formClassName={`${classes.inputWidthCity} ${classes.inputMLCity}`}
					fullWidth
				/>
			</SimpleForm>
		</Create>
	)
}

export default OrdersCreate
