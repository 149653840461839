import React from 'react'
import { useRecordContext } from 'react-admin'

const StageField = (props) => {
    const record = useRecordContext(props)
    const stage = record.stages ? record.stages.name : ''

    return <span>{stage}</span>
}

export default StageField