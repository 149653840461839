import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsBottomToolbar from '../../components/common/ButtonsBottomToolbar'
import {
	ArrayInput,
	Create,
	DateTimeInput,
	FormDataConsumer,
	NumberInput,
	ReferenceInput,
	SelectInput,
	SimpleForm,
	SimpleFormIterator,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
} from 'react-admin'
import { validateRequired } from '../../validators/validate'
import CustomFieldClient from './selectInputClient/CustomFieldClient'
import AsyncSelectCityFromEdit from '../../components/common/SelectInput/AsyncSelectPlaceFromEdit/AsyncSelectCityFromEdit'
import AsyncSelectStreetFromEdit from '../../components/common/SelectInput/AsyncSelectPlaceFromEdit/AsyncSelectStreetFromEdit'
import AsyncSelectRegionFromEdit from '../../components/common/SelectInput/AsyncSelectPlaceFromEdit/AsyncSelectRegionFromEdit'
import { disableEnter } from '../../Utils/utils'
import { newClientModalStyles } from '../telephony/TelephonyFormStyles'

const useStyles = makeStyles({
	inputWidth: {
		display: 'inline-flex',
		marginRight: '1%',
		width: '32.6665%',
		'&:nth-child(3n)': {
			marginRight: '0',
		},
	},
	inputSelectWrapper: {
		top: '-13px',
		position: 'relative',
	},
	inputWidthCity: {
		display: 'inline-block',
		width: '49.5%',
	},
	inputMLCity: {
		marginLeft: '0.5%',
	},
	inputMRCity: {
		marginRight: '0.5%',
	},
	addRouteButton: {
		display: 'inline-flex',
		marginRight: '1%',
		width: '20%',
	},
	inputHidden: {
		display: 'none',
	},
})

const OrdersCreate = props => {
	const { source, ...rest } = props
	const [regionIdFrom, setRegionIdFrom] = useState(27)
	const [cityIdTo, setCityIdTo] = useState(92732)
	const [cityIdFrom, setCityIdFrom] = useState(92732)

	const notify = useNotify()
	const refresh = useRefresh()
	const redirect = useRedirect()

	const onSuccess = () => {
		const validateStreetLocal = JSON.parse(localStorage.getItem('validateStreet'))
		if (validateStreetLocal === false) return

		notify('Заказ создан', 'info')
		redirect('/orders')
		refresh()
	}

	const onFailure = error => {
		notify(`${error.message}`, 'error')
	}
	const RouteOrder = ({ record }) => {
		let index = 0
		return (
			<ArrayInput source='pointOrders' label='Маршрут заказа'>
				<SimpleFormIterator>
					<FormDataConsumer>
						{({ getSource, scopedFormData }) => {
							return (
								<>
									<AsyncSelectRegionFromEdit
										label='Регион'
										source={getSource('regionId')}
										record={scopedFormData}
										onSelectRegionIdFrom={regionIdFrom => {
											setRegionIdFrom(regionIdFrom)
										}}
										formClassName={`${classes.inputFullWidth}`}
									/>
									<AsyncSelectCityFromEdit
										label='Город'
										source={getSource('cityId')}
										record={scopedFormData}
										onSelectCityIdFrom={cityIdFrom => {
											setCityIdFrom(cityIdFrom)
										}}
										formClassName={`${classes.inputFullWidth}`}
									/>
									<AsyncSelectStreetFromEdit
										label='Выберите улицу из списка'
										source={getSource('street')}
										record={scopedFormData}
										formClassName={`${classes.inputFullWidth}`}
										{...rest}
									/>
									<TextInput
										onKeyDown={disableEnter}
										source={getSource('house')}
										record={scopedFormData}
										label='Дом'
										margin='none'
										helperText=''
										formClassName={`${classes.inputFullWidth}`}
										fullWidth
									/>
									<TextInput
										source={getSource('apartment')}
										onKeyDown={disableEnter}
										record={scopedFormData}
										label='Квартира'
										margin='none'
										helperText=''
										formClassName={`${classes.inputFullWidth} `}
										fullWidth
									/>
								</>
							)
						}}
					</FormDataConsumer>
				</SimpleFormIterator>
			</ArrayInput>
		)
	}

	const classes = useStyles()

	return (
		<Create title='Создать заказ' onSuccess={onSuccess} onFailure={onFailure} {...props}>
			<SimpleForm toolbar={<ButtonsBottomToolbar />}>
				<DateTimeInput
					onKeyDown={disableEnter}
					source='dateCreated'
					label='Дата и время создания заказа'
					formClassName={classes.inputWidth}
					validate={validateRequired}
					initialValue={new Date()}
					fullWidth
				/>
				<DateTimeInput
					onKeyDown={disableEnter}
					source='dateStartOrder'
					label='Дата и время начала заказа'
					formClassName={classes.inputWidth}
					validate={validateRequired}
					initialValue={new Date()}
					fullWidth
				/>

				<CustomFieldClient
					onKeyDown={disableEnter}
					label='Клиент'
					formClassName={`${classes.inputWidth} ${classes.inputSelectWrapper}`}
					source='clientId'
					name='clientId'
					reference='clients'
					validate={validateRequired}
					fullWidth
					{...rest}
				/>

				<ReferenceInput
					onKeyDown={disableEnter}
					reference='reference/dimensions'
					source='dimensionId'
					label='Габариты авто'
					resettable
					formClassName={classes.inputWidth}
					fullWidth
				>
					<SelectInput optionText='sizeCar' />
				</ReferenceInput>

				<NumberInput
					onKeyDown={disableEnter}
					source='countWorkers'
					label='Кол-во грузчиков'
					formClassName={classes.inputWidth}
					fullWidth
					min='0'
				/>

				<ReferenceInput
					onKeyDown={disableEnter}
					reference='reference/stages'
					source='stageId'
					sort={{ field: 'order', order: 'ASC' }}
					label='Стадия заказа'
					resettable
					formClassName={classes.inputWidth}
					fullWidth
				>
					<SelectInput optionText='name' />
				</ReferenceInput>

				<ReferenceInput
					onKeyDown={disableEnter}
					reference='reference/payments'
					source='paymentId'
					label='Способ оплаты'
					resettable
					formClassName={classes.inputWidth}
					fullWidth
				>
					<SelectInput optionText='name' />
				</ReferenceInput>
				<TextInput
					label='Тариф'
					source='tariff'
					formClassName={classes.inputWidth}
					fullWidth
					onKeyDown={disableEnter}
				/>
				<NumberInput
					onKeyDown={disableEnter}
					source='price'
					label='Цена'
					min='0'
					formClassName={classes.inputWidth}
					fullWidth
				/>

				<ReferenceInput
					onKeyDown={disableEnter}
					reference='reference/users'
					source='managerId'
					label='Ответственный логист'
					resettable
					formClassName={classes.inputWidth}
					filter={{ roleuser: 'Logist' }}
					fullWidth
				>
					<SelectInput optionText='fio' />
				</ReferenceInput>

				<TextInput
					onKeyDown={disableEnter}
					label='Комментарий'
					source='comment'
					multiline
					resettable
					formClassName={classes.inputWidth}
					fullWidth
				/>
				<TextInput
					onKeyDown={disableEnter}
					label=''
					source='durationOrder'
					initialValue={60}
					formClassName={classes.inputHidden}
					type='hidden'
				/>
				<RouteOrder />
			</SimpleForm>
		</Create>
	)
}

export default OrdersCreate
