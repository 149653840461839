import React, { Fragment, useState } from 'react'
import { Datagrid, DateField, FunctionField, List, ReferenceField, TextField } from 'react-admin'
import { makeStyles, Typography } from '@material-ui/core'
import ButtonsTopToolbar from '../../components/common/ButtonsTopToolbar'
import BulkActionButtons from '../../components/common/BulkActionButtons'
import GroupButtonsActionTable from '../../components/common/GroupButtonsActionTable'
import AddressField from '../../components/common/AddressField'
import OrdersFilter from './OrdersFilter'
import BulkDeleteWithConfirmButton from '../../components/common/CustomDeleteButton/BulkDeleteWithConfirmButton'
import { appDataProvider } from '../../api/rest/rest'

const useStyles = makeStyles({
	widthActionsColumn: {
		width: '160px',
		textAlign: 'right',
	},
	title: {
		marginTop: '20px',
	},
})

const orderRowStyle = (record, index) => ({
	backgroundColor: new Date(record.dateCreated) < new Date() ? 'rgba(255, 0, 0, .05)' : 'white',
})

const OrdersList = ({ permissions, ...props }) => {
	const classes = useStyles()

	const addZero = date => {
		return date.toString().padStart(2, '0')
	}

	let now = new Date()
	let date = now.getFullYear() + '-' + addZero(now.getMonth() + 1) + '-' + addZero(now.getDate())

	return (
		<>
			<Typography variant='h5' gutterBottom className={classes.title}>
				Заказы, переданные в другие организации
			</Typography>
			<List
				title={' '}
				actions={null}
				bulkActionButtons={permissions !== 'Driver' ? <BulkActionButtons /> : false}
				filters={permissions !== 'Driver' ? <OrdersFilter /> : null}
				sort={{ field: 'dateCreated', order: 'ASC' }}
				empty={false}
				{...props}
			>
				<Datagrid rowClick='show' rowStyle={orderRowStyle}>
					<ReferenceField reference='clients' source='clientId' label='ФИО' link={false}>
						<FunctionField render={record => `${record.fio} ${record.phone}`} />
					</ReferenceField>

					<ReferenceField
						reference='clients'
						source='clientId'
						label='Компания'
						sortBy='company'
						link={false}
					>
						<ReferenceField reference='reference/company' source='companyId' link={false}>
							<TextField source='name' />
						</ReferenceField>
					</ReferenceField>

					<ReferenceField
						reference='reference/stages'
						source='stageId'
						label='Состояние заказа'
						link={false}
					>
						<TextField source='name' label='Стадия заказа' />
					</ReferenceField>

					<DateField source='dateCreated' label='Дата и время заказа' showTime />

					<ReferenceField
						reference='clients'
						source='clientId'
						label='Телефон'
						sortBy='phone'
						link={false}
					>
						<TextField source='phone' />
					</ReferenceField>

					<AddressField source='cityFrom' label='Откуда' />

					<AddressField source='cityTo' label='Куда' />

					<TextField label='Авто' source='car.gosNum' />

					<TextField label='Габариты авто' source='dimension.sizeCar' />

					<TextField source='countWorkers' label='Кол-во грузчиков' />

					<TextField source='price' label='Цена заказа' />

					{permissions !== 'Driver' && (
						<GroupButtonsActionTable addLabel label='' cellClassName={classes.widthActionsColumn} />
					)}
				</Datagrid>
			</List>
		</>
	)
}
export default OrdersList
