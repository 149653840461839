import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Dialog from '@material-ui/core/Dialog'
import { ClientCreateForm } from './ClientCreateForm'

export const ClientCreateModal = ({ open, handleClose, client, processingCallId }) => {
    return (
        <Dialog
            open={open}
            onClose={() => {
                handleClose('closeClick')
            }}
            aria-labelledby="form-create-client"
            maxWidth="lg"
        >
            <DialogTitle id="form-create-client">Создать клиента для номера {client.phone}</DialogTitle>

            <DialogContent>
                <ClientCreateForm client={client} handleClose={handleClose} processingCallId={processingCallId} />
            </DialogContent>
        </Dialog>
    )
}