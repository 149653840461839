import React from 'react'
import { Datagrid, List, TextField, usePermissions } from 'react-admin'
import GroupButtonsActionTable from '../../../components/common/GroupButtonsActionTable'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsTopToolbar from '../../../components/common/ButtonsTopToolbar'
import BulkActionButtons from '../../../components/common/BulkActionButtons'

const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const ReferenceClosingCallList = (props) => {
    const { loading, permissions } = usePermissions()

    const classes = useStyles()

    return loading ? (
      <div>Пожалуйста подождите</div>
    ) : (
      <List title="Справочник вариантов закрытия звонка"
            actions={permissions === 'Administrators' ? <ButtonsTopToolbar /> : null}
            bulkActionButtons={permissions === 'Administrators' ? <BulkActionButtons /> : false}
            {...props}
      >
          <Datagrid>
              <TextField source="name" label="Наименование" />
              {
                  permissions === 'Administrators' &&
                  <GroupButtonsActionTable cellClassName={classes.widthActionsColumn} />
              }
          </Datagrid>
      </List>
    )
}

export default ReferenceClosingCallList