import { combineReducers, createStore } from 'redux'
import { adminReducer } from 'react-admin'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory as createHistory } from 'history'

const history = createHistory()

export const initialState = {
	order: null,
	isCall: false,
}

export const currentOrder = (state = initialState, action) => {
	switch (action.type) {
		case 'ADD_ORDER':
			return { ...state, order: action.payload }
		case 'IS_CALL':
			return { ...state, isCall: action.payload }
		default:
			return state
	}
}

const reducer = combineReducers({
	admin: adminReducer,
	router: connectRouter(history),
	currentOrder: currentOrder,
})

export const store = createStore(reducer)
