import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import ActionDelete from '@material-ui/icons/Delete'
import classnames from 'classnames'
import { useDeleteWithConfirmController, useResourceContext } from 'ra-core'

import { Button } from '@material-ui/core'
import Confirm from './Confirm'

const DeleteWithConfirmButton = (props) => {
    const {
        basePath,
        classes: classesOverride,
        className,
        record,
        confirmTitle = 'Удаление ' +
            (record.name ? '"' + record.name + '"' : ''),
        confirmContent = 'ra.message.delete_content',
        icon = defaultIcon,
        label = 'ra.action.delete',
        mutationMode,
        onClick,
        redirect = 'list',
        onSuccess,
        onFailure,
        ...rest
    } = props
    const classes = useStyles(props)
    const resource = useResourceContext(props)
    const {
        open,
        loading,
        handleDialogOpen,
        handleDialogClose,
        handleDelete,
    } = useDeleteWithConfirmController({
        record,
        redirect,
        basePath,
        mutationMode,
        onClick,
        onSuccess,
        onFailure,
        resource,
    })

    return (
        <Fragment>
            <Button
                onClick={handleDialogOpen}
                label={label}
                className={classnames(
                    'ra-delete-button',
                    classes.deleteButton,
                    className
                )}
                key="button"
                {...rest}
            >
                {icon}
            </Button>
            <Confirm
                isOpen={open}
                loading={loading}
                title={confirmTitle}
                content={confirmContent}
                translateOptions={{
                    name: record.name,
                    id: record.id,
                }}
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    )
}

const defaultIcon = <ActionDelete />

const useStyles = makeStyles(
    (theme) => ({
        deleteButton: {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: fade(theme.palette.error.main, 0.12),
                // Reset on mouse devices
                '@media (hover: none)': {
                    backgroundColor: 'transparent',
                },
            },
        },
    }),
    { name: 'RaDeleteWithConfirmButton' }
)

DeleteWithConfirmButton.propTypes = {
    basePath: PropTypes.string,
    classes: PropTypes.object,
    className: PropTypes.string,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.any,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    icon: PropTypes.element,
}

export default DeleteWithConfirmButton