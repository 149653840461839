import React from "react";
import { useFormik } from "formik";
import StelsSaveButton from "../../../../components/common/ButtonStyles/StelsSaveButton";
import { userAPI } from "../../../../api/api";
import { useNotify } from "react-admin";
import { TextField } from "@material-ui/core";

const FormikDismissInModal = (props) => {
  const notify = useNotify();

  const formik = useFormik({
    initialValues: {
      userId: props.userId,
      dateCreated: new Date(),
    },
    onSubmit: async (values) => {
      let userId = values.userId;
      let dateCreated = { dateCreated: values.dateCreated };

      await userAPI
        .dismissUser(userId, dateCreated)
        .then((response) => {
          if (response.status !== 200) {
            throw new Error(response.statusText);
          }
          props.openModal();
          notify("Сотрудник уволен", "info");
        })
        .catch((error) => {
          notify(
            "С пользователем есть связанные автомобили!Пометить на увольнение сотрудника невозможно!",
            "error"
          );
        });
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {/*<TextField
                    variant="filled"
                    id="dateCreated"
                    label="Дата увольнения"
                    type="datetime-local"
                    name="dateCreated"
                    value={formik.values.dateCreated}
                    onChange={formik.handleChange}

                    InputLabelProps={{
                        shrink: true,
                    }}
                    style={{ margin: '10px 0 20px' }}
                />*/}
        <StelsSaveButton
          variant="contained"
          color="default"
          fullWidth
          type="submit"
        >
          Уволить
        </StelsSaveButton>
      </form>
    </div>
  );
};

export default FormikDismissInModal;
