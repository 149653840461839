import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { saveQr } from "../../../api/api";
import { Button, Input, Box } from "@mui/material";
import { Title } from "react-admin";

const useStyles = makeStyles({
  style: {
    marginTop: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  qr: {
    width: "300px",
    background: "black",
  },
});

const QrCodeList = ({ permissions, ...props }) => {
  const classes = useStyles();
  const [link, setLink] = useState("");

  const onChangeInputs = ({ target }) => {
    setLink(target.value);
  };

  const onSumbit = () => {
    saveQr(link).then(() => window.location.reload());
  };

  return (
    <>
      <Title title={"QrCode"} />
      <Box className={classes.style}>
        <Input
          type="url"
          name="url"
          id="url"
          placeholder="Добавьте ссылку"
          onChange={onChangeInputs}
        />
        <Button variant="contained" onClick={onSumbit}>
          Сохранить
        </Button>
      </Box>
      <Box className={classes.style}>
        <img
          className={classes.qr}
          src={process.env.REACT_APP_API_URL + "/images/QrCode.png"}
          alt="Добавьте QrCode"
        />
      </Box>
    </>
  );
};

export default QrCodeList;
