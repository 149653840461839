import restProvider from 'ra-data-simple-rest'
import { fetchUtils } from 'react-admin'
import { stringify } from 'query-string'

const apiUrl = process.env.REACT_APP_API_URL

const countHeader = 'content-range'

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' })
    }
    // add your own headers here
    if (localStorage.getItem('token')) {
        options.headers = new Headers({ Authorization: 'Bearer ' + localStorage.getItem('token') })
    }
    return fetchUtils.fetchJson(url, options)
}

export const appDataProvider = {
    ...restProvider,
    getList: (resource, params) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort

        const rangeStart = (page - 1) * perPage
        const rangeEnd = page * perPage - 1

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([rangeStart, rangeEnd]),
            filter: JSON.stringify(params.filter),
        }

        const options =
            countHeader === 'content-range'
                ? {
                      // Chrome doesn't return `Content-Range` header if no `Range` is provided in the request.
                      headers: new Headers({
                          Range: `${resource}=${rangeStart}-${rangeEnd}`,
                      }),
                  }
                : {}

        const url = `${apiUrl}/${resource}?${stringify(query)}`

        return httpClient(url, options).then(({ headers, json }) => {
            if (!headers.has(countHeader)) {
                throw new Error(
                    `The ${countHeader} header is missing in the HTTP Response. 
                     The simple REST data provider expects responses for lists of resources to contain this header with
                     the total number of results to build the pagination. If you are using CORS, did you declare 
                     ${countHeader} in the Access-Control-Expose-Headers header?`
                )
            }

            return {
                data: json.data,
                total:
                    countHeader === 'content-range'
                        ? parseInt(headers.get('content-range').split('/').pop(), 10)
                        : parseInt(headers.get(countHeader.toLowerCase())),
            }
        })
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`)
            .then(({ json }) => {
                return { data: json }
            })
            .catch((error) => {
                console.log(error)
            }),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`
        return httpClient(url).then(({ json }) => ({ data: json.data }))
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort

        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        }
        const url = `${apiUrl}/${resource}?${stringify(query)}`

        return httpClient(url).then(({ headers, json }) => ({
            data: json.data,
            total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }))
    },

    update: (resource, params) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json.data }))
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json.data }))
    },

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: { ...params.data, id: json.data.id } })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        }
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json.data }))
    },
}