import * as React from 'react'
import { useRef, useState,useEffect } from 'react'
import { Admin, Resource } from 'react-admin'
import autopark from './containers/autopark'
import referenceUsers from './containers/references/referenceUsers/index'
import referenceCompany from './containers/references/referenceCompany'
import referencePayments from './containers/references/referencePaymentMethods'
import referenceCalls from './containers/references/referenceClosingCall'
import referenceCars from './containers/references/referenceCars'
import referenceDimensions from './containers/references/referenceDimensions'
import clients from './containers/clients'
import orders from './containers/orders'
import transfer from './containers/transfer'
import historyDrivers from './containers/history/historyDrivers'
import rings from './containers/rings'
import settings from './containers/settings'
import telephonySettings from './containers/settings/telephony'
import referenceDrivers from './containers/references/referenceDrivers'
import referenceStages from './containers/references/referenceStages'
import authReducer from './store/reducers/authReducer'
import SignIn from './components/LoginPage/SignIn'
import LogoutButton from './components/LoginPage/LogoutButton/LogoutButton'
import LayoutApp from './layout/LayoutApp'
import { appDataProvider } from './api/rest/rest'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import russianMessages from './i18n/ru'
import ReferencePaymentMethodsEdit from './containers/references/referencePaymentMethods/ReferencePaymentMethodsEdit'
import ReferencePaymentMethodsCreate from './containers/references/referencePaymentMethods/ReferencePaymentMethodsCreate'
import ReferenceClosingCallEdit from './containers/references/referenceClosingCall/ReferenceClosingCallEdit'
import ReferenceClosingCallCreate from './containers/references/referenceClosingCall/ReferenceClosingCallCreate'
import ReferenceUsersEdit from './containers/references/referenceUsers/ReferenceUsersEdit'
import ReferenceUsersCreate from './containers/references/referenceUsers/ReferenceUsersCreate'
import ReferenceCarsCreate from './containers/references/referenceCars/ReferenceCarsCreate'
import { Dashboard } from './containers/dashboard'
import TelephonyForm from './containers/telephony/TelephonyForm'
//import telephony from './containers/telephony/telephony'
import qrcode from './containers/settings/qrCode'
import { SnackbarProvider } from 'notistack'
import Slide from '@material-ui/core/Slide'
import customRoutes from './customRoutes'
import { appStyles } from './appStyles'
import { useDispatch } from 'react-redux'
import { createHashHistory } from 'history'
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import {putInfoCall} from "./api/api";


const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')
export const App = () => {
	const dispatch = useDispatch()
	const providerRef = useRef()
	const history = createHashHistory()
	const classes = appStyles()
	const [connection, setConnection] = useState();

	const closeForm = () => {
		setInfoForModal(infoForModal => ({ ...infoForModal, isCall: false }))
	}
	const userId = JSON.parse(localStorage.getItem('userId'))

	let active = true
	const [infoForModal, setInfoForModal] = useState({
		callId: null,
		isCall: false,
		identifyClient: false,
		clientData: null,
		order: null,
		lastCall: null,
		clientId: null,
	})

	const [infoForDriverModal, setInfoForDriverModal] = useState({
		orderId: null,
		gosNum: null,
		marka: null,
		isCall: false,
	})
	const [infoComment, setInfoComment] = useState({
		orderId: null,
		comment: null
	})
	useEffect(() => {
		const connect = new HubConnectionBuilder()
			.withUrl(process.env.REACT_APP_SIGNALR_API_URL, {
				accessTokenFactory: () => localStorage.getItem('token'),
			})
			.withAutomaticReconnect([1000, 5000,7000])
			.build()
		setConnection(connect);
	},[])
	useEffect(() => {
		if (connection) {
			connection
				.start()
				.then(() => {
						connection.on('IncomingCall', data => {
							if (data.client.phone && active) {
								providerRef.current.enqueueSnackbar(data.client.phone, {
									preventDuplicate: true,
									anchorOrigin: { vertical: 'top', horizontal: 'right' },
									TransitionComponent: Slide,
									variant: 'info',
									autoHideDuration: 1000,
								})

								localStorage.setItem('callId', JSON.stringify(data.callId))

								setInfoForModal(infoForModal => ({
									...infoForModal,
									isCall: true,
									identifyClient: data.isNewClient,
									clientData: data.client,
									order: data.lastOrder,
									lastCall: data.lastCall,
									callId: data.callId,
									clientId: data.clientId,
								}))
							}
						})
					connection.on('ReleaseIncomingCall', data => {
						active = true
					})
					connection.on('CloseIncomingCall', data => {
						closeForm()
						active = true
					})

					connection.on('DashboardUpdate', data => {
						dispatch({ type: 'ADD_ORDER', payload: data })
					})
					connection.on('RedirectedCall',data => {

						  if (active) {
							  closeForm()
						  }
					})
					connection.on('AcceptedIncomingCall', data => {
							if (data.client.phone && active) {
								setInfoForModal(infoForModal => ({
									...infoForModal,
									identifyClient: data.isNewClient,
									clientData: data.client,
									order: data.lastOrder,
									lastCall: data.lastCall,
								}))
								const {callId} = data
								setInfoForModal(infoForModal => ({...infoForModal, isCall: true, callId: data.callId}))
								active = false
							}
						}
					)
					connection.on('AddComment', data => {
						let comment = `Добавлен комментарий в заказе №${data.orderId}
		Комментарий: ${data.comment}`
						providerRef.current.enqueueSnackbar(comment, {
							preventDuplicate: true,
							anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
							TransitionComponent: Slide,
							variant: 'info',
							autoHideDuration: 5000,
						})
					})
				})
				.catch((error) => {
					let comment = `Пропало соединение, необходимо обновить страницу!`
					providerRef.current.enqueueSnackbar(comment, {
						preventDuplicate: true,
						anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
						TransitionComponent: Slide,
						variant: 'warning',
						autoHideDuration: 1000,
					})
					console.log(error)
				});
		}
	}, [connection]);


	return (
		<SnackbarProvider
			dense={false}
			maxSnack={5}
			ref={providerRef}
			classes={{ containerRoot: classes.root }}
		>
			{infoForModal.isCall && (
				<TelephonyForm infoForModal={infoForModal} closeForm={() => closeForm()} />
			)}
			<Admin
				history={history}
				// initialState={initialState}
				// customReducers={{ currentOrder }}
				logoutButton={LogoutButton}
				loginPage={SignIn}
				authProvider={authReducer}
				dataProvider={appDataProvider}
				layout={LayoutApp}
				i18nProvider={i18nProvider}
				customRoutes={customRoutes}
				dashboard={Dashboard}
			>
				{permissions => {
					if (permissions === 'Driver') {
						return [
							<Resource name='ordere' edit={null} create={null} {...orders} />,
							<Resource name='reference/stages' {...referenceStages} />,
							<Resource name='reference/payments' {...referencePayments} />,
							<Resource name='clients' {...clients} />,
							<Resource name='reference/company' {...referenceCompany} />,
						]
					} else if (permissions === 'Logist') {
						return [
							<Resource name='autopark' {...autopark} />,

							<Resource
								name='reference/users'
								edit={permissions === 'Administrators' ? ReferenceUsersEdit : null}
								create={permissions === 'Administrators' ? ReferenceUsersCreate : null}
								{...referenceUsers}
							/>,

							<Resource name='reference/company' {...referenceCompany} />,

							<Resource
								name='reference/payments'
								edit={permissions === 'Administrators' ? ReferencePaymentMethodsEdit : null}
								create={permissions === 'Administrators' ? ReferencePaymentMethodsCreate : null}
								{...referencePayments}
							/>,

							<Resource name='reference/dimensions' {...referenceDimensions} />,

							<Resource
								name='reference/calls'
								edit={permissions === 'Administrators' ? ReferenceClosingCallEdit : null}
								create={permissions === 'Administrators' ? ReferenceClosingCallCreate : null}
								{...referenceCalls}
							/>,

							<Resource
								name='reference/cars'
								create={permissions === 'Administrators' ? ReferenceCarsCreate : null}
								{...referenceCars}
							/>,
							<Resource name='reference/stages' {...referenceStages} />,
							<Resource name='reference/drivers' {...referenceDrivers} />,
							<Resource name='clients' {...clients} />,
							<Resource name='orders' {...orders} />,
							<Resource name='transfer' {...transfer} />,
							<Resource name='beeline/calls' {...rings} />,
						]
					} else {
						return [
							<Resource name='autopark' {...autopark} />,

							<Resource
								name='reference/users'
								edit={permissions === 'Administrators' ? ReferenceUsersEdit : null}
								create={permissions === 'Administrators' ? ReferenceUsersCreate : null}
								{...referenceUsers}
							/>,

							<Resource name='reference/company' {...referenceCompany} />,

							<Resource
								name='reference/payments'
								edit={permissions === 'Administrators' ? ReferencePaymentMethodsEdit : null}
								create={permissions === 'Administrators' ? ReferencePaymentMethodsCreate : null}
								{...referencePayments}
							/>,

							<Resource name='reference/dimensions' {...referenceDimensions} />,

							<Resource
								name='reference/calls'
								edit={permissions === 'Administrators' ? ReferenceClosingCallEdit : null}
								create={permissions === 'Administrators' ? ReferenceClosingCallCreate : null}
								{...referenceCalls}
							/>,
							<Resource name='history/drivers' {...historyDrivers} />,
							<Resource
								name='reference/cars'
								create={permissions === 'Administrators' ? ReferenceCarsCreate : null}
								{...referenceCars}
							/>,
							<Resource name='reference/stages' {...referenceStages} />,
							<Resource name='reference/drivers' {...referenceDrivers} />,
							<Resource name='clients' {...clients} />,
							<Resource name='orders' {...orders} />,
							<Resource name='transfer' {...transfer} />,
							<Resource name='beeline/calls' {...rings} />,
							<Resource name='email' {...settings} />,
							<Resource name='settings/telephony' {...telephonySettings} />,
							<Resource name='api/qr' {...qrcode} />,
						]
					}
				}}
			</Admin>
		</SnackbarProvider>
	)
}
export default App
