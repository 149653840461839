import React from 'react'
import {Datagrid, List, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsTopToolbar from "../../../components/common/ButtonsTopToolbar";


const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const SettingsTelephonyList = ({permissions, ...props}) => {
    const classes = useStyles()
    return (
        <>
            <List
                title="Настройки телефонии"
                actions={permissions == "Administrators" ? <ButtonsTopToolbar /> : null}
                {...props}
            >
                <Datagrid rowClick="edit">
                    <TextField
                        source="id"
                        label="id"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextField
                        source="subscribeUrl"
                        label="Сервер АТС"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextField
                        source="apiForCallEvent"
                        label="Сервер событий"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextField
                        source="subscribeDelayIndDays"
                        label="Период обновления (в минутах)"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextField
                        source="token"
                        label="Токен"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextField
                        source="subscriptionId"
                        label="Идентификатор подписки"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                </Datagrid>
            </List>
        </>
    )
}

export default SettingsTelephonyList