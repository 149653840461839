import React, { useCallback, useEffect, useState } from 'react'
import CreateClientModalButton from './CreateClientModalButton'
import AutocompleteSelectWithDefaultValue from '../../../components/common/Autocomplete/AutocompleteSelectWithDefaultValue'
import { referencesAPI, userAPI } from '../../../api/api'
import { filterOptions } from '../../../Utils/utils'
import { TextInput, TextField } from 'react-admin'

const CustomFieldClient = props => {
	const [defaultValue, setDefaultValue] = useState()
	const handleChange = useCallback(data => {
		setDefaultValue(data)
	}, [])

	return (
		<>
			<AutocompleteSelectWithDefaultValue
				targetList={{
					get: userAPI.getClients,
					getInputChange: userAPI.getClientsForFilter,
					type: 'normal',
				}}
				name='clientId'
				optionLabel='fio phone'
				label='Клиент'
				filterOptions={filterOptions}
				defaultValue={defaultValue || null}
				{...props}
			/>
			<TextField
				id='clientInput'
				name='clientInput'
				type='hidden'
				value={null}
			/>
			<CreateClientModalButton onChange={handleChange} />
		</>
	)
}

export default CustomFieldClient
