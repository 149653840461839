import React from 'react'
import {Datagrid, List, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ButtonsTopToolbar from "../../components/common/ButtonsTopToolbar";


const useStyles = makeStyles({
    widthActionsColumn: {
        width: '160px',
        textAlign: 'right',
    },
})

const SettingsList = ({permissions, ...props}) => {
    const classes = useStyles()
    return (
        <>
            <List
                title="Настройки почтового сервера для заявок"
                actions={permissions == "Administrators" ? <ButtonsTopToolbar /> : null}
                {...props}
            >
                <Datagrid rowClick="edit">
                    <TextField
                        source="id"
                        label="id"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextField
                        source="server"
                        label="Сервер"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextField
                        source="port"
                        label="Порт"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                    <TextField
                        source="login"
                        label="Имя пользователя"
                        resettable
                        formClassName={classes.inputWidth}
                        fullWidth
                    />
                </Datagrid>
            </List>
        </>
    )
}

export default SettingsList