import { makeStyles } from '@material-ui/core/styles'

export const ClientStyles = makeStyles({
    inputWidth: {
        display: 'inline-flex',
        marginRight: '1%',
        marginBottom: '2%',
        width: '32.6665%',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
    checkbox: {
        marginLeft: '0',
    },
    formButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        '& > button': {
            maxWidth: '130px',
            width: '100%',
            '&:last-child': {
                marginLeft: '10px',
            },
        },
    },
})