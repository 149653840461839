import globalHook from 'use-global-hook'
import { ordersAPI, referencesAPI } from '../../src/api/api'

const actions = {
	addNewNameRegion: (store, amount) => {
		const newRegion = { region: amount }
		store.setState(newRegion)
	},
	addNewNameCity: (store, amount) => {
		const newCity = { cityId: amount }
		store.setState(newCity)
	},
	initialDateDefault: (store, amount) => {
		const newData = { date: amount }
		store.setState(newData)
	},
	initialRegionIdDefault: (store, amount) => {
		const newRegionId = { defaultRegionId: amount }
		store.setState(newRegionId)
	},
	initialRegionDefault: (store, amount) => {
		const newRegion = { defaultRegion: amount }
		store.setState(newRegion)
	},
	initialOrder: (store, amount) => {
		ordersAPI.getOrder(amount).then(res => {
			store.setState({ selectOrder: res })
		})
	},
	initialCars: (store, amount) => {
		referencesAPI.getCars(amount).then(res => {
			store.setState({ cars: res })
		})
	},
	addToOrders: (store, amount) => {
		store.setState({ currentOrders: amount })
	},
}

const initialState = {
	region: 'Ставропольский',
	defaultRegionId: 27,
	defaultRegion: 'Ставропольский',
	cityId: 92732,
	date: new Date(),
	selectOrder: null,
	currentOrders: null,
}

export const useGlobal = globalHook(initialState, actions)
