import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useInput } from 'react-admin'
import { svidaAPI } from '../../../../api/svidaApi'
import { useGlobal } from '../../../../GlobalState'
import { disableEnter } from '../../../../Utils/utils'

const AsyncSelectRegionFromEdit = React.memo(props => {
	const {
		input: { name, onChange, ...rest },
		meta: { touched, error },
		isRequired,
	} = useInput(props)

	const [open, setOpen] = useState(false)
	const [options, setOptions] = useState([])
	const [regionValue, setRegionValue] = useState(null)
	const [defoltRegionValue, setDefoltRegionValue] = useState(null)
	const [globalState, globalActions] = useGlobal()
	const loading = open && options.length === 0

	useEffect(() => {
		if (!props.record && globalState.defaultRegionId) {
			svidaAPI.getRegion(globalState.defaultRegionId).then(res => {
				setRegionValue(res)
				onChange(globalState.defaultRegionId)
			})
		}
	}, [onChange])
	useEffect(() => {
		let regionData = props.record
		if (regionData && regionData.regionId) {
			;(async () => {
				await svidaAPI.getRegion(regionData.regionId).then(response => {
					setRegionValue(response)
				})
			})()
		} else if (props.record && props.record.cityId) {
			;(async () => {
				await svidaAPI.getCity(props.record.cityId).then(response => {
					setRegionValue(response.region)

					onChange(response.region.id)
				})
			})()
		}
	}, [props, onChange])

	useEffect(() => {
		let active = true

		if (!loading) {
			return undefined
		}

		;(async () => {
			await svidaAPI.getRegions().then(response => {
				if (active) {
					setOptions(
						Object.keys(response).map(key => {
							return response[key]
						}),
					)
				}
			})
		})()

		return () => {
			active = false
		}
	}, [loading])

	useEffect(() => {
		if (!open) {
			setOptions([])
		}
	}, [open])

	//const onSelectRegionNameFrom = (regionNameFrom) => {
	//  props.onSelectRegionNameFrom(regionNameFrom);
	// };

	return (
		<Autocomplete
			open={open}
			onOpen={() => {
				setOpen(true)
			}}
			onClose={() => {
				setOpen(false)
			}}
			getOptionSelected={(option, value) => option.name === value.name}
			getOptionLabel={option => option.shortName + '. ' + option.name}
			loadingText='Загрузка...'
			noOptionsText='Нет совпадений'
			clearText='Очистить'
			openText='Открыть'
			options={options}
			loading={loading}
			value={regionValue}
			onInputChange={(event, value) => {
				// if (!value) {
				//   props.onSelectRegionNameFrom(null);
				// }

				;(async () => {
					await svidaAPI.getRegionsByName(value).then(response => {
						setOptions(
							Object.keys(response).map(key => {
								return response[key]
							}),
						)
					})
				})()
			}}
			onChange={(event, value) => {
				if (value) {
					;(async () => {
						await svidaAPI.getCityByRegionName(value.name).then(response => {
							setRegionValue(value)
							globalActions.addNewNameRegion(value.name, props.source)
						})
					})()
				}
				return !value ? false : onChange(value.id)
			}}
			onKeyDown={disableEnter}
			renderInput={(params, input) => {
				return (
					<TextField
						{...params}
						{...input}
						name={name}
						label={props.label}
						size='small'
						variant='filled'
						error={!!(touched && error)}
						helperText={touched && error}
						required={isRequired}
						{...rest}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? <CircularProgress color='inherit' size={20} /> : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)
			}}
		/>
	)
})

export default AsyncSelectRegionFromEdit
