import { makeStyles } from '@material-ui/core'

export const CreateClientModalButtonStyles = makeStyles({
    inputWidth: {
        display: 'inline-flex',
        marginRight: '1%',
        width: '32.6665%',
        '&:nth-child(3n)': {
            marginRight: '0',
        },
    },
    button: {
        height: '48px',
        width: '48px',
        minWidth: 'inherit',
        position: 'relative',
        top: '-8px',
        borderRadius: '0 4px 0 0',
        backgroundColor: 'rgba(0, 0, 0, 0.13)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.17)',
            borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
        },
    },
})