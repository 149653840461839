import React from 'react'
import { useRecordContext } from 'react-admin'

const WhereField = (props) => {
    const record = useRecordContext(props)

    let city = ''
    if (record.cityTo && record.cityTo.city && record.cityTo.city.name) {
        city += `г. ${record.cityTo.city.name} 
`
        if (record.cityTo.street && record.cityTo.street.name) {
            city += `ул. ${record.cityTo.street.name} 
`
            if (record.cityTo.house) {
                city += `д. ${record.cityTo.house} `
                if (record.cityTo.apartment) {
                    city += `д. ${record.cityTo.apartment}`
                }
            }
        }
    }
    return <span><pre>{city}</pre></span>
}

export default WhereField
